import clsx from 'clsx'
import {FC} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {SettingsMenu} from '../../../partials/layout/settings-menus/SettingsMenu'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const {currentUser, companyStorage} = useAuth()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* begin::Settings menu */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <SettingsMenu toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        {/* end::Settings menu */}
        {/* begin::Theme mode */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        {/* end::Theme mode */}

        {/* begin::User */}
        {currentUser && (
          <div
            className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
            id='kt_header_user_menu_toggle'
          >
            {/* begin::Toggle */}
            <div
              className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <img
                className='h-30px w-30px rounded'
                src={
                  process.env.REACT_APP_CDN_SENSITIVE_URL +
                  `${companyStorage?.companyPublicKey}/user-image/${currentUser?.publicKey}`
                }
                onError={({currentTarget}) => {
                  currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
                }}
                alt='safetyhub-user'
              />
            </div>
            <HeaderUserMenu />
            {/* end::Toggle */}
          </div>
        )}

        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
