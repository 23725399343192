import {PublicKey, Response} from '../../../../../../_metronic/helpers'

export interface ControlMeasureStatus {
  error?: number
  messages?: string
}

export type ControlMeasure = {
  publicKey?: PublicKey
  hazard_name?: string
  item_amount?: string
  hazard_description?: string
  activity_publicKey?: PublicKey
  activity_name?: string | undefined
  location_publicKey?: PublicKey
  location_name?: string | undefined
  table?: string
  type?: string
  items?: string
  parent?: string | undefined
  controlMeasure_name?: string
  controlMeasure_publicKey?: PublicKey
  table_name?: string
  equipment_quantity?: string
  training_quantity?: string
  medical_quantity?: string
  rescuePlan_quantity?: string
  quantity?: string
}

export type ICreateControlMeasure = {
  hazard_publicKey: PublicKey
  table: string
  table_publicKey: PublicKey
}

export const initialControlMeasure: ICreateControlMeasure = {
  hazard_publicKey: '',
  table: '',
  table_publicKey: '',
}

export type ControlMeasureQueryResponse = Response<Array<ControlMeasure>>

export const initialUser: ControlMeasure = {
  hazard_name: '',
  item_amount: '',
  hazard_description: '',
  activity_publicKey: '',
  activity_name: '',
  location_publicKey: '',
  location_name: '',
}

export type RescuePlan = {
  publicKey?: PublicKey
  image?: string
  description?: string
  sequence?: string
}

export interface RescuePlanStatus {
  error?: number
  messages?: string
}

export type RescuePlanQueryResponse = Response<Array<RescuePlan>>

export const initialValues: RescuePlan = {
  image: '',
  description: '',
}
