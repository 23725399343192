import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTIcon, checkIsActive} from '../../../helpers'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const SettingsMenu = ({
  toggleBtnClass = '',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const {currentModules, currentFeaturesObject} = useAuth()

  const intl = useIntl()
  const {pathname} = useLocation()

  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href='#'
        className={clsx('btn btn-icon btn-active-light-primary btn-custom', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <KTIcon iconName='gear' className='fs-1' />
      </a>

      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-200px'
        data-kt-menu='true'
      >
        {currentModules?.includes('settings') &&
        currentFeaturesObject &&
        currentFeaturesObject.user ? (
          <>
            {/* begin::Menu item User */}
            <div className='menu-item px-3 my-0'>
              <Link
                to='/settings/user'
                className={clsx('menu-link px-3 py-2', {
                  active: checkIsActive(pathname, '/settings/user'),
                })}
              >
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='user' className='fs-3' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.GENERAL.USERS'})}</span>
              </Link>
            </div>
            {/* end::Menu item User*/}
          </>
        ) : (
          <>
            {/* begin::Menu item User */}
            <div className='menu-item px-3 my-0'>
              <Link to='#' className='menu-link px-3 py-2' style={{opacity: '70%'}}>
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='lock-3' className='fs-2 text-danger' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.GENERAL.USERS'})}</span>
              </Link>
            </div>
            {/* end::Menu item User*/}
          </>
        )}

        {currentModules?.includes('settings') &&
        currentFeaturesObject &&
        currentFeaturesObject.employee ? (
          <>
            {/* begin::Menu item Employee */}
            <div className='menu-item px-3 my-0'>
              <Link
                to='/settings/employee'
                className={clsx('menu-link px-3 py-2', {
                  active: checkIsActive(pathname, '/settings/employee'),
                })}
              >
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='people' className='fs-3' />
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'MENU.GENERAL.EMPLOYEES'})}
                </span>
              </Link>
            </div>
            {/* end::Menu item Employee*/}
          </>
        ) : (
          <>
            {/* begin::Menu item Employee */}
            <div className='menu-item px-3 my-0'>
              <Link to='#' className='menu-link px-3 py-2' style={{opacity: '70%'}}>
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='lock-3' className='fs-2 text-danger' />
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'MENU.GENERAL.EMPLOYEES'})}
                </span>
              </Link>
            </div>
            {/* end::Menu item Employee*/}
          </>
        )}

        {currentModules?.includes('access') &&
        currentFeaturesObject &&
        currentFeaturesObject.workflow ? (
          <>
            {/* begin::Menu item Workflow*/}
            <div className='menu-item px-3 my-0'>
              <a
                href='/access/workflow/list'
                className={clsx('menu-link px-3 py-2', {
                  active: checkIsActive(pathname, '/access/workflow/list'),
                })}
              >
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='data' className='fs-3' />
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'ACCESS.DASHBOARD.HEADER_BUTTONS.WORKFLOW'})}
                </span>
              </a>
            </div>
            {/* end::Menu item Workflow*/}
          </>
        ) : (
          <>
            {/* begin::Menu item Workflow*/}
            <div className='menu-item px-3 my-0'>
              <Link to='/not-paid/access' className='menu-link px-3 py-2' style={{opacity: '70%'}}>
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='lock-3' className='fs-2 text-danger' />
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'ACCESS.DASHBOARD.HEADER_BUTTONS.WORKFLOW'})}
                </span>
              </Link>
            </div>
            {/* end::Menu item Workflow*/}
          </>
        )}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {SettingsMenu}
