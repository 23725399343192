import axios, {AxiosResponse} from 'axios'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'

const getWorkAuthorizations = () => {
  return axios.get(`${API_ALPHA}/customer/access/workAuthorization?offset=1&limit=7`)
}

const getDashboardCount = () => {
  return axios.get(`${API_ALPHA}/customer/access/dashboardAccessCount`)
}

const getPendingIssues = () => {
  return axios.get(`${API_ALPHA}/customer/access/workAuthorizationIssue?offset=1&limit=7`)
}

export {getWorkAuthorizations, getDashboardCount, getPendingIssues}
