import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../_metronic/helpers'
import {Equipment, EquipmentQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const EQUIPMENT_URL = `${API_ALPHA}/customer/asset/equipment`

const getEquipments = (query: string): Promise<EquipmentQueryResponse> => {
  return axios
    .get(`${EQUIPMENT_URL}?${query}`)
    .then((d: AxiosResponse<EquipmentQueryResponse>) => d.data)
}

const getEquipmentById = (publicKey: PublicKey): Promise<Equipment | undefined> => {
  return axios
    .get(`${EQUIPMENT_URL}/${publicKey}`)
    .then((response: AxiosResponse<Response<Equipment>>) => response.data)
    .then((response: Response<Equipment>) => response.data)
}

const createEquipment = (equipment: Equipment): Promise<Equipment | undefined> => {
  return axios
    .post(EQUIPMENT_URL, equipment)
    .then((response: AxiosResponse<Response<Equipment>>) => response.data)
    .then((response: Response<Equipment>) => response.data)
}

const updateEquipment = (equipment: Equipment): Promise<Equipment | undefined> => {
  return axios
    .put(`${EQUIPMENT_URL}/${equipment.publicKey}`, equipment)
    .then((response: AxiosResponse<Response<Equipment>>) => response.data)
    .then((response: Response<Equipment>) => response.data)
}

const deleteEquipment = (equipmentId: PublicKey) => {
  return axios.delete(`${EQUIPMENT_URL}/${equipmentId}`).then((res) => res.data)
}

const deleteSelectedEquipments = (equipmentPublicKey: Array<PublicKey>) => {
  const requests = equipmentPublicKey.map((publicKey) =>
    axios.delete(`${EQUIPMENT_URL}/${publicKey}`)
  )
  return axios.all(requests).then((res) => {
    res.map((item) => {
      let dividedUrl = item.config.url?.split('/') || ''
      item.data.publicKey = dividedUrl[dividedUrl.length - 1]
      return item
    })
    return res
  })
}

export {
  getEquipments,
  deleteEquipment,
  deleteSelectedEquipments,
  getEquipmentById,
  createEquipment,
  updateEquipment,
}
