import {KTCard} from '../../../../../_metronic/helpers/components/KTCard'
import {WorkflowModal} from '../../newWorkflowModal/WorkflowModal'
import WorkflowModalWrapper from '../../newWorkflowModal/WorkflowModalWrapper'
import {HazardManagementListHeader} from './components/header/HazardManagementListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from './core/QueryResponseProvider'
import {WorkflowListTable} from './table/WorkAuthorizationTable'

const WorkflowList = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  return (
    <>
      <KTCard>
        <HazardManagementListHeader />
        <WorkflowListTable />
      </KTCard>
      {itemIdForUpdate !== undefined && (
        <WorkflowModalWrapper
          show={true}
          refetch={refetch}
          handleClose={() => setItemIdForUpdate(undefined)}
        />
      )}
    </>
  )
}

const WorkflowListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <WorkflowList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {WorkflowListWrapper}
