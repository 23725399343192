import {motion} from 'framer-motion'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

type Props = {
  currentStepIndex: any
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
  handleClose: () => void
  stepCurrentIndex: number
  setStepCurrentIndex: React.Dispatch<React.SetStateAction<number>>
  inspectionsSteps: any
  nfcAvailable: boolean
  nfcAvailableSecondStep: boolean
}

const BottomNavigationBar: React.FC<Props> = ({
  currentStepIndex,
  setCurrentStepIndex,
  handleClose,
  stepCurrentIndex,
  inspectionsSteps,
  nfcAvailable,
  nfcAvailableSecondStep,
}) => {
  const intl = useIntl()

  const [showHint, setShowHint] = useState(false)

  useEffect(() => {
    setShowHint(false)
  }, [stepCurrentIndex])

  return (
    <motion.div
      initial={{opacity: 0, y: 800}}
      animate={{opacity: 1, y: 0}}
      exit={{opacity: 0, y: 800}}
      transition={{
        duration: 0.8,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      className='w-100 d-flex flex-column align-items-center px-4 justify-content-between bg-gray-200 shadow-lg border-top'
      style={{
        height: '32%',
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        position: 'sticky',
        bottom: '0',
        zIndex: 99,
      }}
    >
      <div className='mt-8 w-100'>
        {currentStepIndex === 1 ? (
          <>
            <h1 className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.SCAN_SAFETY_TAG'})}
            </h1>
            <p className=''>
              {intl.formatMessage({
                id: 'TRANSACTION.BOTTOM_NAVIGATION.SCAN_SAFETY_TAG_DESCRIPTION',
              })}
            </p>
          </>
        ) : (
          <></>
        )}

        {currentStepIndex === 2 ? (
          <>
            <h1 className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.TYPE_SAFETY_TAG'})}
            </h1>
            <p className=''>
              {intl.formatMessage({
                id: 'TRANSACTION.BOTTOM_NAVIGATION.TYPE_SAFETY_TAG_DESCRIPTION',
              })}
            </p>
          </>
        ) : (
          <></>
        )}

        {currentStepIndex === 3 || currentStepIndex === 7 ? (
          <>
            <h1 className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_VALIDATION_QR'})}
            </h1>
            <p className=''>
              {intl.formatMessage({
                id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_VALIDATION_QR_DESCRIPTION',
              })}
            </p>
          </>
        ) : (
          <></>
        )}

        {currentStepIndex === 4 ? (
          <>
            <h1 className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_VALIDATION_QR'})}
            </h1>
            <p className=''>
              {intl.formatMessage({
                id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_VALIDATION_TYPE_DESCRIPTION',
              })}
            </p>
          </>
        ) : (
          <></>
        )}
        {currentStepIndex === 5 ? (
          <>
            <h1 className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_FACE_RECOGNITION'})}
            </h1>
            <p className=''>
              {intl.formatMessage({
                id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_FACE_RECOGNITION_DESCRIPTION',
              })}
            </p>
          </>
        ) : (
          <></>
        )}
        {currentStepIndex === 6 ? (
          <>
            <h1 className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_CHALLENGE'})}
            </h1>
            <p className=''>
              {intl.formatMessage({
                id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_CHALLENGE_DESCRIPTION',
              })}
            </p>
          </>
        ) : (
          <></>
        )}

        {currentStepIndex === 8 ? (
          <>
            <div>
              <div className='d-flex w-100 justify-content-between'>
                <h1 className='fw-bold'>
                  {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.EQUIPMENT_TRANSACTION'})}
                </h1>
                {showHint && (
                  <motion.span
                    initial={{opacity: 0, x: 800}}
                    animate={{opacity: 1, x: 0}}
                    exit={{opacity: 0, x: 800}}
                    transition={{
                      duration: 0.8,
                      ease: [0, 0.71, 0.2, 1.01],
                    }}
                    className='bg-primary p-4 rounded w-75 mx-3 text-white'
                    style={{position: 'absolute', top: '-50%', right: '0'}}
                  >
                    <h6 className='fw-bold text-gray-200'>
                      {intl.formatMessage({
                        id: 'EQUIPMENT.INSPECTION_STEP.MODAL.LABEL.DESCRIPTION_OK',
                      })}
                      :
                    </h6>
                    <p>{inspectionsSteps[stepCurrentIndex]?.descriptionOk}</p>
                    <br />
                    <h6 className='fw-bold mt-4 text-gray-200'>
                      {intl.formatMessage({
                        id: 'EQUIPMENT.INSPECTION_STEP.MODAL.LABEL.DESCRIPTION_NOT_OK',
                      })}
                      :
                    </h6>
                    <p>{inspectionsSteps[stepCurrentIndex]?.descriptionNotOk}</p>
                  </motion.span>
                )}
                <button
                  onClick={() => setShowHint(!showHint)}
                  style={{zIndex: 99}}
                  className={`btn ${
                    showHint === true ? 'btn-secondary' : 'btn-primary'
                  } rounded-circle fw-bold`}
                >
                  ?
                </button>
              </div>
              <p className=''>
                {intl.formatMessage({
                  id: 'TRANSACTION.BOTTOM_NAVIGATION.EQUIPMENT_TRANSACTION_DESCRIPTION',
                })}
              </p>
            </div>
          </>
        ) : (
          <></>
        )}
        {currentStepIndex === 9 || currentStepIndex === 10 ? (
          <>
            <h1 className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_SIGNONGLASS'})}
            </h1>
            <p className=''>
              {intl.formatMessage({
                id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_SIGNONGLASS_DESCRIPTION',
              })}
            </p>
          </>
        ) : (
          <></>
        )}
        {currentStepIndex === 11 ? (
          <>
            <h1 className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.TRANSACTION_PHOTO'})}
            </h1>
            <p className=''>
              {intl.formatMessage({
                id: 'TRANSACTION.BOTTOM_NAVIGATION.TRANSACTION_PHOTO_DESCRIPTION',
              })}
            </p>
          </>
        ) : (
          <></>
        )}
        {currentStepIndex === 12 ? (
          <>
            <h1 className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.TRANSACTION_RESULT'})}
            </h1>
            <p className=''>
              {intl.formatMessage({
                id: 'TRANSACTION.BOTTOM_NAVIGATION.TRANSACTION_RESULT_DESCRIPTION',
              })}
            </p>
          </>
        ) : (
          <></>
        )}
        {currentStepIndex === 22 ? (
          <>
            <h1 className='fw-bold'>Adicione notas na transação</h1>
            <p className=''>Adicione as notas e descrições sobre a transação</p>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className='d-flex gap-8'>
        {currentStepIndex === 1 && (
          <>
            <button className='btn btn-primary' onClick={() => setCurrentStepIndex(2)}>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.TYPE'})}
            </button>
            <button
              disabled={!nfcAvailable}
              className={`btn ${nfcAvailable === true ? 'btn-success' : 'btn-secondary'}`}
            >
              NFC
            </button>
          </>
        )}
        {currentStepIndex === 2 && (
          <>
            <button className='btn btn-primary' onClick={() => setCurrentStepIndex(1)}>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.SCAN_QR'})}
            </button>
            <button
              disabled={!nfcAvailable}
              className={`btn ${nfcAvailable === true ? 'btn-success' : 'btn-secondary'}`}
            >
              NFC
            </button>
          </>
        )}

        {currentStepIndex === 3 && (
          <>
            <button className='btn btn-primary' onClick={() => setCurrentStepIndex(4)}>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.TYPE'})}
            </button>
            <button
              disabled={!nfcAvailableSecondStep}
              className={`btn ${nfcAvailableSecondStep === true ? 'btn-success' : 'btn-secondary'}`}
            >
              NFC
            </button>
          </>
        )}
        {currentStepIndex === 4 && (
          <>
            <button className='btn btn-primary' onClick={() => setCurrentStepIndex(3)}>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.SCAN_QR'})}
            </button>
            <button
              disabled={!nfcAvailableSecondStep}
              className={`btn ${nfcAvailableSecondStep === true ? 'btn-success' : 'btn-secondary'}`}
            >
              NFC
            </button>
          </>
        )}

        {currentStepIndex === 11 && (
          <>
            <button className='btn btn-primary' onClick={() => setCurrentStepIndex(22)}>
              {intl.formatMessage({id: 'INSPECTION_DELIVERY.FIELD.STEP.NOTES_PLACEHOLDER'})}
            </button>
          </>
        )}

        {currentStepIndex === 12 && (
          <>
            <button className='btn btn-primary' onClick={() => handleClose()}>
              {intl.formatMessage({id: 'FORM.BUTTON.CLOSE'})}
            </button>
          </>
        )}
      </div>

      <div className='w-100 d-flex align-items-center justify-content-evenly'>
        {currentStepIndex === 1 || currentStepIndex === 2 ? (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG className='svg-icon-primary svg-icon-2qx' path='/media/svg/misc/epi.svg' />
            <p className='fw-bold mb-12 text-primary'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.PPE'})}
            </p>
          </div>
        ) : (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG className='svg-icon-muted svg-icon-2qx' path='/media/svg/misc/epi.svg' />
            <p className='fw-bold'>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.PPE'})}
            </p>
          </div>
        )}
        {currentStepIndex === 3 ||
        currentStepIndex === 4 ||
        currentStepIndex === 5 ||
        currentStepIndex === 6 ||
        currentStepIndex === 7 ? (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG
              className='svg-icon-primary svg-icon-2qx'
              path='/media/svg/misc/construction-worker.svg'
            />
            <p className='fw-bold mb-12 text-primary'>
              {intl.formatMessage({id: 'ACCESS.APPROVED_WORK_AUTHORIZATION.EMPLOYEE_NAME'})}
            </p>
          </div>
        ) : (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG
              className='svg-icon-muted svg-icon-2qx'
              path='/media/svg/misc/construction-worker.svg'
            />
            <p className='fw-bold'>
              {intl.formatMessage({id: 'ACCESS.APPROVED_WORK_AUTHORIZATION.EMPLOYEE_NAME'})}
            </p>
          </div>
        )}
        {currentStepIndex === 8 ? (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG
              className='svg-icon-primary svg-icon-2qx'
              path='/media/svg/misc/inspection.svg'
            />
            <p className='fw-bold mb-12 text-primary'>
              {intl.formatMessage({id: 'ASSET.DASHBOARD.TEAM_PERFORMANCE_CHART.TITLE'})}
            </p>
          </div>
        ) : (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG className='svg-icon-muted svg-icon-2qx' path='/media/svg/misc/inspection.svg' />
            <p className='fw-bold'>
              {intl.formatMessage({id: 'ASSET.DASHBOARD.TEAM_PERFORMANCE_CHART.TITLE'})}
            </p>
          </div>
        )}
        {currentStepIndex === 9 ||
        currentStepIndex === 10 ||
        currentStepIndex === 11 ||
        currentStepIndex === 22 ? (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG
              className='svg-icon-primary svg-icon-2qx'
              path='/media/svg/misc/certificate.svg'
            />
            <p className='fw-bold mb-12 text-primary'>
              {intl.formatMessage({id: 'INSPECTION_DELIVERY.RESUME.TABLE.RESULT'})}
            </p>
          </div>
        ) : (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG className='svg-icon-muted svg-icon-2qx' path='/media/svg/misc/certificate.svg' />
            <p className='fw-bold'>
              {intl.formatMessage({id: 'INSPECTION_DELIVERY.RESUME.TABLE.RESULT'})}
            </p>
          </div>
        )}
        {currentStepIndex === 12 ? (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG className='svg-icon-primary svg-icon-2qx' path='/media/svg/misc/done.svg' />
            <p className='fw-bold mb-12 text-primary'>
              {intl.formatMessage({id: 'INSPECTION_DELIVERY.HEADER.STEP.COMPLETED'})}
            </p>
          </div>
        ) : (
          <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
            <KTSVG className='svg-icon-muted svg-icon-2qx' path='/media/svg/misc/done.svg' />
            <p className='fw-bold'>
              {intl.formatMessage({id: 'INSPECTION_DELIVERY.HEADER.STEP.COMPLETED'})}
            </p>
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default BottomNavigationBar
