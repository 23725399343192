import React from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ApprovedWorkAuthorizationListWrapper} from './approvedWorkAuthorization-list/ApprovedWorkAuthorizationList'
import {PendingIssuesListWrapper} from '../pendingIssues-list/PendingIssuesList'
import {PendingWorkAuthorizationListWrapper} from './pendingWorkAuthorization-list/PendingWorkAuthorizationList'
import {RejectedWorkAuthorizationListWrapper} from './rejectedWorkAuthorization-list/RejectedWorkAuthorizationList'
import {WorkAuthorizationListWrapper} from './WorkAuthorization-list/WorkAuthorizationList'
import {ResolvedIssuesListWrapper} from '../resolvedIssues-list/ResolvedIssuesList'
import {useAuth} from '../../../modules/auth'
import ViewWorkAuthorization from './viewWorkAuthorization/ViewWorkAuthorization'

type Props = {
  stateNewAuthorization: any
  setStateNewAuthorization: any
}

const WorkAuthorization: React.FC<Props> = ({stateNewAuthorization, setStateNewAuthorization}) => {
  const intl = useIntl()
  const {currentFeaturesObject} = useAuth()

  const workAuthorizationBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION.TITLE'}),
      path: '/access/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={workAuthorizationBreadCrumbs}>
                {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION.TITLE'})}
              </PageTitle>
              <WorkAuthorizationListWrapper
                stateNewAuthorization={stateNewAuthorization}
                setStateNewAuthorization={setStateNewAuthorization}
              />
            </>
          }
        />

        {currentFeaturesObject && currentFeaturesObject.workAuthorization && (
          <Route
            path='approved-list'
            element={
              <>
                <PageTitle breadcrumbs={workAuthorizationBreadCrumbs}>
                  {intl.formatMessage({id: 'ACCESS.APPROVED_WORK_AUTHORIZATION.TITLE'})}
                </PageTitle>
                <ApprovedWorkAuthorizationListWrapper />
              </>
            }
          />
        )}

        {currentFeaturesObject && currentFeaturesObject.workAuthorization && (
          <Route
            path='pending-list'
            element={
              <>
                <PageTitle breadcrumbs={workAuthorizationBreadCrumbs}>
                  {intl.formatMessage({id: 'ACCESS.PENDING_WORK_AUTHORIZATION.TITLE'})}
                </PageTitle>
                <PendingWorkAuthorizationListWrapper />
              </>
            }
          />
        )}

        {currentFeaturesObject && currentFeaturesObject.workAuthorization && (
          <Route
            path='rejected-list'
            element={
              <>
                <PageTitle breadcrumbs={workAuthorizationBreadCrumbs}>
                  {intl.formatMessage({id: 'ACCESS.REJECTED_WORK_AUTHORIZATION.TITLE'})}
                </PageTitle>
                <RejectedWorkAuthorizationListWrapper />
              </>
            }
          />
        )}

        {currentFeaturesObject && currentFeaturesObject.workAuthorizationIssue && (
          <Route
            path='issues/pending-list'
            element={
              <>
                <PageTitle breadcrumbs={workAuthorizationBreadCrumbs}>
                  {intl.formatMessage({id: 'ACCESS.PENDING_ISSUES.TITLE'})}
                </PageTitle>
                <PendingIssuesListWrapper />
              </>
            }
          />
        )}

        {currentFeaturesObject && currentFeaturesObject.workAuthorizationIssue && (
          <Route
            path='issues/resolved-list'
            element={
              <>
                <PageTitle breadcrumbs={workAuthorizationBreadCrumbs}>
                  {intl.formatMessage({id: 'ACCESS.RESOLVED_ISSUES.TITLE'})}
                </PageTitle>
                <ResolvedIssuesListWrapper />
              </>
            }
          />
        )}
        {currentFeaturesObject && currentFeaturesObject.workAuthorizationIssue && (
          <>
            <Route
              path='view/:publicKey'
              element={
                <>
                  <PageTitle breadcrumbs={workAuthorizationBreadCrumbs}>
                    {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION'})}
                  </PageTitle>
                  <ViewWorkAuthorization />
                </>
              }
            />
            <Route
              path='view/:publicKey/:workflow_publicKey'
              element={
                <>
                  <PageTitle breadcrumbs={workAuthorizationBreadCrumbs}>
                    {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION'})}
                  </PageTitle>
                  <ViewWorkAuthorization />
                </>
              }
            />
          </>
        )}

        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default WorkAuthorization
