import jsQR from 'jsqr'
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import Webcam from 'react-webcam'
import {KTSVG, PublicKey} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../components/table/loading/Loading'

type Props = {
  setPublicKey: React.Dispatch<React.SetStateAction<string>>
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
  safetyTagScanned: string
  handleClose: () => void
  nfcAvailable: boolean
  setNfcAvailable: React.Dispatch<React.SetStateAction<boolean>>
}

const CameraReader: React.FC<Props> = ({
  safetyTagScanned,
  setCurrentStepIndex,
  setPublicKey,
  handleClose,
  nfcAvailable,
  setNfcAvailable,
}) => {
  const webcamRef: any = useRef<Webcam>(null)
  const intl = useIntl()

  const [isLoad, setIsLoad] = useState(false)
  const [freezeImg, setFreezeImg] = useState('')

  const handleScan = (data: any) => {
    if (data) {
      setPublicKey(parsePublicKeyFromURL(data))
    }
  }

  const capture = () => {
    const imageSrc = webcamRef.current?.getScreenshot()
    if (imageSrc) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (ctx) {
        const img = new Image()
        img.onload = () => {
          const aspectRatio = 1 // Proporção desejada, no caso 1:1
          const maxDimension = Math.max(img.width, img.height)
          const cropSize = maxDimension / aspectRatio
          const cropX = (img.width - cropSize) / 2 // Centralize na largura
          const cropY = (img.height - cropSize) / 2 // Centralize na altura
          canvas.width = cropSize
          canvas.height = cropSize
          ctx.drawImage(img, cropX, cropY, cropSize, cropSize, 0, 0, cropSize, cropSize)
          const croppedImageData = ctx.getImageData(0, 0, cropSize, cropSize)

          ctx.drawImage(img, 0, 0)
          const code = jsQR(croppedImageData.data, croppedImageData.width, croppedImageData.height)

          if (code) {
            setFreezeImg(imageSrc)
            setIsLoad(true)
            handleScan(code.data)
          }
        }
        img.src = imageSrc
      }
    }
  }

  const parsePublicKeyFromURL = (alphaTag_publicKey: string | PublicKey) => {
    let parsedPublicKey = ''

    function isValidUrl(value: string) {
      let url_string
      try {
        url_string = new URL(value)
      } catch (_) {
        return false
      }
      return url_string.protocol === 'http:' || url_string.protocol === 'https:'
    }

    if (isValidUrl(alphaTag_publicKey as string) && alphaTag_publicKey) {
      const mappedPK = alphaTag_publicKey.split('/')

      if (mappedPK[mappedPK.length - 1].length === 8) {
        parsedPublicKey = mappedPK[mappedPK.length - 1]
      } else {
        setCurrentStepIndex(18)
      }
    } else {
      setCurrentStepIndex(18)
    }

    return parsedPublicKey
  }

  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'

  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT)

  const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT,
  }

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    )
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (safetyTagScanned.length < 8) {
        capture()
      }
    }, 100)
    return () => clearInterval(interval)
  }, [])

  ///NFC

  const [nfcReader, setNfcReader] = useState<any>()
  const [isScanning, setIsScanning] = useState(false)
  // settado para cancelar o scan quando sair do modal
  const [abortController] = useState(new AbortController())

  useEffect(() => {
    if ('NDEFReader' in window) {
      // eslint-disable-next-line
      setNfcReader(new window.NDEFReader())
      setNfcAvailable(true)
    }
  }, [])

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [abortController])

  const ScanNFC = () => {
    if (!nfcReader) return
    const signal = abortController.signal
    nfcReader.scan({signal}).then(() => {
      setIsScanning(true)
      nfcReader.onreadingerror = () => {
        toast.error('Error while reading NFC.')
      }
      nfcReader.onreading = (event: any) => {
        const decoder = new TextDecoder()
        for (const record of event.message.records) {
          handleScan(decoder.decode(record?.data))
        }
      }
    })
  }

  const [isCameraPermited, setIsCameraPermited] = useState('Negado')

  async function checkCameraPermission() {
    try {
      const permissionStatus = await navigator.permissions.query({name: 'camera' as PermissionName})

      if (permissionStatus.state === 'granted') {
        // permissão concedida
        setIsCameraPermited('Permitido')
      } else if (permissionStatus.state === 'prompt') {
        // permissão ainda não concedida, solicitação de permissão necessária
        try {
          const stream = await navigator.mediaDevices.getUserMedia({video: true})
          stream.getTracks().forEach((track) => track.stop())
          setIsCameraPermited('Permitido')
        } catch {}
      } else {
        // permissão não concedida
        try {
          const stream = await navigator.mediaDevices.getUserMedia({video: true})
          stream.getTracks().forEach((track) => track.stop())
          setIsCameraPermited('Negado')
        } catch {}
      }
    } catch (error) {
      console.error('Erro ao verificar a permissão da câmera:', error)
    }
  }

  useEffect(() => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // se o usuário estiver em um dispositivo iOS
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
      // `v` será um array contendo a versão do iOS em cada índice
      if (v) {
        if (parseInt(v[1], 10) <= 15) {
          setIsCameraPermited('Permitido')
        } else {
          checkCameraPermission()
        }
      }
    } else {
      checkCameraPermission()
    }
  }, [isCameraPermited])

  // function getiOSVersion() {
  //   if (/iP(hone|od|ad)/.test(navigator.platform)) {
  //     // se o usuário estiver em um dispositivo iOS
  //     const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
  //     // `v` será um array contendo a versão do iOS em cada índice
  //     if(v){
  //       setIosVersion(parseInt(v[1], 10))
  //     }
  //   }
  //   // se o usuário não estiver em um dispositivo iOS, retorna null
  //   return null;
  // }

  useEffect(() => {
    if (nfcAvailable) ScanNFC()
    // eslint-disable-next-line
  }, [nfcAvailable])

  return (
    <div className='w-100' style={{height: '68%', overflow: 'hidden'}}>
      {isCameraPermited === 'Permitido' && (
        <>
          <div
            className='translate-middle d-flex flex-column gap-16'
            style={{
              width: '50%',
              height: '30%',
              position: 'absolute',
              zIndex: '999',
              top: '35%',
              left: '50%',
              borderRadius: '40px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className='d-flex gap-16' style={{width: '100%', justifyContent: 'space-between'}}>
              <div
                className='border-primary'
                style={{
                  width: '100px',
                  height: '80px',
                  borderTopLeftRadius: '10px',
                  borderTop: '4px solid white',
                  borderLeft: '4px solid white',
                }}
              ></div>
              <div
                className='border-primary'
                style={{
                  width: '100px',
                  height: '80px',
                  borderTopRightRadius: '10px',
                  borderTop: '4px solid white',
                  borderRight: '4px solid white',
                }}
              ></div>
            </div>
            <div className='d-flex gap-16' style={{width: '100%', justifyContent: 'space-between'}}>
              <div
                className='border-primary'
                style={{
                  width: '100px',
                  height: '80px',
                  borderBottomLeftRadius: '10px',
                  borderBottom: '4px solid white',
                  borderLeft: '4px solid white',
                }}
              ></div>
              <div
                className='border-primary'
                style={{
                  width: '100px',
                  height: '80px',
                  borderBottomRightRadius: '10px',
                  borderBottom: '4px solid white',
                  borderRight: '4px solid white',
                }}
              ></div>
            </div>
          </div>
          {isLoad ? (
            <>
              <Loading />
              <img
                src={freezeImg}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </>
          ) : (
            <Webcam
              audio={false}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              ref={webcamRef}
              screenshotFormat='image/webp'
              videoConstraints={{
                ...videoConstraints,
                facingMode,
              }}
            />
          )}

          <div
            style={{position: 'absolute', top: '0'}}
            className='w-100 d-flex align-items-center justify-content-between px-4 py-2 mt-14 rounded'
          >
            <button onClick={handleClose} className='btn btn-secondary svg-icon-2qx'>
              X
            </button>

            <div onClick={() => handleClick()}>
              <KTSVG
                className='svg-icon-primary svg-icon-2qx'
                path='/media/svg/misc/switch-camera.svg'
              />
            </div>
          </div>
        </>
      )}
      {isCameraPermited === 'Negado' && (
        <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
          <button className='btn btn-primary' onClick={() => checkCameraPermission()}>
            {intl.formatMessage({id: 'CAMERA.REQUEST_PERMISSION'})}
          </button>
        </div>
      )}
    </div>
  )
}

export default CameraReader
