/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {PublicKey} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'

type Props = {
  publicKey: PublicKey
  workFlowPublicKey: PublicKey
}

const PendingWorkAuthorizationActionsCell: FC<Props> = ({publicKey, workFlowPublicKey}) => {
  const {authSelected, handleAddAuthPublicKey} = useListView()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [authSelected])

  const item: any = authSelected?.filter((element: any) => element.key === publicKey)

  const intl = useIntl()

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        {/* begin::Menu Status */}
        {/*<a
          onClick={openEditForm}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          <span className='svg-icon svg-icon-3'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z'
                fill='currentColor'
              ></path>
              <path
                opacity='0.3'
                d='M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z'
                fill='currentColor'
              ></path>
            </svg>
          </span>
        </a>*/}
        {/* end::Menu Status */}

        {/* begin::Menu Delete */}
        {/* <a
          data-kt-users-table-filter='delete_row'
          onClick={async () => await deleteItem.mutateAsync()}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <span className='svg-icon svg-icon-3'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                fill='currentColor'
              ></path>
              <path
                opacity='0.5'
                d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                fill='currentColor'
              ></path>
              <path
                opacity='0.5'
                d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                fill='currentColor'
              ></path>
            </svg>
          </span>
        </a> */}
        {/* end::Menu Delete */}
        <div className='d-flex gap-2'>
          {authSelected !== undefined &&
          authSelected?.filter((element: any) => element.key === publicKey).length > 0 ? (
            <>
              <button
                disabled
                className={`btn btn-md ${
                  item[0]?.value === 'Approved' ? 'btn-success' : 'btn-light-success'
                }`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                {intl.formatMessage({
                  id: 'RISK_ANALYSIS.PENDING_RISK_ANALYSIS.MODAL.LABEL.APPROVE',
                })}
              </button>

              <button
                disabled
                className={`btn btn-md ${
                  item[0]?.value === 'Denied' ? 'btn-danger' : 'btn-light-danger'
                }`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                {intl.formatMessage({id: 'RISK_ANALYSIS.PENDING_RISK_ANALYSIS.MODAL.LABEL.DENY'})}
              </button>
            </>
          ) : (
            <>
              <a
                onClick={() => {
                  if (handleAddAuthPublicKey !== undefined) {
                    handleAddAuthPublicKey(publicKey, true)
                  }
                }}
                className='btn btn-md btn-light-success'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                {intl.formatMessage({
                  id: 'RISK_ANALYSIS.PENDING_RISK_ANALYSIS.MODAL.LABEL.APPROVE',
                })}
              </a>

              <a
                onClick={() => {
                  if (handleAddAuthPublicKey !== undefined) {
                    handleAddAuthPublicKey(publicKey, false)
                  }
                }}
                className='btn btn-md btn-light-danger'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                {intl.formatMessage({id: 'RISK_ANALYSIS.PENDING_RISK_ANALYSIS.MODAL.LABEL.DENY'})}
              </a>
            </>
          )}
        </div>
        {/* begin::Menu View */}
        {/* <a
          onClick={() => {
            navigate(`/access/work-authorization/view/${publicKey}/${workFlowPublicKey}`)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          <i className='fa fa-eye fs-2'></i>
        </a> */}
        {/* end::Menu View */}
      </div>

      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditForm}>
            Edit
          </a>
        </div>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div>
      </div> */}
    </>
  )
}

export {PendingWorkAuthorizationActionsCell}
