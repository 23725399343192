import clsx from 'clsx'
import {ErrorMessage, Field} from 'formik'
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useListView} from '../../workflow/Workflow-list/core/ListViewProvider'
import {getWorkflowLevelsByPublicKey} from '../core/_requests'

export const WorkflowLevel = (props: any) => {
  const intl = useIntl()

  const {itemIdForUpdate} = useListView()

  useEffect(() => {
    const getLevelsWorkflow = async () => {
      const {data} = await getWorkflowLevelsByPublicKey(itemIdForUpdate)
      props.setFieldValue('minimumApproval', data?.result[0]?.minimumApproval)
    }

    getLevelsWorkflow()
  }, [])

  return (
    <div className='w-100'>
      <div className='row mb-5'>
        {/* Level */}
        <div className='fv-row mb-5 col-md-6 row'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>
              {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.LABEL.LEVEL'})}
            </span>
          </label>

          <div className='fv-row pe-2 col-4'>
            <Field type='radio' className='btn-check' id='level_1' name='level' value='1' />
            <label
              htmlFor='level_1'
              className='btn btn-outline btn-outline-default btn-light-success p-2 d-flex align-items-center justify-content-center fs-2 p-2'
            >
              1
            </label>
          </div>
          <div className='fv-row pe-2 col-4'>
            <Field type='radio' className='btn-check' id='level_2' name='level' value='2' />
            <label
              htmlFor='level_2'
              className='btn btn-outline btn-outline-default btn-light-warning p-2 d-flex align-items-center justify-content-center fs-2 p-2 disabled'
            >
              2
            </label>
          </div>
          <div className='fv-row pe-2 col-4'>
            <Field type='radio' className='btn-check' id='level_3' name='level' value='3' />
            <label
              htmlFor='level_3'
              className='btn btn-outline btn-outline-default btn-light-danger p-2 d-flex align-items-center justify-content-center fs-2 p-2 disabled'
            >
              3
            </label>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='level' />
          </div>
        </div>
        {/* Level */}

        {/* Minimum Approval */}
        <div className='fv-row mb-5 col-md-6'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>
              {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.LABEL.MINIMUM_APPROVAL'})}
            </span>
          </label>

          <div className='position-relative'>
            <Field
              type='number'
              className={clsx('form-control form-control-lg', {
                'is-invalid': props.touched.minimumApproval && props.errors.minimumApproval,
              })}
              placeholder={intl.formatMessage({
                id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.PLACEHOLDER.MINIMUM_APPROVAL',
              })}
              name='minimumApproval'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='minimumApproval' />
            </div>
          </div>
        </div>
        {/* Minimum Approval */}
      </div>
    </div>
  )
}
