import clsx from 'clsx'
import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {KTIcon, KTSVG, checkIsActive} from '../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  isDisabled?: boolean
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  isDisabled = false,
}) => {
  const {pathname} = useLocation()

  return (
    <>
      {isDisabled ? (
        <div className='menu-item me-lg-1'>
          <Link to={to} className={clsx('menu-link py-3')}>
            {isDisabled && (
              <span className='menu-icon'>
                <KTIcon iconName='lock-3' className='fs-3 text-danger ' />
              </span>
            )}

            <span style={{opacity: '50%'}} className='menu-title'>
              {title}
            </span>

            {hasArrow && <span className='menu-arrow'></span>}
          </Link>
        </div>
      ) : (
        <div className='menu-item me-lg-1'>
          <Link
            className={clsx('menu-link py-3', {
              active: checkIsActive(pathname, to),
            })}
            to={to}
          >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}

            {icon && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}

            {fontIcon && (
              <span className='menu-icon'>
                <i className={clsx('bi fs-3', fontIcon)}></i>
              </span>
            )}

            <span className='menu-title'>{title}</span>

            {hasArrow && <span className='menu-arrow'></span>}
          </Link>
        </div>
      )}
    </>
  )
}

export {MenuItem}
