import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  activity_description: string
}

export const WorkflowActivityDescriptionCell: React.FC<Props> = ({activity_description}) => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-column'>
      <span className={`${!activity_description && 'text-gray-600'}`}>
        {!activity_description
          ? intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.LABEL.GENERIC_ACTIVITY'})
          : activity_description}
      </span>
    </div>
  )
}
