/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {App} from '../App'
import {useAuth} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {PrivateRoutes} from './PrivateRoutes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  const AuthPage = lazy(() => import('../modules/auth/AuthPage'))
  const Logout = lazy(() => import('../modules/auth/Logout'))
  const ViewEquipment = lazy(() => import('../pages/asset/equipment/equipment-view/EquipmentView'))
  const RegisterOccurrencePage = lazy(
    () => import('../pages/audit/RegisterOccurrence/RegisterOccurrencePage')
  )
  const EmployeeDetails = lazy(() => import('../pages/settings/scan-employee/EmployeeDetails'))
  const CertificateTransaction = lazy(
    () => import('../pages/asset/transaction/certificate/CertificateTransaction')
  )

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route
                path='certificate/:publicKey'
                element={
                  <>
                    <MasterLayout />
                    <CertificateTransaction />
                  </>
                }
              />
              <Route path='audit/register-occurrence' element={<RegisterOccurrencePage />} />
              <Route path='safetyTag/:safetyTag' element={<MasterLayout />}>
                <Route index element={<ViewEquipment />} />
              </Route>
              <Route path='employeeDetails/:publicKey' element={<MasterLayout />}>
                <Route index element={<EmployeeDetails />} />
              </Route>

              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
