import {useIntl} from 'react-intl'
//import {EquipmentListFilter} from './EquipmentListFilter'

const PendingWorkAuthorizationListToolbar = () => {
  const intl = useIntl()

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/*<EquipmentListFilter />*/}

      {/* begin::Export */}
      {/* <ListExport endpoint='' query='' /> */}
      {/* end::Export */}

      {/* begin::Add user */}

      {/* <button type='button' className='btn btn-primary' onClick={openAd PendingWorkAuthorization}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({id: "HAZARD_MANAGEMENT.BUTTON.NEW"})}{' '}
      </button> */}
      {/* <button onClick={() => setStateNewAuthorization(null)} className='btn btn-primary'>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        New Authorization
      </button> */}

      {/* end::Add user */}
    </div>
  )
}

export {PendingWorkAuthorizationListToolbar}
