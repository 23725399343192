import {useListView} from '../../core/ListViewProvider'
import {HazardManagementListGrouping} from './HazardManagementListGrouping'
import {HazardManagementListSearchComponent} from './HazardManagementListSearchComponent'
import {HazardManagementListToolbar} from './HazardManagementListToolbar'

const HazardManagementListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <HazardManagementListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <HazardManagementListGrouping /> : <HazardManagementListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {HazardManagementListHeader}
