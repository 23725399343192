import {PublicKey} from '../../../../../_metronic/helpers'

export interface ICreateWorkflow {
  table: string
  table_publicKey: PublicKey
  genericActivity: 'true' | 'false' | true | false
  activity_publicKey?: PublicKey
  description: string

  level: '1' | '2' | '3'
  minimumApproval: string

  level_publicKey: PublicKey
  user_publicKey: PublicKey
}

export const modalInitialValues: ICreateWorkflow = {
  table: '',
  table_publicKey: '',
  genericActivity: false,
  activity_publicKey: '',
  description: '',

  level: '1',
  minimumApproval: '',

  level_publicKey: '',
  user_publicKey: '',
}

export interface ICreateWorkflowStatus {
  publicKey: string
  error: number
  messages: string
}
