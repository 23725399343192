import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../_metronic/helpers'
import {ControlMeasure, ControlMeasureQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const CONTROL_MEASURE_URL = `${API_ALPHA}/customer/risk/controlMeasure`
const GROUPED_CONTROL_MEASURE_URL = `${API_ALPHA}/customer/risk/listControlMeasureByHazardGrouped`
const REQUEST_EQUIPMENTS_BY_PRODUCT_CATEGORY = `${API_ALPHA}/customer/asset/listEquipmentByProductCategory`
const RESCUE_PLAN_URL = `${API_ALPHA}/customer/risk/rescuePlanProcedure`
const MEDICAL_ROUTINES_URL = `${API_ALPHA}/general/logged/getMedicalRoutine`

export const getMedicalRoutines = () => {
  return axios.get(MEDICAL_ROUTINES_URL).then((d) => d.data)
}

export const getControlMeasures = (
  query: string,
  publicKey: string
): Promise<ControlMeasureQueryResponse> => {
  return axios
    .get(`${GROUPED_CONTROL_MEASURE_URL}/${publicKey}?${query}`)
    .then((d: AxiosResponse<ControlMeasureQueryResponse>) => d.data)
}

export const getEquipmentsByProductCategory = (
  publicKey: string
): Promise<ControlMeasureQueryResponse> => {
  return axios
    .get(`${REQUEST_EQUIPMENTS_BY_PRODUCT_CATEGORY}/${publicKey}`)
    .then((d: AxiosResponse<ControlMeasureQueryResponse>) => d.data)
}

export const getControlMeasureById = (
  publicKey: PublicKey
): Promise<ControlMeasure | undefined> => {
  return axios
    .get(`${CONTROL_MEASURE_URL}/${publicKey}`)
    .then((response: AxiosResponse<Response<ControlMeasure>>) => response.data)
    .then((response: Response<ControlMeasure>) => response.data)
}

export const createControlMeasure = (
  controlMeasure: ControlMeasure
): Promise<ControlMeasure | undefined> => {
  return axios
    .post(CONTROL_MEASURE_URL, controlMeasure)
    .then((response: AxiosResponse<Response<ControlMeasure>>) => response.data)
    .then((response: Response<ControlMeasure>) => response.data)
}

export const updateControlMeasure = (
  controlMeasure: ControlMeasure
): Promise<ControlMeasure | undefined> => {
  return axios
    .put(`${CONTROL_MEASURE_URL}/${controlMeasure.publicKey}`, controlMeasure)
    .then((response: AxiosResponse<Response<ControlMeasure>>) => response.data)
    .then((response: Response<ControlMeasure>) => response.data)
}

export const deleteControlMeasure = (controlMeasureId: PublicKey): Promise<void> => {
  return axios
    .delete(`${CONTROL_MEASURE_URL}/${controlMeasureId}`)
    .then((res) => res.data.messages.toString())
}

export const deleteSelectedControlMeasures = (
  controlMeasurePublicKey: Array<PublicKey>
): Promise<void> => {
  const requests = controlMeasurePublicKey.map((publicKey) =>
    axios.delete(`${CONTROL_MEASURE_URL}/${publicKey}`)
  )
  return axios.all(requests).then(() => {})
}

export const createRescuePlan = (rescuePlan: any): Promise<any | undefined> => {
  return axios
    .post(RESCUE_PLAN_URL, rescuePlan)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

export const updateRescuePlan = (rescuePlan: any): Promise<any | undefined> => {
  return axios
    .put(`${RESCUE_PLAN_URL}/${rescuePlan.publicKey}`, rescuePlan)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
