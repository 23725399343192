/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  resolved_issues_table: string
}

const ResolvedIssuesTableCell: FC<Props> = ({resolved_issues_table}) => {
  const intl = useIntl()

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {resolved_issues_table === 'equipment' && (
          <span className={`badge badge-light-primary fw-bolder`}>
            {intl.formatMessage({id: 'ACCESS.PENDING_ISSUES.TABLE.EQUIPMENT'})}
          </span>
        )}
        {resolved_issues_table === 'training' && (
          <span className={`badge badge-light-danger fw-bolder`}>
            {intl.formatMessage({id: 'ACCESS.PENDING_ISSUES.TABLE.TRAINING'})}
          </span>
        )}
        {resolved_issues_table === 'medical' && (
          <span className={`badge badge-light-success fw-bolder`}>
            {intl.formatMessage({id: 'ACCESS.PENDING_ISSUES.TABLE.MEDICAL'})}
          </span>
        )}
      </div>
    </div>
  )
}

export {ResolvedIssuesTableCell}
