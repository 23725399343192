import axios, {AxiosResponse} from 'axios'
import {MapQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const REQUEST_TRANSACTION_INFO_MAP = `${API_ALPHA}/v2/customer/asset/transactionInformationMap`

const getTransactionInformationMap = () => {
  return axios
    .get(`${REQUEST_TRANSACTION_INFO_MAP}`)
    .then((d: AxiosResponse<MapQueryResponse>) => d.data)
}

export {getTransactionInformationMap}
