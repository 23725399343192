import React from 'react'
import TransactionCertificate from '../components/TransactionCertificate'

type Props = {
  transactionPublicKey: string
}

const TransactionResult: React.FC<Props> = ({transactionPublicKey}) => {
  return <TransactionCertificate transactionPublicKey={transactionPublicKey} />
}

export default TransactionResult
