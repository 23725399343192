import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {PendingWorkAuthorizationListSearchComponent} from './PendingWorkAuthorizationListSearchComponent'
import {PendingWorkAuthorizationListToolbar} from './PendingWorkAuthorizationListToolbar'

const PendingWorkAuthorizationListHeader = () => {
  const {selected} = useListView()

  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const intl = useIntl()

  return (
    <div className='card-header border-0 pt-6'>
      <PendingWorkAuthorizationListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <button onClick={() => setItemIdForUpdate(null)} className='btn btn-primary'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION.MASS_APPROVAL.TITLE'})}
        </button>
        {selected.length > 0 ? '' : <PendingWorkAuthorizationListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PendingWorkAuthorizationListHeader}
