import {useFormik} from 'formik'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {acceptIntegrate} from '../core/_requests'

type RouteParams = {
  tokenIntegrate: string
}

const initialValues = {
  accepted: '',
}

export function AcceptIntegrate() {
  const intl = useIntl()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState('')

  const {tokenIntegrate} = useParams<RouteParams>()

  const formik = useFormik({
    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        acceptIntegrate(tokenIntegrate)
          .then(({data}) => {
            setLoading(false)
            if (data.error === 1) {
              setHasErrors(true)
              setErrorMessage(data.messages)
              toast.error(intl.formatMessage({id: 'TOAST.ERROR.CREATE'}))
            } else {
              setHasErrors(false)
              setErrorMessage('')
              toast.success(intl.formatMessage({id: 'AUTH.ACCEPT_INTEGRATE.SUBMIT.SUCCESS'}))
              navigate('/auth/login')
            }
            setLoading(false)
          })
          .catch((error) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setErrorMessage(error.response.data.messages?.error)
            toast.error(intl.formatMessage({id: 'TOAST.SUBMIT.FAILED'}))
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>
            {intl.formatMessage({id: 'AUTH.ACCEPT_INTEGRATE.TITLE'})}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'AUTH.ACCEPT_INTEGRATE.SUBTITLE'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errorMessage}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.ACCEPT_INTEGRATE.BUTTON.SUBMIT'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'AUTH.ACCEPT_INTEGRATE.BUTTON.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_verify_login_form_cancel_button'
              className='btn btn-lg btn-danger w-100 mb-5'
            >
              {intl.formatMessage({id: 'AUTH.ACCEPT_INTEGRATE.BUTTON.CANCEL'})}
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
