import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {forgotPassword} from '../core/_requests'

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [submitAction, setSubmitAction] = useState(true)
  const intl = useIntl()

  const initialValues = {
    environment: 'customer',
    email: '',
  }

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'VALIDATION.EMAIL'}))
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {min: '3'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {max: '50'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setStatus('')
      setTimeout(() => {
        forgotPassword(values)
          .then(({data}) => {
            setLoading(false)
            if (data.error === 0) {
              setSubmitAction(false)
              toast.success(intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.SUBMIT.SUCCESS'}))
              setStatus('')
            } else {
              toast.error(intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.VALIDATION.ERROR'}))
            }
          })
          .catch((err) => {
            toast.error(intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.SUBMIT.ERROR'}))
            setLoading(false)
            setSubmitting(false)
            setStatus(err.response.data.messages.error)
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_forgot_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>
            {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.TITLE'})}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.SUBTITLE'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger text-center mb-8'>
            <div className='text-danger alert-text fw-bold'>{formik.status}</div>
          </div>
        )}

        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <input
            type='email'
            placeholder='email@example.com'
            disabled={!submitAction}
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          {submitAction && (
            <>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.BUTTON.SUBMIT'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.BUTTON.PLEASE_AWAIT'})}...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </>
          )}
          {!submitAction && (
            <>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_forgot_form_cancel_button'
                  className='btn btn-lg btn-success fw-bolder'
                >
                  {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.BUTTON.RETURN_LOGIN'})}
                </button>
              </Link>{' '}
            </>
          )}
        </div>

        {/* end::Form group */}
      </form>
    </>
  )
}
