import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../../../_metronic/helpers'
import {Building, BuildingQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const BUILDING_LIST = `${API_ALPHA}/customer/settings/building`

const getBuildings = (query: string) => {
  return axios
    .get(`${BUILDING_LIST}?${query}`)
    .then((d: AxiosResponse<BuildingQueryResponse>) => d.data)
}

const getBuildingById = (publicKey: PublicKey): Promise<Building | undefined> => {
  return axios
    .get(`${BUILDING_LIST}/${publicKey}`)
    .then((response: AxiosResponse<Response<Building>>) => response.data)
    .then((response: Response<Building>) => response.result)
}

const createBuilding = (building: Building): Promise<Building | Response<Building> | undefined> => {
  return axios
    .post(BUILDING_LIST, building)
    .then((response: AxiosResponse<Response<Building>>) => response.data)
    .then((response: Response<Building>) => response)
}

const updateBuilding = (building: Building): Promise<Building | undefined> => {
  return axios
    .put(`${BUILDING_LIST}/${building.publicKey}`, building)
    .then((response: AxiosResponse<Response<Building>>) => response.data)
    .then((response: Response<Building>) => response as Response<Building>['data'])
}

const deleteBuilding = (key: PublicKey) => {
  return axios.delete(`${BUILDING_LIST}/${key}`)
}

const deleteSelectedBuildings = (buildingPublicKey: Array<PublicKey>) => {
  const requests = buildingPublicKey.map((publicKey) =>
    axios.delete(`${BUILDING_LIST}/${publicKey}`)
  )
  return axios.all(requests).then((res) => {
    res.map((item) => {
      let dividedUrl = item.config.url?.split('/') || ''
      item.data.publicKey = dividedUrl[dividedUrl.length - 1]
      return item
    })
    return res
  })
}

export {
  getBuildings,
  deleteBuilding,
  deleteSelectedBuildings,
  createBuilding,
  updateBuilding,
  getBuildingById,
}
