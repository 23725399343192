/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {PublicKey} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../core/QueryResponseProvider'

type Props = {
  publicKey: PublicKey
}

const WorkAuthorizationActionsCell: FC<Props> = ({publicKey}) => {
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const intl = useIntl()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        {/* begin::Menu Status */}
        {/*<a
          onClick={openEditForm}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          <span className='svg-icon svg-icon-3'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z'
                fill='currentColor'
              ></path>
              <path
                opacity='0.3'
                d='M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z'
                fill='currentColor'
              ></path>
            </svg>
          </span>
        </a>*/}
        {/* end::Menu Status */}

        {/* begin::Menu Delete */}
        <Link to={`/access/work-authorization/view/${publicKey}`}>
          <a
            data-kt-users-table-filter='delete_row'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          >
            <span className='svg-icon svg-icon-3'>
              <i className='fa fa-eye fs-2'></i>
            </span>
          </a>
        </Link>
        {/* end::Menu Delete */}
      </div>

      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditForm}>
            Edit
          </a>
        </div>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div>
      </div> */}
    </>
  )
}

export {WorkAuthorizationActionsCell}
