import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../_metronic/helpers'
import {ControlMeasure, ControlMeasureQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const CONTROL_MEASURE_URL = `${API_ALPHA}/customer/risk/controlMeasure`
const REQUEST_EQUIPMENTS_BY_PRODUCT_CATEGORY = `${API_ALPHA}/customer/asset/listEquipmentsByProductCategory`
const CONTROL_MEASURE_BY_HAZARD_URL = `${API_ALPHA}/customer/risk/listControlMeasureByHazard`

const getControlMeasures = (
  table: string,
  publicKey: string,
  query?: string
): Promise<ControlMeasureQueryResponse> => {
  return axios
    .get(`${CONTROL_MEASURE_BY_HAZARD_URL}/${publicKey}?filter_table=${table}&${query}`)
    .then((d: AxiosResponse<ControlMeasureQueryResponse>) => d.data)
}

const getEquipmentsByProductCategory = (
  publicKey: string
): Promise<ControlMeasureQueryResponse> => {
  return axios
    .get(`${REQUEST_EQUIPMENTS_BY_PRODUCT_CATEGORY}/${publicKey}`)
    .then((d: AxiosResponse<ControlMeasureQueryResponse>) => d.data)
}

const getControlMeasureById = (publicKey: PublicKey): Promise<ControlMeasure | undefined> => {
  return axios
    .get(`${CONTROL_MEASURE_URL}/${publicKey}`)
    .then((response: AxiosResponse<Response<ControlMeasure>>) => response.data)
    .then((response: Response<ControlMeasure>) => response.data)
}

const createControlMeasure = (
  controlMeasure: ControlMeasure
): Promise<ControlMeasure | undefined> => {
  return axios
    .post(CONTROL_MEASURE_URL, controlMeasure)
    .then((response: AxiosResponse<Response<ControlMeasure>>) => response.data)
    .then((response: Response<ControlMeasure>) => response.data)
}

const updateControlMeasure = (
  controlMeasure: ControlMeasure
): Promise<ControlMeasure | undefined> => {
  return axios
    .put(`${CONTROL_MEASURE_URL}/${controlMeasure.publicKey}`, controlMeasure)
    .then((response: AxiosResponse<Response<ControlMeasure>>) => response.data)
    .then((response: Response<ControlMeasure>) => response.data)
}

const deleteControlMeasure = (controlMeasureId: PublicKey) => {
  return axios.delete(`${CONTROL_MEASURE_URL}/${controlMeasureId}`).then((res) => res.data)
}

const deleteSelectedControlMeasures = (controlMeasurePublicKey: Array<PublicKey>) => {
  const requests = controlMeasurePublicKey.map((publicKey) =>
    axios.delete(`${CONTROL_MEASURE_URL}/${publicKey}`)
  )
  return axios.all(requests).then((res) => {
    res.map((item) => {
      let dividedUrl = item.config.url?.split('/') || ''
      item.data.publicKey = dividedUrl[dividedUrl.length - 1]
      return item
    })
    return res
  })
}

export {
  getControlMeasures,
  deleteControlMeasure,
  deleteSelectedControlMeasures,
  getControlMeasureById,
  createControlMeasure,
  updateControlMeasure,
  getEquipmentsByProductCategory,
}
