import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  table: string
}

export const WorkflowTableCell: React.FC<Props> = ({table}) => {
  const intl = useIntl()
  const type_translation = `RISK_ANALYSIS.TABLE.TYPE.${table?.toUpperCase()}`
  const color =
    table === 'activity'
      ? 'primary'
      : table === 'branch'
      ? 'info'
      : table === 'building'
      ? 'warning'
      : table === 'area'
      ? 'danger'
      : 'success'

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className={`badge badge-light-${color} fw-bolder`}>
          {intl.formatMessage({id: type_translation})}
        </span>
      </div>
    </div>
  )
}
