import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../../../_metronic/helpers'
import {Branch, BranchQueryResponse, IMapBoxGeolocationResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const BUILDING_LIST = `${API_ALPHA}/customer/settings/branch`

const getBranches = (query: string) => {
  return axios
    .get(`${BUILDING_LIST}?${query}`)
    .then((d: AxiosResponse<BranchQueryResponse>) => d.data)
}

const getAddressMapBox = (search: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_GEOCODE_URL}forward?q=${search}&proximity=ip&access_token=${process.env.REACT_APP_MAP_BOX_TOKEN}`
    )
    .then((d: AxiosResponse<IMapBoxGeolocationResponse>) => d.data.features)
}

const getBranchById = (publicKey: PublicKey): Promise<Branch | undefined> => {
  return axios
    .get(`${BUILDING_LIST}/${publicKey}`)
    .then((response: AxiosResponse<Response<Branch>>) => response.data)
    .then((response: Response<Branch>) => response.result)
}

const createBranch = (branch: Branch): Promise<Branch | Response<Branch> | undefined> => {
  return axios
    .post(BUILDING_LIST, branch)
    .then((response: AxiosResponse<Response<Branch>>) => response.data)
    .then((response: Response<Branch>) => response)
}

const updateBranch = (branch: Branch): Promise<Branch | undefined> => {
  return axios
    .put(`${BUILDING_LIST}/${branch.publicKey}`, branch)
    .then((response: AxiosResponse<Response<Branch>>) => response.data)
    .then((response: Response<Branch>) => response as Response<Branch>['data'])
}

const deleteBranch = (key: PublicKey) => {
  return axios.delete(`${BUILDING_LIST}/${key}`)
}

const deleteSelectedBranches = (branchPublicKey: Array<PublicKey>) => {
  const requests = branchPublicKey.map((publicKey) => axios.delete(`${BUILDING_LIST}/${publicKey}`))
  return axios.all(requests).then((res) => {
    res.map((item) => {
      let dividedUrl = item.config.url?.split('/') || ''
      item.data.publicKey = dividedUrl[dividedUrl.length - 1]
      return item
    })
    return res
  })
}

export {
  createBranch,
  deleteBranch,
  deleteSelectedBranches,
  getAddressMapBox,
  getBranchById,
  getBranches,
  updateBranch,
}
