import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Webcam from 'react-webcam'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../components/table/loading/Loading'
import {requestCompleteTransaction, uploadTransactionNotesImage} from '../core/_requests'

type Props = {
  transactionPublicKey: string
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
  image: string
  setImage: React.Dispatch<React.SetStateAction<string>>
  transactionNotes: string
}

const TransactionNotesPhoto: React.FC<Props> = ({
  transactionPublicKey,
  setCurrentStepIndex,
  image,
  setImage,
  transactionNotes,
}) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)

  const webcamRef = useRef<Webcam>(null)

  const capture = async () => {
    const imageSrc: any = webcamRef.current?.getScreenshot()

    setImage(imageSrc)
  }

  const handleSubmit = async () => {
    //Envia imagem e notas para o backend, aguarda resposta para proxima step

    setIsLoading(true)

    const dataSend = {
      image: image,
      notes: transactionNotes,
    }

    const data: any = await uploadTransactionNotesImage(transactionPublicKey, dataSend)

    if (data) {
      if (data.httpStatus === '201') {
        const data = await requestCompleteTransaction(transactionPublicKey)

        if (data.httpStatus === '200') {
          setCurrentStepIndex(16)
        } else {
          setCurrentStepIndex(14)
        }
      } else {
        setIsLoading(false)
        setImage('')
      }
    }
  }

  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'

  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT)

  const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT,
  }

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    )
  }, [])

  const [isCameraPermited, setIsCameraPermited] = useState('Negado')

  async function checkCameraPermission() {
    try {
      const permissionStatus = await navigator.permissions.query({name: 'camera' as PermissionName})

      if (permissionStatus.state === 'granted') {
        // permissão concedida
        setIsCameraPermited('Permitido')
      } else if (permissionStatus.state === 'prompt') {
        // permissão ainda não concedida, solicitação de permissão necessária
        try {
          const stream = await navigator.mediaDevices.getUserMedia({video: true})
          stream.getTracks().forEach((track) => track.stop())
          setIsCameraPermited('Permitido')
        } catch {}
      } else {
        // permissão não concedida
        try {
          const stream = await navigator.mediaDevices.getUserMedia({video: true})
          stream.getTracks().forEach((track) => track.stop())
          setIsCameraPermited('Negado')
        } catch {}
      }
    } catch (error) {
      console.error('Erro ao verificar a permissão da câmera:', error)
    }
  }

  useEffect(() => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // se o usuário estiver em um dispositivo iOS
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
      // `v` será um array contendo a versão do iOS em cada índice
      if (v) {
        if (parseInt(v[1], 10) <= 15) {
          setIsCameraPermited('Permitido')
        } else {
          checkCameraPermission()
        }
      }
    } else {
      checkCameraPermission()
    }
  }, [isCameraPermited])

  return (
    <>
      {isLoading && <Loading />}
      <div className='w-100' style={{height: '68%', overflow: 'hidden'}}>
        {isCameraPermited === 'Permitido' && (
          <>
            {image === '' && (
              <div
                className='translate-middle d-flex flex-column gap-16'
                style={{
                  width: '200px',
                  height: '160px',
                  position: 'absolute',
                  zIndex: '999',
                  top: '20%',
                  left: '50%',
                  borderRadius: '40px',
                }}
              >
                <div className='d-flex gap-16'>
                  <div
                    className='border-primary'
                    style={{
                      width: '100px',
                      height: '80px',
                      borderTopLeftRadius: '10px',
                      borderTop: '4px solid white',
                      borderLeft: '4px solid white',
                    }}
                  ></div>
                  <div
                    className='border-primary'
                    style={{
                      width: '100px',
                      height: '80px',
                      borderTopRightRadius: '10px',
                      borderTop: '4px solid white',
                      borderRight: '4px solid white',
                    }}
                  ></div>
                </div>

                <div className='d-flex gap-16'>
                  <div
                    className='border-primary'
                    style={{
                      width: '100px',
                      height: '80px',
                      borderBottomLeftRadius: '10px',
                      borderBottom: '4px solid white',
                      borderLeft: '4px solid white',
                    }}
                  ></div>
                  <div
                    className='border-primary'
                    style={{
                      width: '100px',
                      height: '80px',
                      borderBottomRightRadius: '10px',
                      borderBottom: '4px solid white',
                      borderRight: '4px solid white',
                    }}
                  ></div>
                </div>
              </div>
            )}

            {image !== '' ? (
              <>
                <div
                  style={{height: '100%'}}
                  className='w-100 d-flex align-items-center justify-content-center px-6'
                >
                  <img
                    src={image}
                    style={{
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div
                  style={{height: '65%', position: 'absolute', top: '0', padding: '24px'}}
                  className='w-100 d-flex align-items-end justify-content-between'
                >
                  <div
                    onClick={() => setImage('')}
                    className='w-60px h-60px d-flex align-items-center justify-content-center bg-danger rounded-circle fixed cursor-pointer'
                  >
                    <span style={{fontSize: '30px'}} className='text-white fw-bold'>
                      X
                    </span>
                  </div>

                  <div
                    onClick={() => handleSubmit()}
                    className='w-60px h-60px d-flex align-items-center justify-content-center bg-success rounded-circle fixed cursor-pointer'
                  >
                    <span style={{fontSize: '30px'}} className='text-white fw-bold'>
                      ✓
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Webcam
                  audio={false}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  ref={webcamRef}
                  screenshotFormat='image/jpeg'
                  videoConstraints={{
                    ...videoConstraints,
                    facingMode,
                  }}
                />
                <div
                  onClick={() => capture()}
                  className='w-100 d-flex align-items-center justify-content-center position-absolute p-8 mt-12 rounded'
                  style={{bottom: '32%'}}
                >
                  <button className='btn bg-white rounded-circle p-12'> </button>
                </div>
              </>
            )}
            <div
              onClick={() => handleClick()}
              style={{position: 'absolute', top: '0'}}
              className='w-100 d-flex align-items-center justify-content-end px-4 py-2 mt-14 rounded'
            >
              <div>
                <KTSVG
                  className='svg-icon-primary svg-icon-2qx'
                  path='/media/svg/misc/switch-camera.svg'
                />
              </div>
            </div>
          </>
        )}
        {isCameraPermited === 'Negado' && (
          <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
            <button className='btn btn-primary' onClick={() => checkCameraPermission()}>
              {intl.formatMessage({id: 'CAMERA.REQUEST_PERMISSION'})}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default TransactionNotesPhoto
