import {useFormik} from 'formik'

import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import Select, {StylesConfig, Theme} from 'react-select'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {Loading} from '../../../../../components/table/loading/Loading'
import {Equipment} from '../../../../asset/equipment/equipment-list/core/_models'
import {getEquipments} from '../../../../asset/equipment/equipment-list/core/_requests'
import {useListView} from '../../control-measure-fiter-list/core/ListViewProvider'
import {useListView as useListViewGeneral} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {ControlMeasureStatus, initialControlMeasure} from '../core/_models'
import {createControlMeasure} from '../core/_requests'

type Props = {
  isControlMeasureLoading: boolean
}

export const reactSelectStyles: StylesConfig = {
  container: (base, state) => ({
    ...base,
    margin: 0,
    border: state.isFocused ? '1px solid var(--bs-input-focus-border-color)' : '',
    minHeight: 'calc(1.5em + 1.65rem + 2px)',
    padding: 0,
    fontSize: '1.15rem',
    borderRadius: '0.625rem',
    backgroundColor: state.isDisabled ? 'var(--bs-input-disabled-bg)' : base.backgroundColor,
    borderColor: state.isDisabled ? 'var(--bs-input-disabled-border-color)' : base.borderColor,
    color: state.isDisabled ? 'var(--bs-input-disabled-color)' : base.color,
    display: 'flex',
    alignItems: 'center',
  }),
  input: (base) => ({
    ...base,
    color: 'var(--bs-input-color)',
    cursor: 'text',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--bs-input-color)',
  }),
  menu: (base) => ({
    ...base,
    color: 'var(--bs-input-color)',
    backgroundColor: 'var(--bs-menu-dropdown-bg-color)',
    zIndex: 999,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? 'var(--bs-primary)' : base.backgroundColor,
    zIndex: 999,
  }),
  placeholder: (base) => ({
    ...base,
    color: '#a1a4b6',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 1.5rem',
  }),
  control: (base) => ({
    ...base,
    borderColor: 'var(--bs-gray-300)',
    background: '#00000000',
    height: '100%',
    width: '100%',
  }),
  clearIndicator: (base) => ({
    ...base,
    cursor: 'pointer',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    cursor: 'pointer',
  }),
}

export const theme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#00000000',
    primary25: 'var(--bs-menu-link-bg-color-active)',
    primary50: '#ffaa7a',
  },
})

const ControlMeasureEquipmentEditModalForm: FC<Props> = ({isControlMeasureLoading}) => {
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  const [createNew, setCreateNew] = useState(false)

  const [equipmentSearch, setEquipmentSearch] = useState('')
  const [equipmentOptions, setEquipmentOptions] = useState<Equipment[]>([])
  const [isFetching, setIsFetching] = useState(true)
  const [selection, setSelection] = useState<Equipment>({} as Equipment)

  const {controlMeasurePublicKey} = useParams()

  const {setItemIdForUpdate: setItemIdForUpdateGeneral} = useListViewGeneral()
  const {setItemIdForUpdate} = useListView()

  const [equipmentSearchTimeout, setEquipmentSearchTimeout] = useState() as any // n achei nenhum type de timeout socorro

  const equipmentFilter = (input: string) => {
    setEquipmentSearch(input)
    if (equipmentSearch === input) return
  }

  useEffect(() => {
    setIsFetching(true)
    if (equipmentSearchTimeout !== undefined) clearTimeout(equipmentSearchTimeout)

    if (equipmentSearch !== '')
      setEquipmentSearchTimeout(
        setTimeout(async () => {
          await getEquipmentsOptions(equipmentSearch)
        }, 250)
      )
    else getEquipmentsOptions(equipmentSearch)
    // eslint-disable-next-line
  }, [equipmentSearch])

  const editControlMeasureSchema = Yup.object().shape({
    /*sequence: Yup.string()
      .min(1, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {min: '1'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {max: '50'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),*/
    table: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    table_publicKey: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    hazard_publicKey: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }

    setItemIdForUpdate(undefined)
    setItemIdForUpdateGeneral(undefined)
  }

  const formik = useFormik({
    initialValues: initialControlMeasure,
    validationSchema: editControlMeasureSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = (await createControlMeasure(values)) as ControlMeasureStatus
        if (data?.error === 1) {
          setStatus(data?.messages)
          toast.error(intl.formatMessage({id: 'TOAST.ERROR.UPDATE'}))
        } else {
          toast.success(intl.formatMessage({id: 'TOAST.SUCCESS.UPDATE'}))
          cancel(true)
        }
      } catch (ex) {
        console.error(ex)
        toast.error(intl.formatMessage({id: 'TOAST.ERROR.UPDATE'}))
      } finally {
        setSubmitting(true)
        if (createNew) formik.resetForm()
        setCreateNew(false)
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue('table', 'equipment')
    formik.setFieldValue('hazard_publicKey', controlMeasurePublicKey)
    // eslint-disable-next-line
  }, [])

  async function getEquipmentsOptions(search?: string) {
    if (search === '' || search === undefined) {
      const query = {
        offset: '1',
        limit: '10',
      } as any

      const paramsConverted = Object.keys(query)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(`${query[key]}`)
        })
        .join('&')

      const {result} = await getEquipments(paramsConverted)

      setEquipmentOptions(result || [])
      result?.push({
        equipment_name: intl.formatMessage({id: 'REACT_SELECT_SEARCH_PLACEHOLDER'}),
      } as any)
      setIsFetching(false)
      return
    }
    const query = {
      offset: '1',
      limit: '10',
      search: search,
    } as any

    const paramsConverted = Object.keys(query)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(`${query[key]}`)
      })
      .join('&')

    const {result} = await getEquipments(paramsConverted)
    setEquipmentOptions(result || [])
    setIsFetching(false)
  }

  function handleEquipmentSelection(value: any) {
    const equipmentItem = equipmentOptions?.find(
      (item) => item.publicKey === (value?.value as string)
    )
    setSelection(equipmentItem as Equipment)

    formik.setFieldValue('table_publicKey', value?.value)
  }

  return (
    <>
      <form
        id='kt_modal_add_inspectionStep_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}

        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_inspectionStep_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_inspectionStep_header'
          data-kt-scroll-wrappers='#kt_modal_add_inspectionStep_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='pb-lg-10'>
            <h3>{intl.formatMessage({id: 'HAZARD_MANAGEMENT.CONTROL_MEASURE.ASSET.TITLE'})}</h3>
          </div>

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              {Object.keys(formik.status).map((key) => (
                <div className='alert-text font-weight-bold'>{formik.status[key]}</div>
              ))}
            </div>
          )}

          {/* Equipment* */}
          <div className='col-md-12 fv-row mb-14'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span className='required'>
                {intl.formatMessage({
                  id: 'HAZARD_MANAGEMENT.CONTROL_MEASURE.ASSET.LABEL.REQUIREMENT',
                })}
              </span>
            </label>

            <Select
              onInputChange={equipmentFilter}
              options={equipmentOptions?.map(
                ({code, equipment_name, publicKey: equipment_publicKey}, index) => {
                  return {
                    label: `${code && code !== '' ? `${code} -` : ''} ${equipment_name}`,
                    value: equipment_publicKey as string,
                    disabled:
                      equipment_name ===
                      intl.formatMessage({id: 'REACT_SELECT_SEARCH_PLACEHOLDER'}),
                  }
                }
              )}
              isOptionDisabled={(option: any) => option.disabled}
              styles={reactSelectStyles}
              theme={theme}
              className='form-control form-control-lg form-control'
              name='table_publicKey'
              isSearchable={true}
              placeholder={intl.formatMessage({
                id: 'HAZARD_MANAGEMENT.CONTROL_MEASURE.ASSET.PLACEHOLDER.REQUIREMENT',
              })}
              onChange={handleEquipmentSelection}
              isLoading={isFetching}
            />
            {formik.touched.table_publicKey && formik.errors.table_publicKey && (
              <div className='text-danger mt-2'>
                <span role='alert'>{formik.errors.table_publicKey}</span>
              </div>
            )}
          </div>
          {/* Equipment* */}

          <div className='row mb-5'>
            {/*Manufacturer*/}
            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                <span>{intl.formatMessage({id: 'EQUIPMENT.FORM.LABEL.MANUFACTURER'})}</span>
              </label>

              <div className='position-relative'>
                <input
                  value={selection.manufacturer_corporateName || ''}
                  className='form-control form-control-lg'
                  disabled
                />
              </div>
            </div>

            {/*Type*/}
            <div className='col-md-6 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                <span>{intl.formatMessage({id: 'EQUIPMENT.FORM.LABEL.PRODUCT_TYPE'})}</span>
              </label>

              <div className='position-relative'>
                <input
                  value={selection.productType_name || ''}
                  className='form-control form-control-lg'
                  disabled
                />
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            {/*Group*/}
            <div className='col-md-6 fv-row mb-5'>
              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                <span>{intl.formatMessage({id: 'EQUIPMENT.FORM.LABEL.PRODUCT_GROUP'})}</span>
              </label>

              <div className='position-relative'>
                <input
                  value={selection.productGroup_name || ''}
                  className='form-control form-control-lg'
                  disabled
                />
              </div>
            </div>

            {/*Class*/}
            <div className='col-md-6 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                <span>{intl.formatMessage({id: 'EQUIPMENT.FORM.LABEL.PRODUCT_CLASS'})}</span>
              </label>

              <div className='position-relative'>
                <input
                  value={selection.productClass_name || ''}
                  className='form-control form-control-lg'
                  disabled
                />
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            {/*Category*/}
            <div className='col-md-12 fv-row mb-5'>
              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                <span>{intl.formatMessage({id: 'EQUIPMENT.FORM.LABEL.PRODUCT_CATEGORY'})}</span>
              </label>

              <div className='position-relative'>
                <input
                  value={selection.productCategory_name || ''}
                  className='form-control form-control-lg'
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-inspection-steps-modal-action='cancel'
            disabled={formik.isSubmitting || isControlMeasureLoading}
          >
            {intl.formatMessage({id: 'FORM.BUTTON.DISCARD'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-inspection-steps-modal-action='submit'
            disabled={
              isControlMeasureLoading || formik.isSubmitting || !formik.isValid || !formik.touched
            }
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'FORM.BUTTON.SUBMIT'})}
            </span>
            {(formik.isSubmitting || isControlMeasureLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'FORM.BUTTON.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isControlMeasureLoading) && <Loading />}
    </>
  )
}

export {ControlMeasureEquipmentEditModalForm}
