import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, CompanyStorageModel, ModuleStorageModel, UserModel} from './_models'
import {getUserByToken, getUserFeatures, getUserModule} from './_requests'
import * as authHelper from './AuthHelpers'
import * as settingsHelper from './SettingsHelpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  companyStorage: CompanyStorageModel | undefined
  saveCompanyStorage: (settings: CompanyStorageModel | undefined) => void
  moduleStorage: ModuleStorageModel | undefined
  saveModuleStorage: (settings: ModuleStorageModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  currentFeatures: any | undefined
  currentFeaturesObject: any | undefined
  setCurrentFeatures: Dispatch<SetStateAction<any>>
  currentModules: any | undefined
  setCurrentModules: Dispatch<SetStateAction<any>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  companyStorage: settingsHelper.getCompanyStorage(),
  saveCompanyStorage: () => {},
  moduleStorage: settingsHelper.getModuleStorage(),
  saveModuleStorage: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentModules: undefined,
  setCurrentModules: () => {},
  currentFeatures: undefined,
  currentFeaturesObject: undefined,
  setCurrentFeatures: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [companyStorage, setCompanyStorage] = useState<CompanyStorageModel | undefined>(
    settingsHelper.getCompanyStorage()
  )
  const [moduleStorage, setModuleStorage] = useState<ModuleStorageModel | undefined>(
    settingsHelper.getModuleStorage()
  )
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentModules, setCurrentModules] = useState<any | undefined>()
  const [currentFeatures, setCurrentFeatures] = useState<any | undefined>()
  const currentFeaturesObject =
    typeof currentFeatures === 'object'
      ? currentFeatures.reduce((feature: any, curr: any) => {
          feature[curr.feature] = {create: curr.create, update: curr.update, delete: curr.delete}
          return feature
        }, {})
      : undefined

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const saveCompanyStorage = async (company: CompanyStorageModel | undefined) => {
    setCompanyStorage(company)
    if (company) {
      settingsHelper.setCompanyStorage(company)
      const {data} = await getUserFeatures()

      const modules = await getUserModule()
      setCurrentModules(modules.data)
      setCurrentFeatures(data)
    } else {
      settingsHelper.removeCompanyStorage()
    }
  }

  const saveModuleStorage = (module: ModuleStorageModel | undefined) => {
    setModuleStorage(module)
    if (module) {
      settingsHelper.setModuleStorage(module)
    } else {
      settingsHelper.removeModuleStorage()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    saveCompanyStorage(undefined)
    saveModuleStorage(undefined)
    setCurrentUser(undefined)
    setCurrentFeatures(undefined)
    setCurrentModules(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        companyStorage,
        saveCompanyStorage,
        moduleStorage,
        saveModuleStorage,
        currentUser,
        setCurrentUser,
        currentModules,
        setCurrentModules,
        currentFeatures,
        setCurrentFeatures,
        currentFeaturesObject,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, setCurrentFeatures, setCurrentModules} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (accessToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken()
          const {features}: any = await getUserFeatures()
          const {modules}: any = await getUserModule()
          if (data.environment !== 'customer') {
            if (!didRequest.current) {
              logout()
            }
          }

          if (data) {
            setCurrentUser(data)
          }
          if (features) {
            setCurrentFeatures(features)
          }
          if (modules) {
            setCurrentModules(modules)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    const requestFeatures = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserFeatures()
          if (data) {
            setCurrentFeatures(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      }
    }

    const requestModules = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserModule()
          if (data) {
            setCurrentModules(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      }
    }

    if (auth && auth.accessToken) {
      requestUser(auth.accessToken)
      requestFeatures()
      requestModules()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit, AuthProvider, useAuth}
