/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useLocation, useParams} from 'react-router-dom'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from './QueryRequestProvider'
import {ControlMeasure} from './_models'
import {getControlMeasures} from './_requests'

interface TableItem {
  table_name: string
  quantity: string
  type: 'rescue-plan' | 'training' | 'asset' | 'medical'
}

const QueryResponseContext = createResponseContext<ControlMeasure>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {controlMeasurePublicKey} = useParams()

  const {pathname} = useLocation()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const table = pathname.split('/')[4]

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.CONTROL_MEASURE_FILTER_LIST}-${query}`,
    () => {
      return getControlMeasures(table, controlMeasurePublicKey as any, query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.result || []
}

export const useQueryBreadcrumbData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
