import {KTCard} from '../../../../../_metronic/helpers/components/KTCard'
import ApproveWorkAuthorizationModal from './approveWorkAuthorizationModal/ApproveWorkAuthorizationModal'
import {PendingWorkAuthorizationListHeader} from './components/header/PendingWorkAuthorizationListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PendingWorkAuthorizationDetailsModal} from './pendingWorkAuthorization-details-modal/PendingWorkAuthorizationDetailsModal'
import {PendingWorkAuthorizationTable} from './table/PendingWorkAuthorizationTable'

const ApproveOrRejectWorkAuthorizationList = ({closeModal}: any) => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard>
        <PendingWorkAuthorizationListHeader />
        <PendingWorkAuthorizationTable closeModal={closeModal} />
      </KTCard>
    </>
  )
}

const ApproveOrRejectWorkAuthorizationListWrapper = ({closeModal}: any) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ApproveOrRejectWorkAuthorizationList closeModal={closeModal} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ApproveOrRejectWorkAuthorizationListWrapper}
