import {Column} from 'react-table'
import {HazardManagement} from '../../core/_models'
import {WorkflowActionsCell} from './WorkflowActionsCell'
import WorkflowDescriptionCell from './WorkflowDescriptionCell'
import {WorkflowCustomHeader} from './WorkflowCustomHeader'
import {WorkflowTableCell} from './WorkflowTableCell'
import WorkflowApproversCell from './WorkflowApproversCell'
import {WorkflowSelectionCell} from './WorkflowSelectionCell'
import {WorkflowSelectionHeader} from './WorkflowSelectionHeader'
import {WorkflowActivityDescriptionCell} from './WorkflowActivityDescriptionCell'

const workflowColumns: ReadonlyArray<Column<HazardManagement>> = [
  {
    Header: (props) => <WorkflowSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => (
      <WorkflowSelectionCell publicKey={props.data[props.row.index].publicKey} />
    ),
  },
  // {
  //   Header: (props) => (
  //     <LocationsCustomHeader
  //       tableProps={props}
  //       title='RISK.ACTIVITIES_LIST.LOCATION_NAME'
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'location_name',
  //   Cell: ({...props}) => <LocationsInfoCell activityData={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => (
      <WorkflowCustomHeader
        tableProps={props}
        title='HAZARD_MANAGEMENT.TABLE.DESCRIPTION.TITLE'
        className='min-w-125px'
      />
    ),
    id: 'description',
    Cell: ({...props}) => (
      <WorkflowDescriptionCell description={props.data[props.row.index].description} />
    ),
  },
  {
    Header: (props) => (
      <WorkflowCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.ACTIVITY_DESCRIPTION'
        className='min-w-125px'
      />
    ),
    id: 'activity_description',
    Cell: ({...props}) => (
      <WorkflowActivityDescriptionCell
        activity_description={props.data[props.row.index].activity_description}
      />
    ),
  },
  {
    Header: (props) => (
      <WorkflowCustomHeader
        tableProps={props}
        title='HAZARD_MANAGEMENT.TABLE.PARENT.TITLE'
        className='min-w-125px'
      />
    ),
    id: 'table',
    Cell: ({...props}) => <WorkflowTableCell table={props.data[props.row.index].table} />,
  },
  {
    Header: (props) => (
      <WorkflowCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.LOCATION_NAME'
        className='min-w-125px'
      />
    ),
    accessor: 'location_name',
  },

  {
    Header: (props) => (
      <WorkflowCustomHeader
        tableProps={props}
        title='ACCESS.WORKFLOW_LIST.APPROVERS'
        className='min-w-125px text-center'
      />
    ),
    id: 'workflow-approvers',
    Cell: ({...props}) => <WorkflowApproversCell count={props.data[props.row.index].users_count} />,
  },
  {
    Header: (props) => (
      <WorkflowCustomHeader
        tableProps={props}
        title='TABLE.ACTIONS.TITLE'
        className='text-end min-w-120px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <WorkflowActionsCell publicKey={props.data[props.row.index].publicKey} />,
  },
]

export {workflowColumns}
