import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'

type Props = {
  title: string
  icon: string
  url: string
  count: any
  color: string
}

const DashboardInfoButtons: React.FC<Props> = ({title, icon, url, count, color}) => {
  return (
    <>
      <div className='col d-flex justify-content-center'>
        <Link
          to={url}
          className='card card-stretched bg-body hoverable mb-xl-8 shadow-sm w-100 h-100'
        >
          <div className='card-body'>
            <span className='svg-icon svg-icon-body svg-icon-3x ms-n1'>
              <KTSVG className={color && `svg-icon-${color}`} path={icon} />
            </span>

            <div className='fw-bold fs-1 mb-2 mt-5 text-body'>{count}</div>
            <div className='fw-semibold text-body'>{title}</div>
          </div>
        </Link>
      </div>
    </>
  )
}

export {DashboardInfoButtons}
