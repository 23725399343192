import {Column} from 'react-table'
import {HazardManagement} from '../../core/_models'
import WorkAuthorizationAccessControlCell from './WorkAuthorizationAccessControlCell'
import {WorkAuthorizationActionsCell} from './WorkAuthorizationActionsCell'
import WorkAuthorizationActivityDescriptionCell from './WorkAuthorizationActivityDescriptionCell'
import {WorkAuthorizationCustomHeader} from './WorkAuthorizationCustomHeader'
import WorkAuthorizationPermissionCell from './WorkAuthorizationPermissionCell'
import {WorkAuthorizationSelectionCell} from './WorkAuthorizationSelectionCell'
import {WorkAuthorizationSelectionHeader} from './WorkAuthorizationSelectionHeader'
import WorkAuthorizationStatusCell from './WorkAuthorizationStatusCell'

const workAuthorizationColumns: ReadonlyArray<Column<HazardManagement>> = [
  {
    Header: (props) => <WorkAuthorizationSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => (
      <WorkAuthorizationSelectionCell publicKey={props.data[props.row.index].publicKey} />
    ),
  },
  // {
  //   Header: (props) => (
  //     <LocationsCustomHeader
  //       tableProps={props}
  //       title='RISK.ACTIVITIES_LIST.LOCATION_NAME'
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'location_name',
  //   Cell: ({...props}) => <LocationsInfoCell activityData={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => (
      <WorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.ACTIVITY_DESCRIPTION'
        className='min-w-125px'
      />
    ),
    id: 'activity_description',
    Cell: ({...props}) => (
      <WorkAuthorizationActivityDescriptionCell workAuthorization={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <WorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.EMPLOYEE_NAME'
        className='min-w-125px'
      />
    ),
    accessor: 'employee_name',
  },
  {
    Header: (props) => (
      <WorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.LOCATION_NAME'
        className='min-w-125px'
      />
    ),
    accessor: 'location_name',
  },
  {
    Header: (props) => (
      <WorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.REQUESTED_BY-NAME'
        className='min-w-125px'
      />
    ),
    accessor: 'requestedBy_user_fullName',
  },
  {
    Header: (props) => (
      <WorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.ACCESS_CONTROL'
        className='min-w-125px'
      />
    ),
    id: 'activity_accessControl',
    Cell: ({...props}) => (
      <WorkAuthorizationAccessControlCell workAuthorization={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <WorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.PERMISSION_REQUIRED'
        className='min-w-125px text-center'
      />
    ),
    id: 'activity_permitionRequired',
    Cell: ({...props}) => (
      <WorkAuthorizationPermissionCell workAuthorization={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <WorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.STATUS'
        className='min-w-125px text-center'
      />
    ),
    id: 'activity_status',
    Cell: ({...props}) => (
      <WorkAuthorizationStatusCell status={props.data[props.row.index].status} />
    ),
  },
  {
    Header: (props) => (
      <WorkAuthorizationCustomHeader
        tableProps={props}
        title='TABLE.ACTIONS.TITLE'
        className='text-end min-w-120px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <WorkAuthorizationActionsCell publicKey={props.data[props.row.index].publicKey} />
    ),
  },
]

export {workAuthorizationColumns}
