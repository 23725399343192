import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Webcam from 'react-webcam'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../components/table/loading/Loading'
import {requestFaceValidation} from '../core/_requests'

type Props = {
  transactionPublicKey: string
  safetyTagScanned: string
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
}

const FaceRecognition: React.FC<Props> = ({
  transactionPublicKey,
  safetyTagScanned,
  setCurrentStepIndex,
}) => {
  const intl = useIntl()
  const [imageFrozen, setImageFronzen] = useState(false)
  const [image, setImage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const webcamRef = useRef<Webcam>(null)

  const capture = async () => {
    //Envia imagem para o backend, aguarda resposta para proxima step

    const imageSrc: any = webcamRef.current?.getScreenshot()

    setIsLoading(true)
    setImage(imageSrc)
    setImageFronzen(true)

    const dataSend = {
      equipmentTransaction_publicKey: transactionPublicKey,
      employee_publicKey: safetyTagScanned.toLowerCase(),
      image: imageSrc,
    }

    const data = await requestFaceValidation(dataSend)

    if (data.httpStatus !== 400) {
      if (data.approved === true) {
        setCurrentStepIndex(15)
        setIsLoading(false)
        setImage('')
        setImageFronzen(false)
      } else {
        setCurrentStepIndex(14)
        setIsLoading(false)
        setImage('')
        setImageFronzen(false)
      }
    } else {
      setIsLoading(false)
      setImage('')
      setImageFronzen(false)
    }
  }

  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'

  const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT,
  }

  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT)

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    )
  }, [])

  const [isCameraPermited, setIsCameraPermited] = useState('Negado')

  async function checkCameraPermission() {
    try {
      const permissionStatus = await navigator.permissions.query({name: 'camera' as PermissionName})

      if (permissionStatus.state === 'granted') {
        // permissão concedida
        setIsCameraPermited('Permitido')
      } else if (permissionStatus.state === 'prompt') {
        // permissão ainda não concedida, solicitação de permissão necessária
        try {
          const stream = await navigator.mediaDevices.getUserMedia({video: true})
          stream.getTracks().forEach((track) => track.stop())
          setIsCameraPermited('Permitido')
        } catch {}
      } else {
        // permissão não concedida
        try {
          const stream = await navigator.mediaDevices.getUserMedia({video: true})
          stream.getTracks().forEach((track) => track.stop())
          setIsCameraPermited('Negado')
        } catch {}
      }
    } catch (error) {
      console.error('Erro ao verificar a permissão da câmera:', error)
    }
  }

  useEffect(() => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // se o usuário estiver em um dispositivo iOS
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
      // `v` será um array contendo a versão do iOS em cada índice
      if (v) {
        if (parseInt(v[1], 10) <= 15) {
          setIsCameraPermited('Permitido')
        } else {
          checkCameraPermission()
        }
      }
    } else {
      checkCameraPermission()
    }
  }, [isCameraPermited])

  return (
    <>
      {isLoading && <Loading />}
      <div className='w-100' style={{height: '68%', overflow: 'hidden'}}>
        {isCameraPermited === 'Permitido' && (
          <>
            <div
              className='translate-middle d-flex flex-column gap-16'
              style={{
                width: '200px',
                height: '160px',
                position: 'absolute',
                zIndex: '999',
                top: '20%',
                left: '50%',
                borderRadius: '40px',
              }}
            >
              <div className='d-flex gap-16'>
                <div
                  className='border-primary'
                  style={{
                    width: '100px',
                    height: '80px',
                    borderTopLeftRadius: '10px',
                    borderTop: '4px solid white',
                    borderLeft: '4px solid white',
                  }}
                ></div>
                <div
                  className='border-primary'
                  style={{
                    width: '100px',
                    height: '80px',
                    borderTopRightRadius: '10px',
                    borderTop: '4px solid white',
                    borderRight: '4px solid white',
                  }}
                ></div>
              </div>

              <div className='d-flex gap-16'>
                <div
                  className='border-primary'
                  style={{
                    width: '100px',
                    height: '80px',
                    borderBottomLeftRadius: '10px',
                    borderBottom: '4px solid white',
                    borderLeft: '4px solid white',
                  }}
                ></div>
                <div
                  className='border-primary'
                  style={{
                    width: '100px',
                    height: '80px',
                    borderBottomRightRadius: '10px',
                    borderBottom: '4px solid white',
                    borderRight: '4px solid white',
                  }}
                ></div>
              </div>
            </div>

            {imageFrozen === true ? (
              <img
                src={image}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <>
                {/* <CameraReaderQR videoRef={videoRef} canvasRef={canvasRef} /> */}
                <Webcam
                  audio={false}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  ref={webcamRef}
                  screenshotFormat='image/jpeg'
                  videoConstraints={{
                    ...videoConstraints,
                    facingMode,
                  }}
                />
                <div
                  onClick={() => capture()}
                  className='w-100 d-flex align-items-center justify-content-center position-absolute p-8 mt-12 rounded'
                  style={{bottom: '32%'}}
                >
                  <button className='btn bg-white rounded-circle p-12'> </button>
                </div>
              </>
            )}

            <div
              onClick={() => handleClick()}
              style={{position: 'absolute', top: '0'}}
              className='w-100 d-flex align-items-center justify-content-end px-4 py-2 mt-14 rounded'
            >
              <div>
                <KTSVG
                  className='svg-icon-primary svg-icon-2qx'
                  path='/media/svg/misc/switch-camera.svg'
                />
              </div>
            </div>
          </>
        )}
        {isCameraPermited === 'Negado' && (
          <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
            <button className='btn bg-white' onClick={() => checkCameraPermission()}>
              {intl.formatMessage({id: 'CAMERA.REQUEST_PERMISSION'})}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default FaceRecognition
