/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link, Outlet} from 'react-router-dom'

const AuthLayout = () => {
  const intl = useIntl()

  useEffect(() => {
    document.body.style.backgroundImage = `none`
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      document.body.style.backgroundImage = `url(${process.env.REACT_APP_CDN_WHITE_LABEL}/assets/app/login-bg.webp)`
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>
        <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a
              href={`${process.env.REACT_APP_WHITE_LABEL_WEBSITE}`}
              className='text-muted text-hover-primary px-2'
              target='_blank'
              rel='noreferrer'
            >
              {intl.formatMessage({id: 'AUTH.FOOTER.ABOUT'})}
            </a>

            <a
              href={`mailto:${process.env.REACT_APP_WHITE_LABEL_EMAIL}`}
              className='text-muted text-hover-primary px-2'
            >
              {intl.formatMessage({id: 'AUTH.FOOTER.CONTACT_US'})}
            </a>
          </div>
        </div>
      </div>
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{
          backgroundImage: `url(${process.env.REACT_APP_CDN_WHITE_LABEL}/assets/app/login-bg.webp)`,
        }}
      >
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <Link to='/' className='mb-12'>
            <img
              alt='Logo'
              src={`${process.env.REACT_APP_CDN_WHITE_LABEL}/assets/app/logo-login.png`}
              className='h-100px'
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
