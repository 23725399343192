import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../../../_metronic/helpers'
import {Country, CountryQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const COUNTRY_LIST = `${API_ALPHA}/customer/settings/company_country`

const getCountries = (query: string) => {
  return axios
    .get(`${COUNTRY_LIST}?${query}`)
    .then((d: AxiosResponse<CountryQueryResponse>) => d.data)
}

const createCountry = (country: Country): Promise<Country | Response<Country> | undefined> => {
  return axios
    .post(COUNTRY_LIST, country)
    .then((response: AxiosResponse<Response<Country>>) => response.data)
    .then((response: Response<Country>) => response)
}

const deleteCountry = (key: PublicKey) => {
  return axios.delete(`${COUNTRY_LIST}/${key}`)
}

const deleteSelectedCountries = (countryPublicKey: Array<PublicKey>) => {
  const requests = countryPublicKey.map((publicKey) => axios.delete(`${COUNTRY_LIST}/${publicKey}`))
  return axios.all(requests).then((res) => {
    res.map((item) => {
      let dividedUrl = item.config.url?.split('/') || ''
      item.data.publicKey = dividedUrl[dividedUrl.length - 1]
      return item
    })
    return res
  })
}

export {getCountries, deleteCountry, deleteSelectedCountries, createCountry}
