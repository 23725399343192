import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import DashboardAccessWrapper from './dashboard/DashboardAccessWrapper'
import WorkAuthorization from './workAuthorization/WorkAuthorization'
import Workflow from './workflow/Workflow'

const activityBreadCrumbs: Array<PageLink> = [
  {
    title: 'Access',
    path: '/access/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AccessPages: React.FC = () => {
  const [stateNewAuthorization, setStateNewAuthorization]: any = useState(undefined)
  const {currentFeaturesObject} = useAuth()
  const intl = useIntl()

  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <Outlet />
            </>
          }
        >
          <Route
            path='dashboard'
            element={
              <>
                <PageTitle breadcrumbs={activityBreadCrumbs}>Dashboard</PageTitle>
                <DashboardAccessWrapper
                  stateNewAuthorization={stateNewAuthorization}
                  setStateNewAuthorization={setStateNewAuthorization}
                />
              </>
            }
          />

          {currentFeaturesObject &&
            (currentFeaturesObject.workAuthorization ||
              currentFeaturesObject.workAuthorizationIssue) && (
              <Route
                path='work-authorization/*'
                element={
                  <>
                    <PageTitle breadcrumbs={activityBreadCrumbs}>
                      {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION'})}
                    </PageTitle>
                    <WorkAuthorization
                      stateNewAuthorization={stateNewAuthorization}
                      setStateNewAuthorization={setStateNewAuthorization}
                    />
                  </>
                }
              />
            )}
          {currentFeaturesObject && currentFeaturesObject.workflow && (
            <Route
              path='workflow/*'
              element={
                <>
                  <PageTitle breadcrumbs={activityBreadCrumbs}>
                    {intl.formatMessage({id: 'ACCESS.WORKFLOW'})}
                  </PageTitle>
                  <Workflow />
                </>
              }
            />
          )}

          <Route index element={<Navigate to='dashboard' />} />
        </Route>
      </Routes>
    </>
  )
}

export default AccessPages
