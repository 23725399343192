import {FC, useEffect} from 'react'
import {SwapperComponent} from '../../../assets/ts/components'

const HeaderLabeling: FC = () => {
  useEffect(() => {
    SwapperComponent.reinitialization()
  }, [])

  return <div></div>
}

export {HeaderLabeling}
