import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  workAuthorization: any
}

const WorkAuthorizationPermissionCell: React.FC<Props> = ({workAuthorization}) => {
  const intl = useIntl()

  return (
    <div className='text-center'>
      <span
        className={`badge ${
          workAuthorization.activity_permitionRequired === '0' ? 'badge-success' : 'badge-danger'
        }`}
      >
        {workAuthorization.activity_permitionRequired === '0'
          ? intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.NOT_REQUIRED'})
          : intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.REQUIRED'})}
      </span>
    </div>
  )
}

export default WorkAuthorizationPermissionCell
