/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {PaginationState, QueryRequestContextProps} from '../../../../_metronic/helpers'

type Props = {
  useQueryRequest: () => QueryRequestContextProps
  useQueryResponsePagination: () => PaginationState
  useQueryResponseLoading: () => boolean
  forceLimit?: number
}

const Pagination = ({
  useQueryRequest,
  useQueryResponsePagination,
  useQueryResponseLoading,
  forceLimit,
}: Props) => {
  const intl = useIntl()
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (offset: number | null, limitNumber?: number) => {
    if (!offset || isLoading || pagination.offset === offset) {
      return
    }

    if (forceLimit) {
      updateState({offset, limit: forceLimit})
    } else if (limitNumber) {
      updateState({offset, limit: limitNumber})
    } else {
      updateState({offset, limit: pagination.limit || 10})
    }
  }

  const mappedLabel = (label: string): string => {
    if (label === '&laquo; Previous') {
      return intl.formatMessage({id: 'TABLE.PAGINATION.FIRST'})
    }

    if (label === 'Next &raquo;') {
      return intl.formatMessage({id: 'TABLE.PAGINATION.LAST'})
    }

    return label
  }

  const mappedPage = (label: string, last_page: number): number => {
    if (label === intl.formatMessage({id: 'TABLE.PAGINATION.FIRST'})) return 1
    if (label === intl.formatMessage({id: 'TABLE.PAGINATION.LAST'})) return last_page

    return parseInt(label)
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>

      <div className='col-sm-12 col-md-7 d-flex gap-8 align-items-center justify-content-center justify-content-md-end'>
        <div className='d-flex align-items-center justify-content-center gap-8'>
          <span className='fw-bold'>
            {intl.formatMessage({id: 'TRANSACTION.TABLE.PAGINATION.SHOW'})}
          </span>
          <select
            role='button'
            onChange={(e) => {
              updatePage(1, Number(e.target.value))
            }}
            className={clsx('form-select form-select-solid form-select-sm fw-bold')}
          >
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div id='kt_table_transaction_paginate'>
          <ul className='pagination'>
            {pagination.links
              ?.map((link: PaginationState['links'] | any) => {
                return {...link, label: mappedLabel(link!.label)}
              })
              .map((link: PaginationState['links'] | any) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.page === link.page,
                    disabled: isLoading,
                    previous: link.label === intl.formatMessage({id: 'TABLE.PAGINATION.FIRST'}),
                    next: link.label === intl.formatMessage({id: 'TABLE.PAGINATION.LAST'}),
                  })}
                >
                  <a
                    className={clsx('page-link px-6', {
                      'page-text':
                        link.label === intl.formatMessage({id: 'TABLE.PAGINATION.FIRST'}) ||
                        link.label === intl.formatMessage({id: 'TABLE.PAGINATION.LAST'}),
                      'me-5': link.label === intl.formatMessage({id: 'TABLE.PAGINATION.FIRST'}),
                    })}
                    onClick={() =>
                      updatePage(mappedPage(link.label, pagination.last_page as number))
                    }
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {Pagination}
