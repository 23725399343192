import {motion} from 'framer-motion'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PublicKey} from '../../../../../_metronic/helpers'
import {SearchSelectCustom} from '../../../../components/form/CustomSelect'
import {Loading} from '../../../../components/table/loading/Loading'
import {listEmployee, requestListLocation} from '../core/_requests'

type Props = {
  setSafetyTagScanned: React.Dispatch<React.SetStateAction<string>>
  safetyTagScanned: string
  handleClose: () => void
  currentStepIndex?: Number
}

const ManualInsertSafetyTag: React.FC<Props> = ({
  safetyTagScanned,
  setSafetyTagScanned,
  handleClose,
  currentStepIndex,
}) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)

  const handleInputChange = (event: any, index: any) => {
    const regex = /[^\w\s]/g
    const newInputValues = safetyTagScanned.split('')

    // Se o índice for menor que o comprimento da string, atualize o caractere correspondente no array
    if (index < newInputValues.length) {
      newInputValues[index] = event.target.value
    }
    // Se o índice for maior ou igual ao comprimento da string, adicione o novo caractere no final do array
    else {
      newInputValues.push(event.target.value)
    }

    if (!regex.test(newInputValues[index])) {
      setSafetyTagScanned(newInputValues.join(''))
    }
  }

  const [employeeOptions, setEmployeeOptions]: any = useState([])
  const [employeeSearch, setEmployeeSearch]: any = useState('')
  const [locationOptions, setLocationOptions]: any = useState([])
  const [locationSearch, setLocationSearch]: any = useState('')
  const [isFetching, setIsFetching]: any = useState(false)

  const handleInputKeyUp = (event: any, index: any) => {
    if (event.key === 'Backspace') {
      const previousInput: any = document.querySelector(`input[data-index="${index - 1}"]`)
      if (previousInput) {
        previousInput.focus()
      }
    } else if (
      event.key !== 'Backspace' &&
      event.key !== 'Shift' &&
      event.key !== 'CapsLock' &&
      event.key !== 'Tab' &&
      event.key !== 'ArrowUp' &&
      event.key !== 'ArrowDown'
    ) {
      const regex = /[^\w\s]/g

      if (!regex.test(event.key)) {
        const nextInput: any = document.querySelector(`input[data-index="${index + 1}"]`)
        if (nextInput) {
          nextInput.focus()
        }
      }
    }

    handleInputChange(event, index)
  }

  useEffect(() => {
    getEmployeeOptions('')
    getLocationOptions('')
  }, [])

  async function getEmployeeOptions(search = '') {
    if (search === '') {
      const query = {
        offset: '0',
        limit: '0',
      } as any

      const paramsConverted = Object.keys(query)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(`${query[key]}`)
        })
        .join('&')

      const {data} = await listEmployee(paramsConverted)

      const completeData = [
        ...data.result,
        {
          publicKey: 'aa',
          equipment_name: intl.formatMessage({id: 'REACT_SELECT_SEARCH_PLACEHOLDER'}),
        } as any,
      ]

      setEmployeeOptions(completeData)
      setIsFetching(false)
    } else {
      const query = {
        offset: '0',
        limit: '0',
        search,
      } as any

      const paramsConverted = Object.keys(query)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(`${query[key]}`)
        })
        .join('&')

      const {data} = await listEmployee(paramsConverted)

      setEmployeeOptions(data.result || [])
      setIsFetching(false)
    }
  }

  async function getLocationOptions(search = '') {
    if (search === '') {
      const query = {
        offset: 0,
        limit: 0,
      } as any

      const paramsConverted = Object.keys(query)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(`${query[key]}`)
        })
        .join('&')

      const {data} = await requestListLocation(paramsConverted)

      const completeData = [
        ...data.data,
        {
          publicKey: 'aa',
          name: intl.formatMessage({id: 'REACT_SELECT_SEARCH_PLACEHOLDER'}),
        } as any,
      ]

      setLocationOptions(completeData)
      setIsFetching(false)
    } else {
      const query = {
        offset: 1,
        limit: 10,
        search,
      } as any

      const paramsConverted = Object.keys(query)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(`${query[key]}`)
        })
        .join('&')

      const {data} = await requestListLocation(paramsConverted)

      setLocationOptions(data.data || [])
      setIsFetching(false)
    }
  }

  const handleOnInputChange = (input: string) => {
    setEmployeeSearch(input)
    if (employeeSearch === input) return
  }

  const handleOnInputChangeLocation = (input: string) => {
    setLocationSearch(input)
    if (locationSearch === input) return
  }

  const handleChangeEmployeeInput = async (emplooyee_publicKey: string) => {
    if (!emplooyee_publicKey) return

    try {
      setSafetyTagScanned(emplooyee_publicKey)
    } catch (e: any) {}
  }

  const handleChangeLocationInput = async (location_publicKey: string) => {
    if (!location_publicKey) return

    try {
      setSafetyTagScanned(location_publicKey)
    } catch (e: any) {}
  }

  function isValidUrl(value: string) {
    let url_string
    try {
      url_string = new URL(value)
    } catch (_) {
      return false
    }
    return url_string.protocol === 'http:' || url_string.protocol === 'https:'
  }

  const parsePublicKeyFromURL = (alphaTag_publicKey: string | PublicKey) => {
    if (alphaTag_publicKey && alphaTag_publicKey.startsWith('http')) {
      setIsLoading(true)
    }

    if (
      alphaTag_publicKey &&
      !alphaTag_publicKey.startsWith('http') &&
      alphaTag_publicKey?.length === 8
    ) {
      setSafetyTagScanned(alphaTag_publicKey)
    } else if (
      alphaTag_publicKey &&
      alphaTag_publicKey.startsWith('http') &&
      (alphaTag_publicKey.length === 52 ||
        alphaTag_publicKey.length === 49 ||
        alphaTag_publicKey.length === 48 ||
        alphaTag_publicKey.length === 45)
    ) {
      if (isValidUrl(alphaTag_publicKey as string) && alphaTag_publicKey) {
        var url_decode = new URL(alphaTag_publicKey)

        var pathnameType = url_decode.pathname.split('/')[1]

        if (
          pathnameType === 'safetyTag' ||
          pathnameType === 'employeeDetails' ||
          pathnameType === 'locationDetails'
        ) {
          setSafetyTagScanned(url_decode.pathname.split('/')[2])
        } else {
          setSafetyTagScanned(alphaTag_publicKey)
        }
      }
    } else {
      setSafetyTagScanned(alphaTag_publicKey as string)
    }
  }

  return (
    <motion.div
      initial={{opacity: 0, y: 600}}
      animate={{opacity: 1, y: 0}}
      exit={{opacity: 0, y: 600}}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      className='w-100 d-flex flex-column align-items-center justify-content-center'
      style={{height: '78%', overflow: 'hidden'}}
    >
      <div
        style={{position: 'absolute', top: '0', zIndex: '9999'}}
        className='w-100 d-flex align-items-center justify-content-start px-4 py-2 mt-12'
      >
        <button onClick={handleClose} className='btn btn-secondary svg-icon-2qx'>
          X
        </button>
      </div>

      {isLoading === true && <Loading />}

      <div className='d-flex flex-column align-items-center justify-content-center gap-4'>
        <input
          autoFocus
          type='text'
          value={safetyTagScanned}
          onChange={(e) => parsePublicKeyFromURL(e.target.value)}
          style={{opacity: '0'}}
        />
        <h1>{intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.TYPE_SAFETY_TAG'})}</h1>

        <div className='d-flex'>
          <input
            type='text'
            className='form-control bg-transparent h-70px w-40px fs-2qx text-center mx-1 my-2'
            value={safetyTagScanned[0] || ''}
            style={{padding: '0'}}
            data-index={0}
            onChange={(event) => handleInputChange(event, 0)}
            onKeyUp={(event) => handleInputKeyUp(event, 0)}
          />
          <input
            type='text'
            className='form-control bg-transparent h-70px w-40px fs-2qx text-center mx-1 my-2'
            value={safetyTagScanned[1] || ''}
            style={{padding: '0'}}
            data-index={1}
            onChange={(event) => handleInputChange(event, 1)}
            onKeyUp={(event) => handleInputKeyUp(event, 1)}
          />
          <input
            type='text'
            className='form-control bg-transparent h-70px w-40px fs-2qx text-center mx-1 my-2'
            value={safetyTagScanned[2] || ''}
            style={{padding: '0'}}
            data-index={2}
            onChange={(event) => handleInputChange(event, 2)}
            onKeyUp={(event) => handleInputKeyUp(event, 2)}
          />
          <input
            type='text'
            className='form-control bg-transparent h-70px w-40px fs-2qx text-center mx-1 my-2'
            value={safetyTagScanned[3] || ''}
            style={{padding: '0'}}
            data-index={3}
            onChange={(event) => handleInputChange(event, 3)}
            onKeyUp={(event) => handleInputKeyUp(event, 3)}
          />
          <input
            type='text'
            className='form-control bg-transparent h-70px w-40px fs-2qx text-center mx-1 my-2'
            value={safetyTagScanned[4] || ''}
            style={{padding: '0'}}
            data-index={4}
            onChange={(event) => handleInputChange(event, 4)}
            onKeyUp={(event) => handleInputKeyUp(event, 4)}
          />
          <input
            type='text'
            className='form-control bg-transparent h-70px w-40px fs-2qx text-center mx-1 my-2'
            value={safetyTagScanned[5] || ''}
            style={{padding: '0'}}
            data-index={5}
            onChange={(event) => handleInputChange(event, 5)}
            onKeyUp={(event) => handleInputKeyUp(event, 5)}
          />
          <input
            type='text'
            className='form-control bg-transparent h-70px w-40px fs-2qx text-center mx-1 my-2'
            value={safetyTagScanned[6] || ''}
            style={{padding: '0'}}
            data-index={6}
            onChange={(event) => handleInputChange(event, 6)}
            onKeyUp={(event) => handleInputKeyUp(event, 6)}
          />
          <input
            type='text'
            className='form-control bg-transparent h-70px w-40px fs-2qx text-center mx-1 my-2'
            value={safetyTagScanned[7] || ''}
            style={{padding: '0'}}
            data-index={7}
            onChange={(event) => handleInputChange(event, 7)}
            onKeyUp={(event) => handleInputKeyUp(event, 7)}
          />
        </div>

        {currentStepIndex === 4 && (
          <>
            <div className='w-full d-flex flex-column align-items-center justify-content-center'>
              <h1>{intl.formatMessage({id: 'TRANSACTION.EMPLOYEE.INPUT.SEARCH_FOR_EMPLOYEE'})}</h1>
            </div>
            <SearchSelectCustom
              onChange={(value: string) => handleChangeEmployeeInput(value)}
              value={safetyTagScanned}
              onInputChange={handleOnInputChange}
              isOptionDisabled={(option: any) => option.disabled}
              isLoading={isFetching}
              options={employeeOptions.map(
                ({publicKey, employee_fullName}: any) =>
                  ({
                    value: publicKey,
                    label:
                      employee_fullName ===
                      intl.formatMessage({id: 'REACT_SELECT_SEARCH_PLACEHOLDER'})
                        ? employee_fullName
                        : employee_fullName,
                    disabled:
                      employee_fullName ===
                      intl.formatMessage({id: 'REACT_SELECT_SEARCH_PLACEHOLDER'}),
                  } as any)
              )}
              placeholder={intl.formatMessage({id: 'TRANSACTION.EMPLOYEE.INPUT.NAME_OR_GOV_ID'})}
              className='form-control text-center'
            />
            <div className='w-full d-flex flex-column align-items-center justify-content-center'>
              <h1>
                {intl.formatMessage({
                  id: 'TRAINING.DASHBOARD.EMPLOYEES_WITH_NO_INTEGRATION.HEADER.LOCATION',
                })}
              </h1>
            </div>
            <SearchSelectCustom
              onChange={(value: string) => handleChangeLocationInput(value)}
              value={safetyTagScanned}
              onInputChange={handleOnInputChangeLocation}
              isOptionDisabled={(option: any) => option.disabled}
              isLoading={isFetching}
              options={locationOptions.map(
                ({publicKey, name}: any) =>
                  ({
                    value: publicKey,
                    label:
                      name === intl.formatMessage({id: 'REACT_SELECT_SEARCH_PLACEHOLDER'})
                        ? name
                        : name,
                    disabled: name === intl.formatMessage({id: 'REACT_SELECT_SEARCH_PLACEHOLDER'}),
                  } as any)
              )}
              placeholder={intl.formatMessage({
                id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.PLACEHOLDER.LOCATION',
              })}
              className='form-control text-center'
            />
          </>
        )}
      </div>
    </motion.div>
  )
}

export default ManualInsertSafetyTag
