import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {useReactToPrint} from 'react-to-print'
import {QRCodeSVG} from 'qrcode.react'
import {toast} from 'react-toastify'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../components/table/loading/Loading'
import {getCertificate} from '../core/_requests'

type Props = {
  transactionPublicKey: string
}

const TransactionCertificate: React.FC<Props> = ({transactionPublicKey}) => {
  const intl = useIntl()
  const [transactionData, setTransactionData] = useState<any>(undefined)

  useEffect(() => {
    const requestTransaction = async () => {
      const {data} = await getCertificate(transactionPublicKey)

      if (data.httpStatus == 200) {
        setTransactionData(data.data)
      }
    }

    requestTransaction()
  }, [])

  return (
    <div>
      <div>
        <div
          id='kt_content_container'
          className='d-flex flex-column-fluid align-items-start container-fluid'
        >
          <div className='content flex-row-fluid rounded bg-gray-200' id='kt_content'>
            {transactionData ? (
              <div className='py-8'>
                <div
                  style={{width: '100%'}}
                  className='d-flex align-items-center justify-content-between px-12 my-6 px-4'
                >
                  <div className='rounded' style={{width: '30%'}}></div>
                  <h1 className='text-center'>
                    {transactionData.transactionType === 'delivery'
                      ? intl.formatMessage({
                          id: 'CERTIFICATE.TITLE.DELIVERY',
                        })
                      : intl.formatMessage({
                          id: 'CERTIFICATE.TITLE.INSPECTION',
                        })}
                  </h1>
                  <div
                    style={{width: '30%'}}
                    className='d-flex align-items-center justify-content-end rounded'
                  >
                    {transactionData.transactionAction === 'done' ? (
                      <>
                        <div
                          className='rounded'
                          style={{
                            height: '40px',
                            width: '40px',
                            backgroundImage: `url(${toAbsoluteUrl('/media/modules/approved.png')})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                          }}
                        ></div>
                      </>
                    ) : (
                      <>
                        <div
                          className='rounded d-block'
                          style={{
                            height: '40px',
                            width: '40px',
                            backgroundImage: `url(${toAbsoluteUrl('/media/modules/rejected.png')})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                          }}
                        ></div>
                      </>
                    )}
                  </div>
                </div>

                <div
                  style={{width: '100%'}}
                  className='d-flex flex-column align-items-start justify-content-between px-12 mb-16 gap-8'
                >
                  <div className='w-full d-flex flex-column align-items-start justify-content-center gap-4'>
                    {/* <div
                      style={{
                        width: '250px',
                        height: '400px',
                        backgroundImage: `${process.env.REACT_APP_CDN_SENSITIVE_URL}/${transactionData.transactionImage}`,
                      }}
                      className='rounded'
                    ></div> */}

                    <img
                      src={`${process.env.REACT_APP_CDN_SENSITIVE_URL}${transactionData.equipmentImage}`}
                      onError={({currentTarget}) => {
                        currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
                      }}
                      style={{width: '100%', height: 'auto', objectFit: 'cover'}}
                      className='rounded d-block'
                    />

                    <div className='d-flex flex-column gap-4'>
                      <div>
                        <h1 className='fs-4'>{transactionData.equipmentName}</h1>
                        <p>{transactionData.equipmentCode}</p>
                      </div>

                      <div className='d-flex gap-4'>
                        <div className='d-flex flex-column gap-2 align-items-start justify-content-start'>
                          <div className='d-flex align-items-start justify-content-start gap-2'>
                            <KTSVG
                              path='/media/svg/misc/factory-_1_.svg'
                              className='svg-icon-2x mt-1'
                            />
                            <p className='fw-bold'>
                              {intl.formatMessage({
                                id: 'SAFETY_TAG.DETAILS.LABEL.FABRICATED_IN',
                              })}
                              : <br />{' '}
                              <span>
                                {new Date(transactionData.madeIn).toLocaleString('pt-BR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                              </span>
                            </p>
                          </div>
                          <div className='d-flex align-items-start justify-content-start gap-2'>
                            <KTSVG
                              path='/media/svg/misc/qr-code.svg'
                              className='svg-icon-2x mt-1'
                            />
                            <p className='fw-bold'>
                              SafetyTag: <br /> <span>{transactionData.safetyTag}</span>
                            </p>
                          </div>
                        </div>

                        <div className='d-flex flex-column gap-2 align-items-start justify-content-start'>
                          <div className='d-flex align-items-start justify-content-start gap-2'>
                            <KTSVG
                              path='/media/svg/misc/inspection.svg'
                              className='svg-icon-2x mt-1'
                            />
                            <p className='fw-bold'>
                              CA: <br />
                              <span>
                                {transactionData.equipmentApprovalCertificate
                                  ? transactionData.equipmentApprovalCertificate
                                  : '-'}
                              </span>
                            </p>
                          </div>
                          <div className='d-flex align-items-start justify-content-center gap-2'>
                            <KTSVG
                              path='/media/svg/misc/inspection.svg'
                              className='svg-icon-2x mt-1'
                            />
                            <p className='fw-bold'>
                              {intl.formatMessage({
                                id: 'INSPECTION_DELIVERY.RESUME.LABEL.CA_VALIDITY',
                              })}
                              : <br /> <span>{transactionData.equipmentCAValidity}</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-column'>
                        {transactionData.inspectionSteps?.slice(0, 8).map((item: any) => (
                          <div>
                            <h3 className={`fw-bold fs-6`}>
                              <span
                                className={`${
                                  item.transactionStepResult === 'pass'
                                    ? 'text-success'
                                    : 'text-danger'
                                }`}
                              >
                                {item.transactionStepResult === 'pass' ? '✔' : 'X'}
                              </span>{' '}
                              {item.inspectionStepDescription}
                            </h3>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{height: '100%'}}
                    className='d-flex flex-column align-items-center justify-content-between'
                  >
                    <div
                      className='w-full d-flex align-items-start justify-content-between gap-4'
                      style={{width: '100%'}}
                    >
                      <img
                        style={{width: '150px', height: '150px'}}
                        src={`${process.env.REACT_APP_CDN_SENSITIVE_URL}${transactionData?.inspectorImage}`}
                        onError={({currentTarget}) => {
                          currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
                        }}
                        className='rounded d-none'
                      />

                      <img
                        style={{width: '60px', height: '60px'}}
                        src={`${process.env.REACT_APP_CDN_SENSITIVE_URL}${transactionData?.inspectorImage}`}
                        onError={({currentTarget}) => {
                          currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
                        }}
                        className='rounded d-block'
                      />

                      <div className='d-flex flex-column '>
                        <div>
                          <h1 className='fs-4'>
                            {intl.formatMessage({
                              id: 'INSPECTION_DELIVERY.RESUME.LABEL.INSPECTED_BY',
                            })}
                            : {transactionData.inspectedBy}
                          </h1>
                          <p>DOC. GOV. NUM. {transactionData.inspectorDocument}</p>
                        </div>

                        <div className='d-flex align-items-start justify-content-start gap-2'>
                          <img
                            style={{height: '30px', width: '30px'}}
                            src={toAbsoluteUrl('/media/modules/validade-certificado-icon.png')}
                          />
                          <KTSVG
                            path='/media/modules/validade-certificado-icon.png'
                            className='svg-icon-2x mt-1'
                          />
                          <p className='fw-bold'>
                            {intl.formatMessage({
                              id: 'INSPECTION_DELIVERY.RESUME.LABEL.CERTIFICATE_VALIDITY',
                            })}
                            : <br /> {transactionData.inspectorCertificateValidity}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className='w-full d-flex align-items-start justify-content-between gap-4 mt-6'
                      style={{width: '100%'}}
                    >
                      <div className='d-flex flex-column '>
                        <div>
                          <h1 className='fs-4'>
                            {intl.formatMessage({
                              id: 'TRANSACTION.TABLE.FILTER.LABEL.DELIVERED_TO',
                            })}
                            : {transactionData.transactionRecipientName}
                          </h1>
                          {transactionData.transactionRecipientType === 'employee' && (
                            <p>
                              DOC. GOV. NUM.{' '}
                              {transactionData.transactionRecipientGovernmentIdNumber}
                            </p>
                          )}
                        </div>

                        {transactionData.transactionRecipientType === 'employee' && (
                          <>
                            <div className='d-flex align-items-center justify-content-start gap-2 '>
                              <img
                                style={{height: '30px', width: '30px'}}
                                src={toAbsoluteUrl('/media/modules/validade-aso-icon.png')}
                              />
                              <p className='fw-bold'>
                                {intl.formatMessage({
                                  id: 'INSPECTION_DELIVERY.RESUME.LABEL.ASO_VALIDITY',
                                })}
                                : <br /> {transactionData.asoValidity}
                              </p>
                            </div>

                            <div className='d-flex align-items-center justify-content-start gap-2'>
                              <img
                                style={{height: '30px', width: '30px'}}
                                src={toAbsoluteUrl('/media/modules/validade-autorizacao-icon.png')}
                              />
                              <p className='fw-bold'>
                                {intl.formatMessage({
                                  id: 'INSPECTION_DELIVERY.RESUME.LABEL.AUTHORIZATION_VALIDITY',
                                })}
                                : <br /> {transactionData.authorizationValidity}
                              </p>
                            </div>
                          </>
                        )}
                      </div>

                      {transactionData.transactionRecipientType === 'employee' ? (
                        <>
                          <img
                            style={{width: '60px', height: '60px'}}
                            src={`${process.env.REACT_APP_CDN_SENSITIVE_URL}${transactionData?.transactionRecipientImage}`}
                            onError={({currentTarget}) => {
                              currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
                            }}
                            className='rounded d-block'
                          />
                        </>
                      ) : (
                        <>
                          <img
                            style={{width: '60px', height: '60px'}}
                            src={`${process.env.REACT_APP_CDN_SENSITIVE_URL}${transactionData?.employeeImage}`}
                            onError={({currentTarget}) => {
                              currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
                            }}
                            className='rounded d-block'
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-center justify-content-center gap-4'>
                  <div className='d-flex align-items-center justify-content-center gap-2'>
                    {transactionData.transactionRecipientType === 'employee' && (
                      <>
                        {transactionData.transactionValidationMethod === 'faceValidation' && (
                          <div className='d-flex flex-column align-items-center justify-content-center'>
                            <p className='text-center fw-bold'>
                              {intl.formatMessage({
                                id: 'SETTINGS.COMPANY.FACE_VALIDATION.TITLE',
                              })}
                            </p>
                            <img
                              src={`${process.env.REACT_APP_CDN_SENSITIVE_URL}${transactionData.faceValidationImage}`}
                              style={{height: '60px', width: '60px'}}
                            />
                          </div>
                        )}

                        {transactionData.transactionValidationMethod === 'challengeValidation' && (
                          <div className='d-flex flex-column align-items-center'>
                            <p className='text-center fw-bold'>
                              {intl.formatMessage({
                                id: 'SETTINGS.COMPANY.CHALLENGE_VALIDATION.TITLE',
                              })}
                            </p>
                            <img
                              src={`${toAbsoluteUrl('/media/modules/challengeValidation.png')}`}
                              style={{height: '60px', width: '60px'}}
                            />
                          </div>
                        )}
                      </>
                    )}

                    <div className='d-flex flex-column align-items-center justify-content-center'>
                      <p className='text-center fw-bold'>
                        {intl.formatMessage({
                          id: 'ASSET.TRANSACTION.CERTIFICATE.TRANSACTION_PHOTO',
                        })}
                      </p>
                      <img
                        src={`${process.env.REACT_APP_CDN_SENSITIVE_URL}${transactionData.transactionImage}`}
                        style={{height: '60px', width: '60px'}}
                      />
                    </div>
                  </div>

                  {transactionData.transactionRecipientType === 'employee' && (
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                      <img
                        src={`${process.env.REACT_APP_CDN_SENSITIVE_URL}${transactionData.signOnGlassImage}`}
                        style={{height: '60px', width: '100%'}}
                      />
                      <p className='fw-bold mt-2'>{transactionData.transactionRecipientName}</p>
                    </div>
                  )}

                  <div style={{width: '80px', height: '80px'}} className='rounded'>
                    <QRCodeSVG
                      value={`${window.location.origin}/certificate/${transactionData.publicKey}`}
                      size={80}
                      level={'L'}
                      includeMargin={false}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionCertificate
