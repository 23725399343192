import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  workAuthorization: any
}

const WorkAuthorizationActivityDescriptionCell: React.FC<Props> = ({workAuthorization}) => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-column'>
      <a className='text-gray-800 hover-primary'>{workAuthorization?.activity_description}</a>
      <span>
        {workAuthorization?.activity_environment === 'internal' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.INTERNAL'})}
        {workAuthorization?.activity_environment === 'external' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.EXTERNAL'})}
        {workAuthorization?.activity_environment === 'confined' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.CONFINED'})}
        -
        {workAuthorization?.activity_frequency === 'daily' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.DAILY'})}
        {workAuthorization?.activity_frequency === 'weekly' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.WEEKLY'})}
        {workAuthorization?.activity_frequency === 'monthly' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.MONTHLY'})}
        {workAuthorization?.activity_frequency === 'quarterly' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.QUARTERLY'})}
        {workAuthorization?.activity_frequency === 'semi-anually' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.SEMI_ANNUALLY'})}
        {workAuthorization?.activity_frequency === 'anually' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.ANNUALLY'})}
        {workAuthorization?.activity_frequency === 'once' &&
          intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.ONCE'})}
      </span>
    </div>
  )
}

export default WorkAuthorizationActivityDescriptionCell
