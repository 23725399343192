import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  workAuthorization: any
}

const WorkAuthorizationAccessControlCell: React.FC<Props> = ({workAuthorization}) => {
  const intl = useIntl()

  return (
    <div className='text-center'>
      <span
        className={`badge ${
          workAuthorization.activity_accessControlRequired === '0'
            ? 'badge-success'
            : 'badge-danger'
        }`}
      >
        {workAuthorization.activity_accessControlRequired === '0'
          ? intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.NOT_REQUIRED'})
          : intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.REQUIRED'})}
      </span>
    </div>
  )
}

export default WorkAuthorizationAccessControlCell
