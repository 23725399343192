/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, {ApexOptions} from 'apexcharts'
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../../modules/auth'
import EquipmentTransactionIndex from '../../equipmentTransaction/EquipmentTransactionIndex'

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
  setRefetch: any
}

const getBreakpoints = (): any => {
  const mediaQueries: Record<string, MediaQueryList> = {
    xs: window.matchMedia('(min-width: 376px)'),
    sm: window.matchMedia('(min-width: 576px)'),
    md: window.matchMedia('(min-width: 768px)'),
    lg: window.matchMedia('(min-width: 992px)'),
    xl: window.matchMedia('(min-width: 1200px)'),
  }

  const keys = Object.keys(mediaQueries) as Array<keyof typeof mediaQueries>
  const values = keys.map((key) => mediaQueries[key].matches)

  return Object.fromEntries(keys.map((_, i) => [keys[i], values[i]])) as any
}

const InspectionDeliveryWidget: React.FC<Props> = ({chartColor, chartHeight, strokeColor}) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const [breakpoints, setBreakpoints] = useState<any>(getBreakpoints())

  useEffect(() => {
    const handleResize = () => setBreakpoints(getBreakpoints())
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const intl = useIntl()
  const {currentFeaturesObject} = useAuth()

  const handleOpenModal = () => {
    setShowModal(true)
    document.body.style.overflow = 'hidden'
  }

  const handleCloseModal = () => {
    setShowModal(false)
    document.body.style.overflow = 'auto'
  }

  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  const [hoverDelivery, setHoverDelivery]: any = useState(false)
  const [hoverEquipmentList, setHoverEquipmentList]: any = useState(false)
  const [hoverManufectures, setHoverManufectures]: any = useState(false)
  const [hoverTransactions, setHoverTransactions]: any = useState(false)

  return (
    <>
      {currentFeaturesObject &&
      currentFeaturesObject.equipmentTransaction?.create === '1' &&
      showModal ? (
        <div className='d-flex w-100 align-items-center justify-content-center'>
          <EquipmentTransactionIndex
            show={showModal}
            handleClose={handleCloseModal}
            breakpoints={breakpoints}
          />
        </div>
      ) : (
        ''
      )}
      <div className='row g-5 g-xl-8'>
        {currentFeaturesObject && currentFeaturesObject.equipmentTransaction?.create === '1' && (
          <div
            className='col-xl-3'
            onClick={handleOpenModal}
            onMouseEnter={() => {
              setHoverDelivery(true)
            }}
            onMouseLeave={() => {
              setHoverDelivery(false)
            }}
          >
            <a
              href='#'
              className={`card ${
                hoverDelivery === false ? 'bg-dark text-gray-100' : 'bg-primary text-white'
              } card-xl-stretch mb-xl-8`}
            >
              <div className='card-body'>
                <span className='svg-icon svg-icon-body svg-icon-2hx ms-n1 flex-grow-1'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen035.svg'
                    className={`${hoverDelivery === true && 'text-white'}`}
                  />
                </span>

                <div className='fw-bold fs-2 mb-2 mt-5'>
                  {intl.formatMessage({id: 'ASSET.DASHBOARD.HEADER_BUTTONS.DELIVERY'})}
                </div>
                <div className='fw-semibold'>
                  {intl.formatMessage({id: 'ASSET.DASHBOARD.HEADER_BUTTONS.DELIVERY_DESCRIPTION'})}
                </div>
              </div>
            </a>
          </div>
        )}

        {currentFeaturesObject && currentFeaturesObject.equipment && (
          <div
            className='col-xl-3'
            onMouseEnter={() => {
              setHoverEquipmentList(true)
            }}
            onMouseLeave={() => {
              setHoverEquipmentList(false)
            }}
          >
            <Link
              to='/asset/equipment/list'
              className={`card ${
                hoverEquipmentList === false ? 'bg-dark text-gray-100' : 'bg-primary text-white'
              } card-xl-stretch mb-xl-8`}
            >
              <div className='card-body'>
                <span className='svg-icon svg-icon-body svg-icon-3x ms-n1'>
                  <KTSVG
                    path='/media/icons/duotune/ecommerce/ecm009.svg'
                    className={`${hoverEquipmentList === true && 'text-white'}`}
                  />
                </span>

                <div className='fw-bold fs-2 mb-2 mt-5'>
                  {intl.formatMessage({id: 'ASSET.DASHBOARD.HEADER_BUTTONS.EQUIPMENT_LIST'})}
                </div>
                <div className='fw-semibold'>
                  {intl.formatMessage({
                    id: 'ASSET.DASHBOARD.HEADER_BUTTONS.EQUIPMENT_LIST_DESCRIPTION',
                  })}
                </div>
              </div>
            </Link>
          </div>
        )}

        {currentFeaturesObject && currentFeaturesObject.manufacturer && (
          <div
            className='col-xl-3'
            onMouseEnter={() => {
              setHoverManufectures(true)
            }}
            onMouseLeave={() => {
              setHoverManufectures(false)
            }}
          >
            <Link
              to='/asset/manufacturer/list'
              className={`card ${
                hoverManufectures === false ? 'bg-dark text-gray-100' : 'bg-primary text-white'
              } card-xl-stretch mb-xl-8`}
            >
              <div className='card-body'>
                <span className='svg-icon svg-icon-body svg-icon-3x ms-n1'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen023.svg'
                    className={`${hoverManufectures === true && 'text-white'}`}
                  />
                </span>

                <div className='fw-bold fs-2 mb-2 mt-5'>
                  {intl.formatMessage({id: 'ASSET.DASHBOARD.HEADER_BUTTONS.MANUFACTURES'})}
                </div>
                <div className='fw-semibold'>
                  {intl.formatMessage({
                    id: 'ASSET.DASHBOARD.HEADER_BUTTONS.MANUFACTURES_DESCRIPTION',
                  })}
                </div>
              </div>
            </Link>
          </div>
        )}

        {currentFeaturesObject && currentFeaturesObject.equipmentTransaction && (
          <div
            className='col-xl-3'
            onMouseEnter={() => {
              setHoverTransactions(true)
            }}
            onMouseLeave={() => {
              setHoverTransactions(false)
            }}
          >
            <Link
              to='/asset/transaction/list'
              className={`card ${
                hoverTransactions === false ? 'bg-dark text-gray-100' : 'bg-primary text-white'
              } card-xl-stretch mb-xl-8`}
            >
              <div className='card-body'>
                <span className='svg-icon svg-icon-body svg-icon-3x ms-n1'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen004.svg'
                    className={`${hoverTransactions === true && 'text-white'}`}
                  />
                </span>

                <div className='fw-bold fs-2 mb-2 mt-5'>
                  {intl.formatMessage({id: 'ASSET.DASHBOARD.HEADER_BUTTONS.TRANSACTIONS'})}
                </div>
                <div className='fw-semibold'>
                  {intl.formatMessage({
                    id: 'ASSET.DASHBOARD.HEADER_BUTTONS.TRANSACTIONS_DESCRIPTION',
                  })}
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    </>
  )
}

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const color = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  }
}

export {InspectionDeliveryWidget}
