import {useListView} from '../../core/ListViewProvider'
import {PendingWorkAuthorizationListSearchComponent} from './PendingWorkAuthorizationListSearchComponent'
import {PendingWorkAuthorizationListToolbar} from './PendingWorkAuthorizationListToolbar'

const PendingWorkAuthorizationListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <PendingWorkAuthorizationListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? '' : <PendingWorkAuthorizationListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PendingWorkAuthorizationListHeader}
