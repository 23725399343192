import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PendingWorkAuthorization} from '../../core/_models'

type Props = {
  pending_work_authorization: PendingWorkAuthorization
}

const PendingWorkAuthorizationActivityCell: FC<Props> = ({pending_work_authorization}) => {
  const intl = useIntl()

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1'>
          {pending_work_authorization.activity_description &&
          pending_work_authorization.activity_description.length > 20
            ? pending_work_authorization.activity_description.substring(0, 20) + '...'
            : pending_work_authorization.activity_description}
        </span>
        <span>
          {pending_work_authorization?.activity_environment === 'internal' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.INTERNAL'})}
          {pending_work_authorization?.activity_environment === 'external' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.EXTERNAL'})}
          {pending_work_authorization?.activity_environment === 'confined' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.CONFINED'})}{' '}
          -{' '}
          {pending_work_authorization?.activity_frequency === 'daily' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.DAILY'})}
          {pending_work_authorization?.activity_frequency === 'weekly' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.WEEKLY'})}
          {pending_work_authorization?.activity_frequency === 'monthly' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.MONTHLY'})}
          {pending_work_authorization?.activity_frequency === 'quarterly' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.QUARTERLY'})}
          {pending_work_authorization?.activity_frequency === 'semi-anually' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.SEMI_ANNUALLY'})}
          {pending_work_authorization?.activity_frequency === 'anually' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.ANNUALLY'})}
          {pending_work_authorization?.activity_frequency === 'once' &&
            intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.ONCE'})}
        </span>
      </div>
    </div>
  )
}

export {PendingWorkAuthorizationActivityCell}
