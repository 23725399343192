import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../_metronic/helpers'
import {PendingWorkAuthorization, PendingWorkAuthorizationQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const PENDING_WORK_AUTHORIZATION = `${API_ALPHA}/customer/access/workAuthorizationPendingApproval`
const WORK_AUTHORIZATION = `${API_ALPHA}/customer/access/workAuthorization`
const APPROVE_WORK_AUTHORIZATION = `${API_ALPHA}/customer/access/workAuthorizationWorkflow`

export const getPendingWorkAuthorizations = (
  query: string
): Promise<PendingWorkAuthorizationQueryResponse> => {
  return axios
    .get(`${PENDING_WORK_AUTHORIZATION}?${query}`)
    .then((d: AxiosResponse<PendingWorkAuthorizationQueryResponse>) => d.data)
}

export const getPendingWorkAuthorizationsWithSearch = (
  query: string,
  search: string
): Promise<PendingWorkAuthorizationQueryResponse> => {
  return axios
    .get(`${PENDING_WORK_AUTHORIZATION}?${query}&filter_employee_publickey=${search}`)
    .then((d: AxiosResponse<PendingWorkAuthorizationQueryResponse>) => d.data)
}

export const getPendingWorkAuthorizationById = (
  publicKey: PublicKey
): Promise<PendingWorkAuthorization | undefined> => {
  return axios
    .get(`${PENDING_WORK_AUTHORIZATION}/${publicKey}`)
    .then((response: AxiosResponse<Response<PendingWorkAuthorization>>) => response.data)
    .then((response: Response<PendingWorkAuthorization>) => response.result)
}

export const getWorkAuthorizationById = (
  publicKey: PublicKey
): Promise<PendingWorkAuthorization | undefined> => {
  return axios
    .get(`${WORK_AUTHORIZATION}/${publicKey}`)
    .then((response: AxiosResponse<Response<PendingWorkAuthorization>>) => response.data)
    .then((response: Response<PendingWorkAuthorization>) => response.result)
}

export const approvePendingWorkAuthorization = (
  workAuthorization: PendingWorkAuthorization,
  workAuthorizationWorkflow_publicKey: string
) => {
  return axios
    .put(`${APPROVE_WORK_AUTHORIZATION}/${workAuthorizationWorkflow_publicKey}`, workAuthorization)
    .then((response: AxiosResponse<Response<PendingWorkAuthorization>>) => response.data)
    .then(
      (response: Response<PendingWorkAuthorization>) =>
        response as Response<PendingWorkAuthorization>['data']
    )
}
