import {FC, useMemo} from 'react'
import {PublicKey} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'

type Props = {
  publicKey: PublicKey
}

const WorkAuthorizationSelectionCell: FC<Props> = ({publicKey}) => {
  const {selected, onSelect} = useListView()
  const isSelected = useMemo(() => selected.includes(publicKey), [publicKey, selected])
  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_manufacturers .form-check-input'
        checked={isSelected}
        onChange={() => onSelect(publicKey)}
      />
    </div>
  )
}

export {WorkAuthorizationSelectionCell}
