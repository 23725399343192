/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {listCountry} from '../core/_requests'
import FirstStep from './RegisterAccountSteps/FirstStep'
import SecondStep from './RegisterAccountSteps/SecondStep'
import ThirdStep from './RegisterAccountSteps/ThirdStep'

//Steps list [Create account]
// 1 = Business Name, government ID, country
// 2 = First Name, last name, government ID, phone
// 3 = email, password, confirm password, accept terms
// 4 = Account created

export interface CreateAccount {
  businessName: string
  governmentId: string
  countryPublicKey: string
  firstName: string
  lastName: string
  userGovernmentId: string
  phone: string
  email: string
  password: string
  confirmPassword: string
}

const RegisterAccount: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [countryOptions, setCountryOptions] = useState([])

  const [createAccountValues, setCreateAccountValues] = useState<CreateAccount>({
    businessName: '',
    governmentId: '',
    countryPublicKey: '',
    firstName: '',
    lastName: '',
    userGovernmentId: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
  })

  const changeInitialValues = (valueToChange: keyof CreateAccount, data: any) => {
    setCreateAccountValues((value) => ({
      ...value,
      [valueToChange]: data,
    }))
  }

  async function getCountryOptions() {
    const {data} = await listCountry()
    setCountryOptions(data?.result)
  }

  useEffect(() => {
    getCountryOptions()
  }, [])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
      >
        {currentStep === 1 && (
          <FirstStep
            countryOptions={countryOptions}
            changeValue={changeInitialValues}
            createAccountValues={createAccountValues}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        )}
        {currentStep === 2 && (
          <SecondStep
            changeValue={changeInitialValues}
            createAccountValues={createAccountValues}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        )}
        {currentStep === 3 && (
          <ThirdStep
            changeValue={changeInitialValues}
            createAccountValues={createAccountValues}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        )}
      </form>
    </>
  )
}

export default RegisterAccount
