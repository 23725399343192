import axios from 'axios'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const EMPLOYEE_LIST = `${API_ALPHA}/customer/settings/employee`

const getEmployeeList = (search: string) => {
  return axios.get(`${EMPLOYEE_LIST}?offset=1&limit=10&search=${search}`)
}

const getActivityList = (search: string) => {
  return axios.get(`${API_ALPHA}/customer/risk/activity?offset=1&limit=10&search=${search}`)
}

const getLocationList = (locationType: any) => {
  return axios.get(`${API_ALPHA}/customer/settings/${locationType}`)
}

const makeNewWorkAuthorization = (
  employeePublicKey: any,
  activityPublicKey: any,
  locationType: any,
  tablePublicKey: any,
  dueDate: any
) => {
  return axios.post(`${API_ALPHA}/customer/access/workAuthorization`, {
    employee_publicKey: employeePublicKey,
    activity_publicKey: activityPublicKey,
    table: locationType,
    table_publicKey: tablePublicKey,
    dueDate: dueDate,
  })
}

export {getEmployeeList, getActivityList, getLocationList, makeNewWorkAuthorization}
