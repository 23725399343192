import clsx from 'clsx'
import {Component, KeyboardEventHandler, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Select, {ActionMeta, MultiValue, OnChangeValue} from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import {
  reactSelectStyles,
  theme,
} from '../../pages/risk/hazard-management/control-measure-list/controlMeasure-edit-modal/ControlMeasureEquipmentEditModalForm'

interface ISelectOptions {
  value: string | null
  label: string | null
  disabled?: boolean
}

interface ICreatableSelectCustom {
  isLoading?: boolean
  isDisabled?: boolean
  options: ISelectOptions[]
  isMulti?: boolean
  placeholder?: string
  onInputChange?: (value: string) => void
  isOptionDisabled?: (...args: any) => boolean

  onChange: (value: string) => void
  onCreateOption: (value: string) => void
  value?: ISelectOptions[] | null | undefined
}

export function CreatableSelectCustom({
  onChange,
  onCreateOption,
  onInputChange,
  isOptionDisabled,
  options,
  value,
  isLoading,
  isDisabled,
  placeholder,
}: ICreatableSelectCustom) {
  const defaultValue = (options: any, value: any) => {
    if (!options) return null
    return options.find((option: ISelectOptions) => option?.value === value) || null
  }

  return (
    <>
      <CreatableSelect
        isClearable
        onInputChange={onInputChange}
        isOptionDisabled={isOptionDisabled}
        styles={reactSelectStyles}
        theme={theme}
        className={clsx('form-control form-control-lg')}
        createOptionPosition='first'
        isLoading={isLoading}
        isDisabled={isDisabled}
        maxMenuHeight={300}
        value={defaultValue(options, value)}
        onChange={(value) => {
          onChange(value?.value)
        }}
        onCreateOption={(value) => {
          onCreateOption(value)
        }}
        options={options}
        placeholder={placeholder}
      />
    </>
  )
}

interface ISearchSelectCustom {
  isLoading?: boolean
  isDisabled?: boolean
  options: ISelectOptions[]
  isMulti?: boolean
  className?: string
  placeholder?: string
  isOptionDisabled?: (option: any) => any
  onInputChange?: (value: string) => void

  onChange: (value: string) => void
  value?: ISelectOptions | null | undefined | string
}

export function SearchSelectCustom({
  onChange,
  options,
  value,
  isLoading,
  isDisabled,
  className,
  placeholder,
  isOptionDisabled,
  onInputChange,
}: ISearchSelectCustom) {
  const defaultValue = (options: any, value: any) => {
    return options ? options.find((option: ISelectOptions) => option.value === value) : ''
  }

  return (
    <>
      <Select
        styles={reactSelectStyles}
        theme={theme}
        className={className}
        isLoading={isLoading}
        isDisabled={isDisabled}
        maxMenuHeight={300}
        value={defaultValue(options, value)}
        onChange={(value: any) => {
          onChange(value?.value)
        }}
        isOptionDisabled={isOptionDisabled}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder}
      />
    </>
  )
}

const components = {
  DropdownIndicator: null,
}

interface Option {
  readonly label: string
  readonly value: string
}

const createOption = (label: string) => ({
  label,
  value: label,
})

interface State {
  readonly inputValue: string
  readonly value: readonly Option[]
  tagData: (value: string) => void
}

export class CreatableInputOnly extends Component<{tagData: any}, State> {
  state: State = {
    inputValue: '',
    value: [],
    tagData: () => 'dasdadad',
  }

  handleChange = (value: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => {
    this.setState({value})
  }
  handleInputChange = (inputValue: string) => {
    this.setState({inputValue})
  }

  handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const {inputValue, value} = this.state

    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        console.group('Value Added')

        console.groupEnd()
        this.setState({
          inputValue: '',
          value: [...value, createOption(inputValue)],
        })
        event.preventDefault()
    }
  }
  render() {
    const {inputValue, value} = this.state
    return (
      <CreatableSelect
        styles={{
          container: (base, state) => ({
            ...base,
            margin: 0,
            border: state.isFocused ? '1px solid var(--bs-input-focus-border-color)' : '',
            minHeight: 'calc(1.5em + 1.65rem + 2px)',
            padding: 0,
            fontSize: '1.15rem',
            borderRadius: '0.625rem',
            height: 'calc(1.5em + 1.65rem + 2px)',
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '0 1.5rem',
          }),
          singleValue: (base) => ({
            ...base,
            color: 'var(--bs-input-color)',
          }),
          menu: (base) => ({
            ...base,
            color: 'var(--bs-input-color)',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? 'var(--bs-primary)' : base.backgroundColor,
          }),
          control: (base) => ({
            ...base,
            borderStyle: 'none',
            background: '#00000000',
            cursor: 'text',
            height: '100%',
          }),
          placeholder: (base) => ({
            ...base,
            color: 'var(--bs-input-color)',
          }),
          clearIndicator: (base) => ({
            ...base,
            cursor: 'pointer',
          }),
          dropdownIndicator: (base) => ({
            ...base,
            cursor: 'pointer',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#00000000',
            primary25: 'var(--bs-menu-link-bg-color-active)',
          },
        })}
        className={clsx('form-control form-control-lg')}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder='Type something and press enter...'
        value={value}
      />
    )
  }
}

interface ITagInputCustom {
  isLoading?: boolean
  isDisabled?: boolean
  options?: ISelectOptions[]
  isMulti?: boolean
  className?: string
  setValue?: MultiValue<Option> | null | undefined | any
  setInputValue?: any
  inputValue?: string
  isSubmitting?: boolean

  onChange?: (value: string) => void
  // value?: ISelectOptions | null | undefined
  value?: any
}

export function TagInputCustom({
  value,
  className,
  setValue,
  setInputValue,
  inputValue,
  isSubmitting,
}: ITagInputCustom) {
  const intl = useIntl()

  const [hasChanged, setHasChanged] = useState<Boolean>(false)

  useEffect(() => {
    if (isSubmitting) {
      setHasChanged(true)
    }
  }, [isSubmitting])

  const components = {
    DropdownIndicator: null,
  }

  interface Option {
    readonly label: string
    readonly value: string
  }

  const createOption = (label: string) => ({
    label,
    value: label,
  })

  const handleChange = (value: OnChangeValue<Option, true>, {action}: ActionMeta<Option>) => {
    switch (action) {
      case 'clear':
        setHasChanged(true)
        setValue([])
        break
      default:
        setHasChanged(true)
        setValue(value)
        break
    }
  }

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue)
  }

  const handleChangeCurrentValue = () => {
    if (!inputValue) return

    if (value!.map((v: any) => v.label.trim()).includes(inputValue.trim())) {
      setInputValue('')
    } else {
      // console.group('Value Added');
      // console.groupEnd();
      if (inputValue.trim().indexOf(',') > -1) {
        setInputValue('')

        const values = inputValue
          .trim()
          .split(',')
          .filter((iv) => !value.map((v: any) => v.label.trim()).includes(iv.trim()))

        for (let i = 0; i < values.length; i++) {
          if (values[i].trim().length !== 0)
            setValue((oldValue: any) => [...oldValue, createOption(values[i].trim())])
        }
      } else {
        setInputValue('')
        setValue([...value, createOption(inputValue.trim())])
      }
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        handleChangeCurrentValue()
        setHasChanged(true)
        event.preventDefault()
    }
  }

  return (
    <>
      <CreatableSelect
        className={clsx(className, {'is-valid': hasChanged || isSubmitting})}
        styles={{...reactSelectStyles}}
        theme={theme}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        onBlur={handleChangeCurrentValue}
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={value}
        placeholder={intl.formatMessage({id: 'REACT-SELECT.PLACEHOLDER.TAGS'})}
      />
    </>
  )
}

interface ICustomAsyncSelect {
  promiseOptions: (inputValue: string) => Promise<
    {
      value: string
      label: string
    }[]
  >
  placeholder: string
  value?: {
    label?: string
    value?: string
  }
  onChange: (value: unknown) => void
}

export function CustomAsyncSelect({
  promiseOptions,
  placeholder,
  value,
  onChange,
}: ICustomAsyncSelect) {
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      styles={reactSelectStyles}
      theme={theme}
      placeholder={placeholder}
      value={value}
      onChange={(value) => {
        onChange(value)
      }}
    />
  )
}
