import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../../modules/auth'
import {getPendingIssues, getWorkAuthorizations} from '../core/_requests'
import DashboardLatestAuthorizations from './DashboardLatestAuthorizations'
import DashboardLatestIssuesList from './DashboardLatestIssuesList'

const DashboardListsWrapper: React.FC = () => {
  const {currentFeaturesObject} = useAuth()
  const [pendingIssues, setPendingIssues] = useState()
  const [lastWorkAuthorizations, setLastWorkAuthorization] = useState()

  const requestWorkAuthorization = async () => {
    const {data} = await getWorkAuthorizations()

    setLastWorkAuthorization(data.result)
  }

  const requestPendingIssues = async () => {
    const {data} = await getPendingIssues()

    setPendingIssues(data.result)
  }

  useEffect(() => {
    requestWorkAuthorization()
    requestPendingIssues()
  }, [])

  return (
    <div className='row g-5 g-xl-8 mt-4'>
      {currentFeaturesObject && currentFeaturesObject.workAuthorizationIssue && (
        <DashboardLatestIssuesList pendingIssues={pendingIssues} />
      )}
      {currentFeaturesObject && currentFeaturesObject.workAuthorization && (
        <DashboardLatestAuthorizations lastWorkAuthorizations={lastWorkAuthorizations} />
      )}
    </div>
  )
}

export default DashboardListsWrapper
