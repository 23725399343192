import axios from 'axios'
import {PublicKey} from '../../../../../_metronic/helpers'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const ALPHA_TAG_URL = `${API_ALPHA}/v2/customer/asset/findSafetyTag`
const EMPLOYEE_OR_LOCATION_URL = `${API_ALPHA}/v2/customer/asset/findEmployeeOrLocation`
const TRANSACTION = `${API_ALPHA}/v2/customer/asset/resource/equipmentTransaction`
const FACE_VALIDATION = `${API_ALPHA}/v2/customer/asset/faceValidationTransaction`
const SUBMIT_STEP = `${API_ALPHA}/v2/customer/asset/resource/equipmentTransactionStep`
const EMPLOYEE_CHALLENGE = `${API_ALPHA}/v2/customer/asset/responseChallengeTransaction`
const SIGN_ON_GLASS = `${API_ALPHA}/v2/customer/asset/signOnGlassTransaction`
const TRANSACTION_PHOTO = `${API_ALPHA}/v2/customer/asset/transactionResult`
const REQUEST_EQUIPMENT = `${API_ALPHA}/v2/customer/asset/certificate`
const COMPLETE_TRANSACTION = `${API_ALPHA}/v2/customer/asset/completeTransaction`
const LIST_LOCATION = `${API_ALPHA}/v2/customer/settings/listLocationsTransaction`
export const REQUEST_EMPLOYEE = `${API_ALPHA}/customer/settings/employee`

export const requestSafetyTagDetails = (safetyTag: string) => {
  return axios
    .get(`${ALPHA_TAG_URL}/${safetyTag}`)
    .then((d) => d)
    .catch((error) => error)
}

export const requestEmployeeOrLocationDetails = (publicKey: string, safetyTag: string) => {
  return axios
    .get(`${EMPLOYEE_OR_LOCATION_URL}/${publicKey}/${safetyTag}`)
    .then((d) => d)
    .catch((error) => error)
}

export const requestNewTransaction = (data: any) => {
  return axios.post(`${TRANSACTION}`, data)
}

export const requestFaceValidation = (data: any) => {
  return axios
    .post(`${FACE_VALIDATION}`, data)
    .then((d) => d.data)
    .catch((error) => error.response.data)
}

export const requestSubmitStep = (publicKey: PublicKey, data: any) => {
  return axios
    .put(`${SUBMIT_STEP}/${publicKey}`, data)
    .then((d) => d.data)
    .catch((error) => error.response.data)
}

export const requestSubmitEmployeeChallenge = (publicKey: PublicKey, data: any) => {
  return axios
    .put(`${EMPLOYEE_CHALLENGE}/${publicKey}`, data)
    .then((d) => d.data)
    .catch((error) => error.response.data)
}

export const requestSignOnGlassTransaction = (publicKey: PublicKey, data: any) => {
  return axios
    .put(`${SIGN_ON_GLASS}/${publicKey}`, data)
    .then((d) => d.data)
    .catch((error) => error.response.data)
}

export const uploadTransactionNotesImage = (publicKey: PublicKey, data: any) => {
  return axios
    .put(`${TRANSACTION_PHOTO}/${publicKey}`, data)
    .then((d) => d.data)
    .catch((error) => error.response.data)
}

export const requestCompleteTransaction = (publicKey: PublicKey) => {
  return axios
    .put(`${COMPLETE_TRANSACTION}/${publicKey}`)
    .then((d) => d.data)
    .catch((error) => error.response.data)
}

export const getCertificate = (publicKey: PublicKey) => {
  return axios.get(`${REQUEST_EQUIPMENT}/${publicKey}`)
}

export function listEmployee(query = '') {
  return axios.get(REQUEST_EMPLOYEE + '?' + query)
}

export function requestListLocation(query = '') {
  return axios.get(LIST_LOCATION + '?' + query)
}
