import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
//import {EquipmentListFilter} from './EquipmentListFilter'

const HazardManagementListToolbar = () => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const openAddHazardManagement = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/*<EquipmentListFilter />*/}

      {/* begin::Export */}
      {/* <ListExport endpoint='' query='' /> */}
      {/* end::Export */}

      {/* begin::Add user */}

      {/* <button type='button' className='btn btn-primary' onClick={openAddHazardManagement}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({id: "HAZARD_MANAGEMENT.BUTTON.NEW"})}{' '}
      </button> */}

      {/* end::Add user */}
    </div>
  )
}

export {HazardManagementListToolbar}
