import {ResolvedIssuesListSearchComponent} from './ResolvedIssuesListSearchComponent'

const ResolvedIssuesListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ResolvedIssuesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {/*selected.length > 0 ? <ResolvedIssuesListGrouping /> : <ResolvedIssuesListToolbar />*/}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ResolvedIssuesListHeader}
