/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Form, Formik, FormikValues} from 'formik'
import React, {FormEvent, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../_metronic/helpers'
import {Loading} from '../../../components/table/loading/Loading'
import {useListView} from '../workflow/Workflow-list/core/ListViewProvider'
import {ICreateWorkflow, modalInitialValues} from './core/_models'
import {
  createWorkFlow,
  createWorkFlowLevels,
  getWorkflowLevelsByPublicKey,
  updateWorkFlow,
  updateWorkFlowLevels,
} from './core/_requests'
import {Workflow} from './steps/Workflow'
import {WorkflowLevel} from './steps/WorkflowLevel'
import {WorkflowUsers} from './steps/WorkflowUsers'
type Props = {
  show: boolean
  handleClose: () => void
  refetch?: () => void
}

const UpdateWorkflowModal: React.FC<Props> = ({show, handleClose, refetch}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [initValues] = useState<ICreateWorkflow>(modalInitialValues)
  const [workflowPublicKey, setWorkflowPublicKey] = useState('')
  const [workflowLevelsPublicKey, setWorkflowLevelsPublicKey] = useState('')

  const intl = useIntl()

  const {itemIdForUpdate}: any = useListView()

  useEffect(() => {
    const getLevelsWorkflow = async () => {
      const {data} = await getWorkflowLevelsByPublicKey(itemIdForUpdate)
      setWorkflowLevelsPublicKey(data?.result[0]?.publicKey)
    }

    setWorkflowPublicKey(itemIdForUpdate)

    getLevelsWorkflow()
  }, [])

  const createWorkflowSchemas = [
    Yup.object({
      table: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}))
        .nullable(),
      table_publicKey: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}))
        .nullable(),
      genericActivity: Yup.string(),
      activity_publicKey: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}))
        .nullable(),
      description: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}))
        .nullable(),
    }),
    Yup.object({
      level: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}))
        .nullable(),
      minimumApproval: Yup.string()
        .matches(/^[0-9]+$/, intl.formatMessage({id: 'VALIDATION.NUMBER'}))
        .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}))
        .nullable(),
    }),
  ]

  const [currentSchema, setCurrentSchema] = useState(createWorkflowSchemas[0])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = (event: FormEvent) => {
    if (!stepper.current) {
      return
    }

    event.preventDefault()

    stepper.current.goPrev()

    setCurrentSchema(createWorkflowSchemas[stepper.current.currentStepIndex - 1])
  }

  const saveWorkflowInfo = async (values: any, actions: FormikValues, stepper: any) => {
    if (workflowPublicKey === '') {
      try {
        const fixedValues = {
          genericActivity: !values.genericActivity ? undefined : 1,
          activity_publicKey: !values.genericActivity ? values.activity_publicKey : undefined,
          description: values.description,
          table: values.table,
          table_publicKey: values.table_publicKey,
        }

        const data = await updateWorkFlow(fixedValues, itemIdForUpdate)
        if (!data || data?.error === 1) {
          actions.setStatus(data?.messages)
          toast.error(intl.formatMessage({id: 'TOAST.ERROR.UPDATE'}))
        } else {
          setCurrentSchema(createWorkflowSchemas[stepper.current.currentStepIndex])
          actions.setStatus()
          toast.success(intl.formatMessage({id: 'TOAST.SUCCESS.UPDATE'}))
          setWorkflowPublicKey(data?.publicKey as string)
          stepper.current.goNext()
        }
      } catch (e: any) {
        actions.setStatus(e?.response.data.messages)
        toast.error(intl.formatMessage({id: 'TOAST.SUBMIT.FAILED'}))
      } finally {
        actions.setSubmitting(false)
        return
      }
    }

    try {
      const fixedValues = {
        publicKey: workflowPublicKey,
        genericActivity: !values.genericActivity ? undefined : 1,
        activity_publicKey: !values.genericActivity ? values.activity_publicKey : undefined,
        description: values.description,
        table: values.table,
        table_publicKey: values.table_publicKey,
      }

      const data = await updateWorkFlow(fixedValues, itemIdForUpdate)
      if (!data || data?.error === 1) {
        actions.setStatus(data?.messages)
        toast.error(intl.formatMessage({id: 'TOAST.ERROR.UPDATE'}))
      } else {
        setCurrentSchema(createWorkflowSchemas[stepper.current.currentStepIndex])
        actions.setStatus()
        toast.success(intl.formatMessage({id: 'TOAST.SUCCESS.UPDATE'}))
        stepper.current.goNext()
      }
    } catch (e: any) {
      actions.setStatus(e?.response.data.messages)
      toast.error(intl.formatMessage({id: 'TOAST.SUBMIT.FAILED'}))
    } finally {
      actions.setSubmitting(false)
      return
    }
  }

  const saveWorkflowLevelsInfo = async (
    {level, minimumApproval}: any,
    actions: FormikValues,
    stepper: any
  ) => {
    if (workflowLevelsPublicKey === '') {
      try {
        const fixedValues = {
          publicKey: workflowLevelsPublicKey,
          workflow_publicKey: itemIdForUpdate,
          level,
          minimumApproval,
        }

        const data = await createWorkFlowLevels(fixedValues)
        if (!data || data?.error === 1) {
          actions.setStatus(data?.messages)
          toast.error(intl.formatMessage({id: 'TOAST.ERROR.UPDATE'}))
        } else {
          actions.setStatus()
          toast.success(intl.formatMessage({id: 'TOAST.SUCCESS.UPDATE'}))
          setWorkflowLevelsPublicKey(data?.publicKey as string)
          stepper.current.goNext()
        }
      } catch (e: any) {
        actions.setStatus(e?.response.data.messages)
        toast.error(intl.formatMessage({id: 'TOAST.SUBMIT.FAILED'}))
      } finally {
        actions.setSubmitting(false)
        return
      }
    }
    try {
      const fixedValues = {
        publicKey: workflowLevelsPublicKey,
        workflow_publicKey: workflowPublicKey,
        level,
        minimumApproval,
      }

      const data = await updateWorkFlowLevels(fixedValues)
      if (!data || data?.error === 1) {
        actions.setStatus(data?.messages)
        toast.error(intl.formatMessage({id: 'TOAST.ERROR.UPDATE'}))
      } else {
        actions.setStatus()
        toast.success(intl.formatMessage({id: 'TOAST.SUCCESS.UPDATE'}))
        stepper.current.goNext()
      }
    } catch (e: any) {
      actions.setStatus(e?.response.data.messages)
      toast.error(intl.formatMessage({id: 'TOAST.SUBMIT.FAILED'}))
    } finally {
      actions.setSubmitting(false)
      return
    }
  }

  const submitStep = async (values: ICreateWorkflow, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      if (stepper.current.currentStepIndex === 1) {
        saveWorkflowInfo(values, actions, stepper)
      } else if (stepper.current.currentStepIndex === 2) {
        saveWorkflowLevelsInfo(values, actions, stepper)
      } else {
        actions.setSubmitting(false)
        stepper.current.goNext()
      }
    } else {
      if (refetch !== undefined) refetch()
      actions.resetForm()
      handleClose()
      //stepper.current.goto(1)
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <>
      {show && (
        <>
          <div
            tabIndex={-1}
            className={`modal fade d-block ${show && 'show'}`}
            id='kt_modal_add_inspectionStep'
            role='dialog'
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>{intl.formatMessage({id: 'ACCESS.WORKFLOW'})}</h2>

                  {/* begin::Close */}
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    onClick={handleClose}
                    style={{cursor: 'pointer'}}
                  >
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                  </div>
                  {/* end::Close */}
                </div>

                <div className='modal-body'>
                  <div
                    ref={stepperRef}
                    className='stepper stepper-links d-flex flex-column '
                    id='kt_create_inspection_delivery_stepper'
                  >
                    <div className='stepper-nav mb-5'>
                      <div className='stepper-item current' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>
                          {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.HEADER.STEP.WORKFLOW'})}
                        </h3>
                      </div>

                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>
                          {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.HEADER.STEP.LEVEL'})}
                        </h3>
                      </div>

                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>
                          {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.HEADER.STEP.USERS'})}
                        </h3>
                      </div>
                    </div>

                    <Formik
                      validationSchema={currentSchema}
                      initialValues={initValues}
                      onSubmit={submitStep}
                    >
                      {(props) => (
                        <Form className='mx-auto mw-500px w-100 pb-10' id='kt_create_account_form'>
                          <div className='current' data-kt-stepper-element='content'>
                            <Workflow {...props} tabActived={stepper?.current?.currentStepIndex} />
                          </div>
                          <div data-kt-stepper-element='content'>
                            <WorkflowLevel
                              {...props}
                              tabActived={stepper?.current?.currentStepIndex}
                            />
                          </div>
                          <div data-kt-stepper-element='content'>
                            {workflowPublicKey !== '' &&
                              workflowLevelsPublicKey !== '' &&
                              stepper.current?.currentStepIndex === 3 && (
                                <WorkflowUsers
                                  workflowPublicKey={workflowPublicKey}
                                  workflowLevelsPublicKey={workflowLevelsPublicKey}
                                  {...props}
                                  tabActived={stepper?.current?.currentStepIndex}
                                />
                              )}
                          </div>

                          {/* begin::Actions */}
                          <div className='pt-7'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div className='d-flex justify-content-start'>
                                {stepper.current?.currentStepIndex !== 1 && (
                                  <button
                                    onClick={(e) => {
                                      prevStep(e)
                                    }}
                                    type='button'
                                    className='btn btn-lg btn-light-primary mx-0'
                                    data-kt-stepper-action='previous'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr063.svg'
                                      className='svg-icon-4 me-1'
                                    />
                                    {intl.formatMessage({id: 'FORM.BUTTON.BACK'})}
                                  </button>
                                )}

                                {stepper.current?.currentStepIndex === 1 && (
                                  <button
                                    type='reset'
                                    onClick={() => handleClose()}
                                    className='btn btn-danger'
                                    data-kt-inspection-steps-modal-action='cancel'
                                    disabled={props.isSubmitting}
                                  >
                                    {intl.formatMessage({id: 'FORM.BUTTON.DISCARD'})}
                                  </button>
                                )}
                              </div>

                              <div className='d-flex justify-content-end'>
                                <button type='submit' className='btn btn-lg btn-primary '>
                                  <span className='indicator-label'>
                                    {stepper.current?.currentStepIndex &&
                                      stepper.current?.totalStepsNumber !==
                                        stepper.current?.currentStepIndex &&
                                      intl.formatMessage({id: 'FORM.STEP.BUTTON.SUBMIT'})}

                                    {stepper.current?.currentStepIndex &&
                                      stepper.current?.totalStepsNumber ===
                                        stepper.current?.currentStepIndex &&
                                      workflowLevelsPublicKey !== '' &&
                                      intl.formatMessage({id: 'FORM.STEP.BUTTON.CONCLUDE'})}

                                    {stepper.current?.currentStepIndex &&
                                      stepper.current?.currentStepIndex < 1 &&
                                      intl.formatMessage({id: 'FORM.STEP.BUTTON.CONTINUE'})}
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr064.svg'
                                      className='svg-icon-3 ms-2 me-0'
                                    />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* end::Actions */}

                          {props.isSubmitting && <Loading />}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`modal-backdrop fade ${show && 'show'}`}></div>
        </>
      )}
    </>
  )
}
export {UpdateWorkflowModal}
