import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {useReactToPrint} from 'react-to-print'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../../_metronic/helpers'
import {getRiskAnalisy, updateWorkAuthorization} from './core/_requests'

const ViewWorkAuthorization: React.FC<any> = () => {
  const intl = useIntl()

  const [data, setData]: any = useState()
  const navigate = useNavigate()

  const {publicKey, workflow_publicKey} = useParams()

  const [workAuthStatus, setWorkAuthStatus] = useState<string | null>(null)
  const [isPrinting, setIsPrinting] = useState(false)

  const componentRef: any = useRef()

  const handleSubmitForm = () => {
    updateWorkAuthorization(workflow_publicKey, workAuthStatus)
    navigate('/access/work-authorization/pending-list')
    toast.success(
      intl.formatMessage({
        id: 'TOAST.SUCCESS.UPDATE',
      })
    )
  }

  useEffect(() => {
    getRiskAnalisy(setData, publicKey)
  }, [])

  const handlePrint = useReactToPrint({
    pageStyle: () =>
      '@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin: 5mm 0mm 5mm 0mm !important }}',
    content: () => componentRef.current,
  })

  const handleClick = () => {
    setIsPrinting(true)
    toast.loading(intl.formatMessage({id: 'TRANSACTION.CERTIFICATE.TOAST.WAIT'}))
    setTimeout(() => {
      handlePrint()
      toast.dismiss()
      setIsPrinting(false)
    }, 0.1)
  }

  return (
    <>
      {!workflow_publicKey && (
        <div className='w-100 d-flex align-items-center justify-content-end mb-4'>
          <button onClick={() => handleClick()} type='button' className='btn btn-primary fw-bold'>
            {intl.formatMessage({
              id: 'RISK.VIEW_RISK_ANALYSIS.PRINT_ANSLYSIS',
            })}
          </button>
        </div>
      )}

      <div className='card card-custom overflow-hidden'>
        <div ref={componentRef} className='card-body p-0'>
          <div
            className='row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0'
            style={{backgroundColor: 'rgba(31,12,87,1)'}}
          >
            <div className='col-md-9'>
              <div className='d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row'>
                <h1 className='display-4 text-white fw-boldest mb-10'>
                  {data?.activity_description}
                </h1>
                <div className='d-flex flex-column align-items-md-end px-0'>
                  <a href='#' className='mb-5'>
                    <img
                      src='/metronic/theme/html/demo5/dist/assets/media/logos/logo-light.png'
                      alt=''
                    />
                  </a>
                </div>
              </div>
              <div className='border-bottom w-100 opacity-20'></div>
              <div className='d-flex justify-content-between align-items-center text-white pt-6'>
                <div className='d-flex flex-column flex-root text-end'>
                  <div className='d-flex flex-column  align-items-end'>
                    <span className='fw-bold mb-2r'>
                      {/* {intl.formatMessage({
                        id: 'USER.FORM.LABEL.TITLE',
                      })} */}
                      Employee :
                    </span>
                    <span className='opacity-40'>{data?.employee_name}</span>
                  </div>
                  <div className='d-flex flex-column mt-4'>
                    <span className='fw-bold mb-2r'>
                      {/* {intl.formatMessage({
                        id: 'RISK.RISK_ANALYSIS.VIEW_RISK_ANALISYS.MANAGER',
                      })} */}
                      Location :
                    </span>
                    <span className='opacity-40'>{data?.location_name}</span>
                  </div>
                </div>
                <div className='d-flex flex-column flex-root text-end'>
                  <div className='d-flex flex-column  align-items-end'>
                    <span className='fw-bold mb-2'>
                      {intl.formatMessage({
                        id: 'RISK.RISK_ANALYSIS.VIEW_RISK_ANALISYS.ENVIRONMENT',
                      })}
                      :
                    </span>
                    {data?.activity_environment === 'internal' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.INTERNAL',
                        })}
                      </span>
                    )}
                    {data?.activity_environment === 'external' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.EXTERNAL',
                        })}
                      </span>
                    )}
                    {data?.activity_environment === 'confined' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.CONFINED',
                        })}
                      </span>
                    )}
                  </div>
                  <div className='d-flex flex-column mt-4'>
                    <span className='fw-bold mb-2'>
                      {intl.formatMessage({
                        id: 'RISK.RISK_ANALYSIS.VIEW_RISK_ANALISYS.FREQUENCY',
                      })}
                      :
                    </span>
                    {data?.activity_frequency === 'daily' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.DAILY',
                        })}
                      </span>
                    )}
                    {data?.activity_frequency === 'weekly' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.WEEKLY',
                        })}
                      </span>
                    )}
                    {data?.activity_frequency === 'monthly' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.MONTHLY',
                        })}
                      </span>
                    )}
                    {data?.activity_frequency === 'quarterly' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.QUARTERLY',
                        })}
                      </span>
                    )}
                    {data?.activity_frequency === 'semi-annually' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.SEMI_ANNUALLY',
                        })}
                      </span>
                    )}
                    {data?.activity_frequency === 'annually' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.ANNUALLY',
                        })}
                      </span>
                    )}
                    {data?.activity_frequency === 'once' && (
                      <span className='opacity-40'>
                        {intl.formatMessage({
                          id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.OPTION.ONCE',
                        })}
                      </span>
                    )}
                  </div>
                </div>
                <div className='d-flex flex-column flex-root text-end'>
                  <div className='d-flex flex-column  align-items-end'>
                    <span className='fw-bold mb-2'>
                      {intl.formatMessage({
                        id: 'RISK.RISK_ANALYSIS.VIEW_RISK_ANALISYS.PERMISSION_REQUIRED',
                      })}
                    </span>
                    <span
                      className={`opacity-70 badge ${
                        data?.activity_permitionRequired == 1 ? 'badge-success' : 'badge-danger'
                      }`}
                    >
                      {data?.activity_permitionRequired == 1 ? 'Yes' : 'No'}
                    </span>
                  </div>
                  <div className='d-flex flex-column align-items-end mt-4'>
                    <span className='fw-bold mb-2'>
                      {intl.formatMessage({
                        id: 'RISK.RISK_ANALYSIS.VIEW_RISK_ANALISYS.ACCESS_CONTROL_REQUIRED',
                      })}
                    </span>
                    <span
                      className={`opacity-70 badge ${
                        data?.activity_accessControlRequired == 1 ? 'badge-success' : 'badge-danger'
                      }`}
                    >
                      {data?.activity_accessControlRequired == 1 ? 'Yes' : 'No'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row justify-content-center w-100 bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0 mt-12'>
            <div className='col-md-9'>
              <div className='d-flex flex-column w-100 justify-content-between flex-md-row font-size-lg px-10 w-100'>
                <div className='d-flex flex-column mb-10 mb-md-0' style={{width: '350px'}}>
                  <div className='fw-bold font-size-lg mb-8 text-end'>
                    {intl.formatMessage({
                      id: 'RISK.VIEW_RISK_ANALYSIS.REQUESTED_INFORMATION',
                    })}
                  </div>
                  <div className='d-flex justify-content-between mb-3'>
                    <span className='mr-15 fw-bold'>
                      {intl.formatMessage({
                        id: 'RISK_ANALYSIS.TABLE.REQUESTED_BY.TITLE',
                      })}
                      :
                    </span>
                    <span className='text-right'>{data?.requestedBy_user_fullName}</span>
                  </div>
                  <div className='d-flex justify-content-between mb-3'>
                    <span className='mr-15 fw-bold'>
                      {intl.formatMessage({
                        id: 'RISK.RISK_ANALYSIS.VIEW_RISK_ANALISYS.GOVERNMENT_ID',
                      })}
                      :
                    </span>
                    <span className='text-right'>{data?.requestedBy_governmentIdNumber}</span>
                  </div>

                  <div className='d-flex justify-content-between mb-3'>
                    <span className='mr-20 fw-bold'>
                      {intl.formatMessage({
                        id: 'RISK.RISK_ANALYSIS.VIEW_RISK_ANALISYS.REQUESTED_DATE',
                      })}
                      :
                    </span>
                    <span className='text-right'>
                      {new Date(data?.created_at).toLocaleDateString() +
                        ' ' +
                        new Date(data?.created_at).toLocaleTimeString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {data?.riskAnalysis_publicKey !== null ? (
            <div className='row justify-content-center py-8 px-8 py-md-10 px-md-0'>
              <div className='col-md-9'>
                <div className='table-responsive'>
                  {/* Activity Hazards */}

                  <div className='w-100 text-end border-top'>
                    <h1 className='mt-6'>
                      {intl.formatMessage({
                        id: 'RISK_ANALYSIS.MODAL.CONTROL_MEASURE',
                      })}
                    </h1>
                  </div>

                  <div className='table mt-6 w-100 d-flex flex-column justify-content-center align-items-center border-bottom mb-6'>
                    <div className='d-flex gap-16 w-100 justify-content-between'>
                      <div>
                        <span className='pl-0 fw-bold text-muted text-uppercase'>
                          {intl.formatMessage({
                            id: 'ACTIVITY.HEADER.TAB.SOP',
                          })}
                        </span>
                      </div>

                      <div>
                        <span className='pl-0 fw-bold text-muted text-uppercase'>
                          {intl.formatMessage({
                            id: 'RISK_ANALYSIS.TABLE.FILTER.LABEL.STATUS',
                          })}
                        </span>
                      </div>
                    </div>

                    {data?.controlMeasures?.map((hazard: any) => (
                      <div className='d-flex w-100 justify-content-between border'>
                        <div role='button' style={{width: '60%'}} className='d-flex'>
                          <span className='fw-bold text-primary'>{hazard?.description}</span>
                        </div>

                        <div className='d-flex w-100 flex-column h-auto justify-content-center'>
                          <div className='d-flex flex-column align-items-end'>
                            {hazard?.issuePending === true ? (
                              <>
                                <span className='fw-bold badge badge-warning'>
                                  {intl.formatMessage({
                                    id: 'RISK_ANALYSIS.TABLE.STATUS.PENDING',
                                  })}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className='fw-bold badge badge-success'>
                                  {intl.formatMessage({
                                    id: 'ACCESS.WORK_AUTHORIZATION.STATUS.RESOLVED',
                                  })}
                                </span>
                              </>
                            )}
                          </div>
                        </div>

                        {/* <div
                        className='d-flex w-100 flex-column h-auto justify-content-between'
                        style={{width: '50%'}}
                      >
                        {hazard.hazards.map((activity_hazards: any) => (
                          <div className='d-flex gap-16 w-100 justify-content-between  border-bottom border border-right-0 border-top-0 border-bottom-0'>
                            <div className='d-flex flex-column align-items-start'>
                              <span className='fw-bold'>{activity_hazards.description}</span>
                              {activity_hazards.classification == 1 && (
                                <span className='badge badge-light-info'>
                                  {intl.formatMessage({
                                    id: 'HAZARD_MANAGEMENT.HAZARD.CLASSIFICATION.LABEL.TRIVIAL',
                                  })}
                                </span>
                              )}
                              {activity_hazards.classification == 2 && (
                                <span className='badge badge-light-success'>
                                  {intl.formatMessage({
                                    id: 'HAZARD_MANAGEMENT.HAZARD.CLASSIFICATION.LABEL.TOLERABLE',
                                  })}
                                </span>
                              )}
                              {activity_hazards.classification == 3 && (
                                <span className='badge badge-light-warning'>
                                  {intl.formatMessage({
                                    id: 'HAZARD_MANAGEMENT.HAZARD.CLASSIFICATION.LABEL.MODERATE',
                                  })}
                                </span>
                              )}
                              {activity_hazards.classification == 4 && (
                                <span className='badge badge-light-primary'>
                                  {intl.formatMessage({
                                    id: 'HAZARD_MANAGEMENT.HAZARD.CLASSIFICATION.LABEL.SUBSTANTIAL',
                                  })}
                                </span>
                              )}
                              {activity_hazards.classification == 5 && (
                                <span className='badge badge-light-danger'>
                                  {intl.formatMessage({
                                    id: 'HAZARD_MANAGEMENT.HAZARD.CLASSIFICATION.LABEL.INTOLERABLE',
                                  })}
                                </span>
                              )}
                            </div>
                            <div className='d-flex flex-column'>
                              {activity_hazards.control_measure.map((controlMeasure: any) => (
                                <div className='d-flex flex-column align-items-end mb-2'>
                                  <span className='fw-bold'>{controlMeasure.description}</span>
                                  {controlMeasure?.table === 'equipment' && (
                                    <span
                                      className={`badge badge-light-primary  fw-bold text-uppercase`}
                                    >
                                      {intl.formatMessage({
                                        id: 'ACCESS.PENDING_ISSUES.TABLE.EQUIPMENT',
                                      })}
                                    </span>
                                  )}
                                  {controlMeasure?.table === 'training' && (
                                    <span
                                      className={`badge badge-light-info  fw-bold text-uppercase`}
                                    >
                                      {intl.formatMessage({
                                        id: 'ACCESS.PENDING_ISSUES.TABLE.TRAINING',
                                      })}
                                    </span>
                                  )}
                                  {controlMeasure?.table === 'medical' && (
                                    <span
                                      className={`badge badge-light-success  fw-bold text-uppercase`}
                                    >
                                      {intl.formatMessage({
                                        id: 'ACCESS.PENDING_ISSUES.TABLE.MEDICAL',
                                      })}
                                    </span>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div> */}
                      </div>
                    ))}
                  </div>

                  {workflow_publicKey && (
                    <>
                      <div className='d-flex mt-2 mb-7 h-50'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'></label>
                        <div className='fv-row px-2 col-sm-6'>
                          <label
                            onClick={() => {
                              setWorkAuthStatus('Approved')
                            }}
                            className={`btn btn-outline btn-outline-default ${
                              workAuthStatus === 'Approved' ? 'btn-success' : 'btn-light-success'
                            } p-2 d-flex align-items-center justify-content-center fs-2 p-2`}
                          >
                            {intl.formatMessage({
                              id: 'RISK_ANALYSIS.PENDING_RISK_ANALYSIS.MODAL.LABEL.APPROVE',
                            })}
                          </label>
                        </div>

                        <div className='fv-row px-2 col-sm-6 mt-4 mt-sm-0'>
                          <label
                            onClick={() => {
                              setWorkAuthStatus('Denied')
                            }}
                            className={`btn btn-outline btn-outline-default ${
                              workAuthStatus === 'Denied' ? 'btn-danger' : 'btn-light-danger'
                            } p-2 d-flex align-items-center justify-content-center fs-2 p-2`}
                          >
                            {intl.formatMessage({
                              id: 'RISK_ANALYSIS.PENDING_RISK_ANALYSIS.MODAL.LABEL.DENY',
                            })}
                          </label>
                        </div>
                      </div>

                      <div className='text-center pt-6'>
                        <button
                          onClick={() => {
                            navigate('/access/work-authorization/pending-list')
                          }}
                          className='btn btn-light me-3'
                        >
                          {intl.formatMessage({id: 'FORM.BUTTON.CANCEL'})}
                        </button>
                        <button
                          onClick={() => {
                            handleSubmitForm()
                          }}
                          disabled={workAuthStatus === null}
                          className='btn btn-primary'
                        >
                          {intl.formatMessage({id: 'FORM.BUTTON.SUBMIT'})}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className='d-flex flex-column align-items-center justify-content-center p-12'>
              <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-8'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-2tx svg-icon-warning me-4'
                />
                <div className='d-flex flex-stack flex-grow-1'>
                  <div className='fs-6 text-gray-800'>
                    {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION.WARNING.NO_RISK_ANALYSIS'})}
                  </div>
                </div>
              </div>

              <button
                type='reset'
                onClick={() => window.history.back()}
                className='btn btn-light me-3'
                data-kt-inspection-steps-modal-action='cancel'
              >
                {intl.formatMessage({id: 'FORM.BUTTON.CANCEL'})}
              </button>
            </div>
          )}
        </div>
      </div>

      {!workflow_publicKey && (
        <div className='w-100 d-flex align-items-center justify-content-end'>
          <button onClick={() => handleClick()} type='button' className='btn btn-primary fw-bold'>
            {intl.formatMessage({
              id: 'RISK.VIEW_RISK_ANALYSIS.PRINT_ANSLYSIS',
            })}
          </button>
        </div>
      )}
    </>
  )
}

export default ViewWorkAuthorization
