import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../_metronic/helpers'
import {Employee} from '../../../../settings/employee/employee-list/core/_models'
import {HazardManagement, HazardManagementQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const HAZARD_MANAGEMENT_BY_ACTIVITY = `${API_ALPHA}/customer/risk/activity`
const LOCATION_LIST = `${API_ALPHA}/customer/risk/location-list`
const APPROVED_WORK_AUTHORIZATION = `${API_ALPHA}/customer/access/workAuthorization`
const HAZARD_ENVIRONMENT_URL = `${API_ALPHA}/customer/risk/hazard`
const RISK_URL = `${API_ALPHA}/customer/risk/risk`
const EMPLOYEE_URL = `${API_ALPHA}/customer/settings/listEmployeeByTitle`

const getRejectedWorkAuthorization = (query: string): Promise<HazardManagementQueryResponse> => {
  return axios
    .get(`${APPROVED_WORK_AUTHORIZATION}?${query}&filter_status=denied`)
    .then((d: AxiosResponse<HazardManagementQueryResponse>) => d.data)
}

const getRisks = (query: string): Promise<any> => {
  return axios.get(`${RISK_URL}?${query}`).then((d: AxiosResponse<any>) => d.data)
}

const getEmployeeByTitle = (publicKey: string): Promise<Employee> => {
  return axios.get(`${EMPLOYEE_URL}/${publicKey}`).then((d: AxiosResponse<Employee>) => d.data)
}

const getHazardManagementById = (publicKey: PublicKey): Promise<HazardManagement | undefined> => {
  return axios
    .get(`${HAZARD_MANAGEMENT_BY_ACTIVITY}/${publicKey}`)
    .then((response: AxiosResponse<Response<HazardManagement>>) => response.data)
    .then((response: Response<HazardManagement>) => response.result)
}

const createHazardManagementForEnvironment = (
  environment: HazardManagement
): Promise<HazardManagement | undefined> => {
  return axios
    .post(HAZARD_ENVIRONMENT_URL, environment)
    .then((response: AxiosResponse<Response<HazardManagement>>) => response.data)
    .then((response: Response<HazardManagement>) => response as Response<HazardManagement>['data'])
}

const createHazardManagement = (
  hazard_management: HazardManagement
): Promise<HazardManagement | undefined> => {
  return axios
    .post(HAZARD_MANAGEMENT_BY_ACTIVITY, hazard_management)
    .then((response: AxiosResponse<Response<HazardManagement>>) => response.data)
    .then((response: Response<HazardManagement>) => response as Response<HazardManagement>['data'])
}

const updateHazardManagement = (
  hazard_management: HazardManagement
): Promise<HazardManagement | undefined> => {
  return axios
    .put(`${HAZARD_MANAGEMENT_BY_ACTIVITY}/${hazard_management.publicKey}`, hazard_management)
    .then((response: AxiosResponse<Response<HazardManagement>>) => response.data)
    .then((response: Response<HazardManagement>) => response as Response<HazardManagement>['data'])
}

const deleteHazardManagement = (hazard_managementId: PublicKey): Promise<void> => {
  return axios.delete(`${HAZARD_ENVIRONMENT_URL}/${hazard_managementId}`).then((res) => res.data)
}

const deleteSelectedHazardManagements = (
  hazard_managementPublicKey: Array<PublicKey>
): Promise<void> => {
  const requests = hazard_managementPublicKey.map((publicKey) =>
    axios.delete(`${HAZARD_MANAGEMENT_BY_ACTIVITY}/${publicKey}`)
  )
  return axios.all(requests).then(() => {})
}

export {
  deleteHazardManagement,
  deleteSelectedHazardManagements,
  getHazardManagementById,
  createHazardManagement,
  updateHazardManagement,
  getEmployeeByTitle,
  getRisks,
  createHazardManagementForEnvironment,
  getRejectedWorkAuthorization,
}
