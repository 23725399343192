import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
//import {EquipmentListFilter} from './EquipmentListFilter'

const HazardManagementListToolbar = () => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const openAddWorkflow = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/*<EquipmentListFilter />*/}

      {/* begin::Export */}
      {/* <ListExport endpoint='' query='' /> */}
      {/* end::Export */}

      {/* begin::Add user */}

      <button type='button' className='btn btn-primary' onClick={openAddWorkflow}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'WORKFLOW.BUTTON.NEW'})}{' '}
      </button>

      {/* end::Add user */}

      {/* <button className='btn btn-primary'>New Authorization</button> */}
    </div>
  )
}

export {HazardManagementListToolbar}
