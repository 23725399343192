import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../_metronic/helpers'
import {User, UserQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const EQUIPMENT_URL = `${API_ALPHA}/customer/settings/user`

const getUsers = (query: string): Promise<UserQueryResponse> => {
  return axios
    .get(`${EQUIPMENT_URL}?${query}`)
    .then((d: AxiosResponse<UserQueryResponse>) => d.data)
}

const getUserById = (publicKey: PublicKey): Promise<User | undefined> => {
  return axios
    .get(`${EQUIPMENT_URL}/${publicKey}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (employee: User): Promise<User | undefined> => {
  return axios
    .post(EQUIPMENT_URL, employee)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (employee: User): Promise<User | undefined> => {
  return axios
    .put(`${EQUIPMENT_URL}/${employee.publicKey}`, employee)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (employeeId: PublicKey): Promise<void> => {
  return axios.delete(`${EQUIPMENT_URL}/${employeeId}`)
}

const deleteSelectedUsers = (employeePublicKey: Array<PublicKey>) => {
  const requests = employeePublicKey.map((publicKey) =>
    axios.delete(`${EQUIPMENT_URL}/${publicKey}`)
  )
  return axios.all(requests).then((res) => {
    res.map((item) => {
      let dividedUrl = item.config.url?.split('/') || ''
      item.data.publicKey = dividedUrl[dividedUrl.length - 1]
      return item
    })
    return res
  })
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
