import React, {FC, createContext, useContext} from 'react'
import {WithChildren} from '../helpers'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'pt' | 'en' | 'es'
}
const enSelected: Props = {
  selectedLang: 'en',
}
const ptSelected: Props = {
  selectedLang: 'pt',
}
const esSelected: Props = {
  selectedLang: 'es',
}

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)

  const idiomas_es = [
    'es',
    'es-AR',
    'es-BO',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-DO',
    'es-EC',
    'es-SV',
    'es-GQ',
    'es-GT',
    'es-HN',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PY',
    'es-PE',
    'es-PH',
    'es-PR',
    'es-ES',
    'es-US',
    'es-UY',
    'es-VE',
  ]
  const idiomas_en = [
    'en',
    'en-US',
    'en-GB',
    'en-AU',
    'en-CA',
    'en-IN',
    'en-IE',
    'en-MT',
    'en-NZ',
    'en-PH',
    'en-SG',
    'en-ZA',
    'en-ZW',
  ]
  const idiomas_pt = ['pt', 'pt-PT', 'pt-BR', 'pt-AO', 'pt-MZ', 'pt-GW', 'pt-TL']

  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  } else {
    if (idiomas_es.includes(window.navigator.language)) {
      return esSelected
    }

    if (idiomas_en.includes(window.navigator.language)) {
      return enSelected
    }

    if (idiomas_pt.includes(window.navigator.language)) {
      return ptSelected
    }
  }
  return enSelected
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

const I18nContext = createContext<Props>(enSelected)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
