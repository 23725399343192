import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ApproveOrRejectWorkAuthorizationListWrapper} from '../../approveOrRejectWorkAuthorization-list/PendingWorkAuthorizationList'
import {useListView} from '../core/ListViewProvider'

const ApproveWorkAuthorizationModal: React.FC = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const intl = useIntl()

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_alphatag'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-950px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>
                {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION.MASS_APPROVAL.TITLE'})}
              </h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                onClick={() => setItemIdForUpdate(undefined)}
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-inspection-steps-modal-action='close'
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
              {/* <div className='d-flex align-items-center justify-content-between w-100'> 
                <EquipmentListSearchComponent />
              </div> */}
              <ApproveOrRejectWorkAuthorizationListWrapper closeModal={setItemIdForUpdate} />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default ApproveWorkAuthorizationModal
