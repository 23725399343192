import React from 'react'
import {useIntl} from 'react-intl'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {WorkflowListWrapper} from './Workflow-list/WorkflowList'

const workflowBreadCrumbs: Array<PageLink> = [
  {
    title: 'Workflow',
    path: '/access/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Workflow: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <Outlet />
            </>
          }
        >
          <Route
            path='list'
            element={
              <>
                <PageTitle breadcrumbs={workflowBreadCrumbs}>
                  {intl.formatMessage({id: 'ACCESS.WORKFLOW_LIST'})}
                </PageTitle>
                <WorkflowListWrapper />
              </>
            }
          />

          <Route index element={<Navigate to='list' />} />
        </Route>
      </Routes>
    </>
  )
}

export default Workflow
