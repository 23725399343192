/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {DashboardButtons} from './widgets/DashboardButtons'
import {InspectionDeliveryWidget} from './widgets/InspectionDeliveryWidget'
import {LastTransactionsTable} from './widgets/LastTransactionsTable'
import {TeamPerformanceChart} from './widgets/TeamPerformanceChart'

import {useAuth} from '../../../modules/auth'

import MapClusterAsset from './components/map/MapCluster'
import {getEquipments} from './core/_requests'

type Props = {
  data: any
  intl: any
  chartFirstValue: any
  chartSecondValue: any
}

const DashboardAssetPage: FC<Props> = ({data, intl, chartFirstValue, chartSecondValue}) => {
  const [refetch, setRefetch] = useState(false)

  const {currentFeaturesObject} = useAuth()

  return (
    <>
      {/* begin::Row */}

      <InspectionDeliveryWidget
        className=''
        chartColor=''
        strokeColor=''
        chartHeight=''
        setRefetch={setRefetch}
      />

      {currentFeaturesObject && currentFeaturesObject.alphaTag && (
        <div className='row g-5 g-xl-8'>
          <div className='col-md-12 col-lg-4 col-xl-4 col-xxl-3 mb-md-5 mb-xl-10'>
            <div className='row g-5 g-xl-3 my-2'>
              {currentFeaturesObject && currentFeaturesObject.equipmentTransaction && (
                <DashboardButtons
                  color={'warning'}
                  icon={'/media/icons/duotune/general/gen044.svg'}
                  title={data?.inspectionsToBeDone}
                  description={intl.formatMessage({
                    id: 'ASSET.DASHBOARD.BUTTONS.INSPECTIONS_TO_BE_DONE',
                  })}
                  url={'/asset/tables/inspectionstobedone'}
                />
              )}

              {currentFeaturesObject && currentFeaturesObject.equipmentTransaction && (
                <DashboardButtons
                  color={'danger'}
                  icon={'/media/icons/duotune/general/gen013.svg'}
                  title={data?.inspectionsExpired}
                  description={intl.formatMessage({
                    id: 'ASSET.DASHBOARD.BUTTONS.INSPECTIONS_EXPIRED',
                  })}
                  url={'/asset/tables/inspectionsexpired'}
                />
              )}

              {currentFeaturesObject && currentFeaturesObject.equipment && (
                <DashboardButtons
                  color={'warning'}
                  icon={'/media/icons/duotune/general/gen044.svg'}
                  title={data?.equipmentsMaintence}
                  description={intl.formatMessage({
                    id: 'ASSET.DASHBOARD.BUTTONS.EQUIPMENTS_IN_MAINTENANCE',
                  })}
                  url={'/asset/tables/maintenance'}
                />
              )}

              {currentFeaturesObject && currentFeaturesObject.equipment && (
                <DashboardButtons
                  color={'danger'}
                  icon={'/media/icons/duotune/general/gen040.svg'}
                  title={data?.equipmentsDiscarded}
                  description={intl.formatMessage({
                    id: 'ASSET.DASHBOARD.BUTTONS.EQUIPMENTS_DISCARDED',
                  })}
                  url={'/asset/tables/discarded'}
                />
              )}

              {currentFeaturesObject && currentFeaturesObject.equipment && (
                <DashboardButtons
                  color={''}
                  icon={'/media/icons/duotune/general/gen013.svg'}
                  title={data?.equipmentsUnderEvaluation}
                  description={intl.formatMessage({
                    id: 'ASSET.DASHBOARD.BUTTONS.EQUIPMENTS_UNDER_EVALUATION',
                  })}
                  url={'/asset/equipment/list?underevaluation'}
                />
              )}

              {currentFeaturesObject && currentFeaturesObject.equipment && (
                <DashboardButtons
                  color={''}
                  icon={'/media/icons/duotune/ecommerce/ecm009.svg'}
                  title={data?.registeredEquipments}
                  description={intl.formatMessage({
                    id: 'ASSET.DASHBOARD.BUTTONS.REGISTERED_EQUIPMENTS',
                  })}
                  url={'/asset/equipment/list'}
                />
              )}
            </div>

            {chartFirstValue !== undefined && chartFirstValue !== undefined && (
              <TeamPerformanceChart
                className=''
                value1={chartFirstValue}
                value2={chartSecondValue}
                chartSize={100}
                chartLine={10}
                chartRotate={0}
              />
            )}
          </div>

          {currentFeaturesObject && currentFeaturesObject.equipmentTransaction && (
            <div className='col-md-12 col-lg-8 col-xl-8 col-xxl-9 mb-md-5 mb-xl-10'>
              <div className='row g-5 g-xl-3 my-2'>
                <MapClusterAsset />
                <LastTransactionsTable refetch={refetch} setRefetch={setRefetch} />
              </div>
            </div>
          )}
        </div>
      )}
      {/* end::Row */}
    </>
  )
}

const DashboardAssetWrapper: FC = () => {
  const [dashboardButtonStatus, setDashboardButtonStatus]: any = useState(undefined)

  useEffect(() => {
    getEquipments({setDashboardButtonStatus})
  }, [])

  const [firstValue, setFirstValue]: any = useState<any>(undefined)
  const [secondValue, setSecondValue]: any = useState<any>(undefined)

  useEffect(() => {
    setFirstValue(dashboardButtonStatus?.inspectionsOnTime)
    setSecondValue(dashboardButtonStatus?.inspectionsExpired)
  }, [dashboardButtonStatus])

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardAssetPage
        data={dashboardButtonStatus}
        intl={intl}
        chartFirstValue={firstValue}
        chartSecondValue={secondValue}
      />
    </>
  )
}

export {DashboardAssetWrapper}
