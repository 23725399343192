import React, {useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {initialQueryState, KTSVG, useDebounce} from '../../../../../../../_metronic/helpers'
import {
  reactSelectStyles,
  theme,
} from '../../../../../risk/hazard-management/control-measure-list/controlMeasure-edit-modal/ControlMeasureEquipmentEditModalForm'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {getActivityList, getEmployeeList} from '../../core/_requests'

const ApprovedWorkAuthorizationListFilter: React.FC = () => {
  const [employee, setEmployee] = useState(undefined as any)
  const [activity, setActivity] = useState(undefined as any)

  const [employeeOptions, setEmployeeOptions] = useState(undefined as any)
  const [activityOptions, setActivityOptions] = useState(undefined as any)

  const [searchEmployee, setSearchEmployee] = useState<string>('')
  const [searchActivity, setSearchActivity] = useState<string>('')

  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()

  const resetData = () => {
    handleChangeActivity({value: '', label: ''})
    handleChangeEmployee({value: '', label: ''})
    updateState({filter: undefined, ...initialQueryState})
  }

  const intl = useIntl()

  useEffect(() => {
    getEmployeeOptions('')
    getActivityOptions('')
  }, [])

  const filterData = () => {
    const options = {
      filter: {
        // só vai incluir item no filtro se existir o value no state
        ...(employee !== undefined ? {employee_publicKey: employee.value} : {}),
        ...(activity !== undefined ? {activity_publicKey: activity.value} : {}),
      },
      ...initialQueryState,
    }

    updateState(options)
  }

  async function getEmployeeOptions(search: string) {
    const {data} = await getEmployeeList(search)

    const options = data?.result?.map(
      ({publicKey, employee_fullName}: {publicKey: string; employee_fullName: string}) => ({
        value: publicKey,
        label: employee_fullName,
      })
    )
    setEmployeeOptions(options)
  }

  async function getActivityOptions(search: string) {
    const {data} = await getActivityList(search)
    const options =
      data?.result?.map(({publicKey, description}: {publicKey: string; description: string}) => ({
        value: publicKey,
        label: description,
      })) || []
    setActivityOptions(options)
  }

  const handleChangeEmployee = (employee_publicKey: {value: string; label: string}) => {
    if (employee_publicKey.value !== '') setEmployee(employee_publicKey)
    else setEmployee(undefined)
  }

  const handleChangeActivity = (activity_publicKey: {value: string; label: string}) => {
    if (activity_publicKey.value !== '') setActivity(activity_publicKey)
    else setActivity(undefined)
  }

  const employeeFilterOptions = (
    candidate: {label: string; value: string; data: any},
    input: string
  ) => {
    if (input) {
      setSearchEmployee(input)
    }
    return true
  }

  const debouncedEmployeeSearch = useDebounce(searchEmployee, 150)
  // Effect for API call
  useEffect(() => {
    if (debouncedEmployeeSearch !== undefined && searchEmployee !== undefined) {
      getEmployeeOptions(debouncedEmployeeSearch)
    }
  }, [debouncedEmployeeSearch])

  const activityFilterOptions = (
    candidate: {label: string; value: string; data: any},
    input: string
  ) => {
    if (input) {
      setSearchActivity(input)
    }
    return true
  }

  const debouncedActivitySearch = useDebounce(searchActivity, 150)
  // Effect for API call
  useEffect(() => {
    if (debouncedActivitySearch !== undefined && searchActivity !== undefined) {
      getActivityOptions(debouncedActivitySearch)
    }
  }, [debouncedActivitySearch])

  return (
    <>
      <Dropdown autoClose={true}>
        <Dropdown.Toggle className='btn btn-light-primary me-3' id='dropdown-basic'>
          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'TABLE.HEADER.FILTER'})}
        </Dropdown.Toggle>

        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'>
          <Dropdown.Header className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>
              {intl.formatMessage({id: 'TABLE.HEADER.FILTER.OPTIONS'})}
            </div>
          </Dropdown.Header>
          <Dropdown.Divider />

          <div className='px-7 py-5'>
            {/* Manufacturer */}
            <div className='d-flex flex-column mb-7 fv-row col-md-12'>
              <label className='form-label fs-6 fw-bold'>
                {intl.formatMessage({id: 'EMPLOYEE.TABLE.EMPLOYEE.TITLE'})}:
              </label>
              <Select
                filterOption={employeeFilterOptions}
                isClearable={true}
                isSearchable={true}
                name='employee_publicKey'
                options={employeeOptions}
                value={!employee ? '' : employee}
                onChange={(value: any) => {
                  handleChangeEmployee(value)
                  getEmployeeOptions('')
                }}
                theme={theme}
                styles={reactSelectStyles}
                placeholder={intl.formatMessage({id: 'ACCESS.TABLES_FILTER.SELECT_EMPLOYEE'})}
                className='form-control form-control-lg'
                isLoading={employeeOptions?.length === 0}
              />
            </div>
            {/* Manufacturer */}

            {/* Product Type */}
            <div className='d-flex flex-column mb-7 fv-row col-md-12'>
              <label className='form-label fs-6 fw-bold'>
                {intl.formatMessage({id: 'ACTIVITY.TITLE'})}:
              </label>
              <Select
                filterOption={activityFilterOptions}
                isClearable={true}
                isSearchable={true}
                name='productType_publicKey'
                options={activityOptions}
                onChange={(value: any) => handleChangeActivity(value)}
                placeholder={intl.formatMessage({id: 'RISK_ANALYSIS.MODAL.PLACEHOLDER.ACTIVITY'})}
                isDisabled={activityOptions?.length === 0}
                value={!activity ? '' : activity}
                theme={theme}
                styles={reactSelectStyles}
                className='form-control form-control-lg'
              />
            </div>
            <div className='d-flex flex-column fv-row col-md-12'>
              <div className='d-flex justify-content-end'>
                <Dropdown.Item
                  type='button'
                  as='button'
                  disabled={isLoading}
                  onClick={resetData}
                  className='d-block btn btn-light btn-active-light-primary fw-bold me-2 px-6 w-auto'
                  data-toggle='dropdown'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='reset'
                >
                  {intl.formatMessage({id: 'FORM.BUTTON.RESET_FILTER'})}
                </Dropdown.Item>
                <Dropdown.Item
                  type='button'
                  as='button'
                  onClick={filterData}
                  className='d-block btn btn-primary fw-bold px-6 w-auto'
                  data-toggle='dropdown'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  {intl.formatMessage({id: 'FORM.BUTTON.SUBMIT'})}
                </Dropdown.Item>
              </div>
            </div>
            {/* Product Type */}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default ApprovedWorkAuthorizationListFilter
