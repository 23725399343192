import React from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

const DashboardLatestIssuesList: React.FC<any> = ({pendingIssues}) => {
  const intl = useIntl()

  return (
    <div className='col-xl-6 mt-4 mt-xl-0'>
      <div className='card card-xl-stretch mb-xl-8'>
        <div className='card-header align-items-center border-0 mt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold text-dark'>
              {intl.formatMessage({id: 'ACCESS.DASHBOARD.LISTS.LATEST_REGISTERED_ISSUES'})}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {intl.formatMessage({
                id: 'ACCESS.DASHBOARD.LISTS.LATEST_REGISTERED_ISSUES.DESCRIPTION',
              })}
            </span>
          </h3>
          <Link to='/access/work-authorization/issues/pending-list'>
            <button className='btn btn-light-primary btn-sm'>
              {intl.formatMessage({
                id: 'ACCESS.VIEW_LATEST_PENDING_ACTIONS',
              })}
            </button>
          </Link>
        </div>

        <div className='card-body pt-3'>
          {pendingIssues?.map((item: any) => (
            <div className='d-flex align-items-sm-center mb-7'>
              <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                    {item.table === 'equipment' &&
                      intl.formatMessage({id: 'ACCESS.DASHBOARD_TABLES.ISSUES.EQUIPMENT_REQUIRED'})}
                    {item.table === 'medical' &&
                      intl.formatMessage({
                        id: 'ACCESS.DASHBOARD_TABLES.ISSUES.MEDICAL_PROCEDURE_REQUIRED',
                      })}
                    {item.table === 'training' &&
                      intl.formatMessage({
                        id: 'ACCESS.DASHBOARD_TABLES.ISSUES.TRAINING_REQUIRED',
                      })}
                  </a>
                  <span className='text-muted fw-semibold d-block pt-1'>
                    {intl.formatMessage({id: 'EMPLOYEE.TITLE'})}:{' '}
                    <span className='text-hover-primary text-gray-800'>{item.employee_name}</span>
                  </span>
                </div>

                <div className='d-flex gap-2'>
                  {item.table === 'equipment' && (
                    <span className={`badge badge-light-primary fs-8 fw-bold my-2`}>
                      {intl.formatMessage({id: 'ACCESS.PENDING_ISSUES.TABLE.EQUIPMENT'})}
                    </span>
                  )}
                  {item.table === 'training' && (
                    <span className={`badge badge-light-danger fs-8 fw-bold my-2`}>
                      {intl.formatMessage({id: 'ACCESS.PENDING_ISSUES.TABLE.TRAINING'})}
                    </span>
                  )}
                  {item.table === 'medical' && (
                    <span className={`badge badge-light-success fs-8 fw-bold my-2`}>
                      {intl.formatMessage({id: 'ACCESS.PENDING_ISSUES.TABLE.MEDICAL'})}
                    </span>
                  )}

                  <Link
                    className='btn btn-icon btn-light btn-sm border-0'
                    to={`/access/work-authorization/issues/pending-list?${item.employee_publicKey}`}
                  >
                    <span className='svg-icon svg-icon-2 svg-icon-primary'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x='18'
                          y='13'
                          width='13'
                          height='2'
                          rx='1'
                          transform='rotate(-180 18 13)'
                          fill='currentColor'
                        />
                        <path
                          d='M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DashboardLatestIssuesList
