import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useAuth} from '../../../modules/auth'
import NewAuthorizationModal from '../newAuthorizationModal/NewAuthorizationModal'
import {WorkflowModal} from '../newWorkflowModal/WorkflowModal'
import {DashboardButtons} from './components/DashboardButtons'
import {DashboardInfoButtons} from './components/DashboardInfoButtons'
import DashboardListsWrapper from './components/DashboardListsWrapper'
import {getDashboardCount} from './core/_requests'

type Props = {
  stateNewAuthorization: any
  setStateNewAuthorization: any
}

const DashboardAccessWrapper: React.FC<Props> = ({
  stateNewAuthorization,
  setStateNewAuthorization,
}) => {
  const intl = useIntl()
  const {currentFeaturesObject} = useAuth()

  const [count, setCount] = useState<any>()

  const getCount = async () => {
    const {data} = await getDashboardCount()
    setCount(data)
  }

  useEffect(() => {
    getCount()
  }, [])

  const [showModal, setShowModal] = useState(false)

  // const handleOpenModal = () => {
  //   setShowModal(true)
  // }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {stateNewAuthorization !== undefined && (
        <NewAuthorizationModal setStateNewAuthorization={setStateNewAuthorization} />
      )}
      <WorkflowModal show={showModal} handleClose={handleCloseModal} />
      <DashboardButtons setStateNewAuthorization={setStateNewAuthorization} />

      <div className='row g-5 g-xl-8 my-2'>
        {currentFeaturesObject && currentFeaturesObject.workAuthorizationIssue && (
          <DashboardInfoButtons
            title={intl.formatMessage({id: 'ACCESS.DASHBOARD.BUTTONS.ISSUES_TO_RESOLVE'})}
            icon='/media/icons/duotune/general/gen013.svg'
            url='/access/work-authorization/issues/pending-list'
            count={count?.issuesToResolve}
            color='danger'
          />
        )}
        {currentFeaturesObject && currentFeaturesObject.workAuthorizationIssue && (
          <DashboardInfoButtons
            title={intl.formatMessage({id: 'ACCESS.DASHBOARD.BUTTONS.RECENTLY_RESOLVED_ISSUES'})}
            icon='/media/icons/duotune/general/gen013.svg'
            url='/access/work-authorization/issues/resolved-list'
            count={count?.resolvedIssues}
            color='success'
          />
        )}
        {currentFeaturesObject && currentFeaturesObject.workAuthorization && (
          <DashboardInfoButtons
            title={intl.formatMessage({id: 'ACCESS.DASHBOARD.BUTTONS.APPROVED_AUTHORIZATIONS'})}
            icon='/media/icons/duotune/general/gen013.svg'
            url='/access/work-authorization/approved-list'
            count={count?.approvedAuthorizations}
            color='success'
          />
        )}
        {currentFeaturesObject && currentFeaturesObject.workAuthorization && (
          <DashboardInfoButtons
            title={intl.formatMessage({id: 'ACCESS.DASHBOARD.BUTTONS.REJECTED_AUTHORIZATIONS'})}
            icon='/media/icons/duotune/general/gen013.svg'
            url='/access/work-authorization/rejected-list'
            count={count?.rejectedAuthorizations}
            color='danger'
          />
        )}
        {currentFeaturesObject && currentFeaturesObject.workflow && (
          <DashboardInfoButtons
            title={intl.formatMessage({id: 'ACCESS.DASHBOARD.BUTTONS.CONFIGURE_WORKFLOW'})}
            icon='/media/icons/duotune/general/gen013.svg'
            url='/access/workflow'
            count={count?.workflows}
            color='warning'
          />
        )}
      </div>
      <DashboardListsWrapper />
    </>
  )
}

export default DashboardAccessWrapper
