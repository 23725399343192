import React from 'react'
import FaceRecognition from '../components/FaceRecognition'
import CameraReader from '../components/CameraReader'

type Props = {
  setSafetyTagScanned: React.Dispatch<React.SetStateAction<string>>
  safetyTagScanned: string
  currentStepIndex: Number
  employeeFaceRecognition?: boolean
  transactionPublicKey?: string
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
  handleClose: () => void
  nfcAvailable: boolean
  setNfcAvailable: React.Dispatch<React.SetStateAction<boolean>>
}

const ScanSafetyTag: React.FC<Props> = ({
  safetyTagScanned,
  setSafetyTagScanned,
  employeeFaceRecognition,
  transactionPublicKey,
  setCurrentStepIndex,
  handleClose,
  nfcAvailable,
  setNfcAvailable,
}) => {
  return (
    <div className='w-100 h-100'>
      {employeeFaceRecognition && transactionPublicKey ? (
        <FaceRecognition
          transactionPublicKey={transactionPublicKey}
          safetyTagScanned={safetyTagScanned}
          setCurrentStepIndex={setCurrentStepIndex}
        />
      ) : (
        <>
          <CameraReader
            setCurrentStepIndex={setCurrentStepIndex}
            safetyTagScanned={safetyTagScanned}
            setPublicKey={setSafetyTagScanned}
            handleClose={handleClose}
            nfcAvailable={nfcAvailable}
            setNfcAvailable={setNfcAvailable}
          />
        </>
      )}
    </div>
  )
}

export default ScanSafetyTag
