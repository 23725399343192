import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../../../../_metronic/helpers'
import {Area, AreaQueryResponse} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const AREA_LIST = `${API_ALPHA}/customer/settings/area`

const getAreas = (query: string) => {
  return axios.get(`${AREA_LIST}?${query}`).then((d: AxiosResponse<AreaQueryResponse>) => d.data)
}

const getAreaById = (publicKey: PublicKey): Promise<Area | undefined> => {
  return axios
    .get(`${AREA_LIST}/${publicKey}`)
    .then((response: AxiosResponse<Response<Area>>) => response.data)
    .then((response: Response<Area>) => response.result)
}

const createArea = (building: Area): Promise<Area | Response<Area> | undefined> => {
  return axios
    .post(AREA_LIST, building)
    .then((response: AxiosResponse<Response<Area>>) => response.data)
    .then((response: Response<Area>) => response)
}

const updateArea = (building: Area): Promise<Area | undefined> => {
  return axios
    .put(`${AREA_LIST}/${building.publicKey}`, building)
    .then((response: AxiosResponse<Response<Area>>) => response.data)
    .then((response: Response<Area>) => response as Response<Area>['data'])
}

const deleteArea = (key: PublicKey) => {
  return axios.delete(`${AREA_LIST}/${key}`)
}

const deleteSelectedAreas = (buildingPublicKey: Array<PublicKey>) => {
  const requests = buildingPublicKey.map((publicKey) => axios.delete(`${AREA_LIST}/${publicKey}`))
  return axios.all(requests).then((res) => {
    res.map((item) => {
      let dividedUrl = item.config.url?.split('/') || ''
      item.data.publicKey = dividedUrl[dividedUrl.length - 1]
      return item
    })
    return res
  })
}

export {getAreas, deleteArea, deleteSelectedAreas, createArea, updateArea, getAreaById}
