import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Loading} from '../../../../components/table/loading/Loading'
import {getTransactions} from '../core/_requests'
import {TableColumn} from '../tables/TableColumn'

const LIMIT = 7

type Props = {
  refetch: any
  setRefetch: any
}

const LastTransactionsTable: React.FC<Props> = ({refetch, setRefetch}) => {
  const [transaction, setTransaction] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getTransactionList = async () => {
      const {result} = await getTransactions(`limit=${LIMIT}&offset=0`)
      //const {result, filters}
      setTransaction((result as any) || [])
      setLoading(false)
    }

    getTransactionList()
    setRefetch(false)
  }, [refetch])

  const intl = useIntl()

  return (
    <>
      <div className='card mb-xl-8'>
        {loading && <Loading />}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {' '}
              {intl.formatMessage({id: 'ASSET.DASHBOARD.LAST_TRANSACTIONS.TITLE'})}
            </span>

            {/*<span className='text-muted mt-1 fw-semibold fs-7'>Total {filters ? filters.count : '...'} transactions</span>*/}
          </h3>
        </div>

        <div className='card-body py-1'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-6'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>
                    {intl.formatMessage({id: 'ASSET.DASHBOARD.LAST_TRANSACTIONS.HEADER.EQUIPMENT'})}
                  </th>
                  <th className='min-w-120px'>
                    {intl.formatMessage({id: 'ASSET.DASHBOARD.LAST_TRANSACTIONS.HEADER.DATE'})}
                  </th>
                  <th className='min-w-120px'>
                    {intl.formatMessage({
                      id: 'ASSET.DASHBOARD.LAST_TRANSACTIONS.HEADER.DELIVERED_TO',
                    })}
                  </th>
                  <th className='min-w-120px'>
                    {intl.formatMessage({id: 'ASSET.DASHBOARD.LAST_TRANSACTIONS.HEADER.STATUS'})}
                  </th>
                </tr>
              </thead>

              <tbody>
                {transaction.length !== 0 ? (
                  transaction?.map((transaction, key) => (
                    <TableColumn
                      key={key}
                      equipment_name={transaction.equipment_name}
                      area_name={transaction.area_name}
                      building_name={transaction.building_name}
                      date={transaction.equipmentTransaction_created_at}
                      employee_fullName={transaction.employee_fullName}
                      status={transaction.equipmentTransaction_action}
                    />
                  ))
                ) : (
                  <tr className='text-gray-600 fw-bold'>
                    <td colSpan={6}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({id: 'TABLE.NO_RECORDS_FOUND'})}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export {LastTransactionsTable}
