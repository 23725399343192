import {useParams} from 'react-router-dom'
import PendingIssuesListFilter from './PendingIssuesListFilter'
import {PendingIssuesListSearchComponent} from './PendingIssuesListSearchComponent'

const PendingIssuesListHeader = () => {
  const {publicKey} = useParams()

  return (
    <div className='card-header border-0 pt-6'>
      <PendingIssuesListSearchComponent />

      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end gap-4' data-kt-user-table-toolbar='base'>
          {/* begin::Group actions */}
          {!publicKey && <PendingIssuesListFilter />}
        </div>
        {/*selected.length > 0 ? <PendingIssuesListGrouping /> : <PendingIssuesListToolbar />*/}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PendingIssuesListHeader}
