import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../_metronic/helpers'
import {ResolvedIssues} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const APPROVED_WORK_AUTHORIZATION = `${API_ALPHA}/customer/access/workAuthorizationIssue`

const getResolvedIssues = (query: string): Promise<any> => {
  return axios
    .get(`${APPROVED_WORK_AUTHORIZATION}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getResolvedIssuesById = (publicKey: PublicKey): Promise<ResolvedIssues | undefined> => {
  return axios
    .get(`${APPROVED_WORK_AUTHORIZATION}/${publicKey}`)
    .then((response: AxiosResponse<Response<ResolvedIssues>>) => response.data)
    .then((response: Response<ResolvedIssues>) => response.result)
}

const createResolvedIssuesForEnvironment = (
  environment: ResolvedIssues
): Promise<ResolvedIssues | undefined> => {
  return axios
    .post(APPROVED_WORK_AUTHORIZATION, environment)
    .then((response: AxiosResponse<Response<ResolvedIssues>>) => response.data)
    .then((response: Response<ResolvedIssues>) => response as Response<ResolvedIssues>['data'])
}

const createResolvedIssues = (
  hazard_management: ResolvedIssues
): Promise<ResolvedIssues | undefined> => {
  return axios
    .post(APPROVED_WORK_AUTHORIZATION, hazard_management)
    .then((response: AxiosResponse<Response<ResolvedIssues>>) => response.data)
    .then((response: Response<ResolvedIssues>) => response as Response<ResolvedIssues>['data'])
}

const updateResolvedIssues = (
  hazard_management: ResolvedIssues
): Promise<ResolvedIssues | undefined> => {
  return axios
    .put(`${APPROVED_WORK_AUTHORIZATION}/${hazard_management.publicKey}`, hazard_management)
    .then((response: AxiosResponse<Response<ResolvedIssues>>) => response.data)
    .then((response: Response<ResolvedIssues>) => response as Response<ResolvedIssues>['data'])
}

const deleteResolvedIssues = (hazard_managementId: PublicKey): Promise<void> => {
  return axios
    .delete(`${APPROVED_WORK_AUTHORIZATION}/${hazard_managementId}`)
    .then((res) => res.data)
}

const deleteSelectedResolvedIssues = (
  hazard_managementPublicKey: Array<PublicKey>
): Promise<void> => {
  const requests = hazard_managementPublicKey.map((publicKey) =>
    axios.delete(`${APPROVED_WORK_AUTHORIZATION}/${publicKey}`)
  )
  return axios.all(requests).then(() => {})
}

export {
  deleteResolvedIssues,
  deleteSelectedResolvedIssues,
  getResolvedIssuesById,
  createResolvedIssues,
  updateResolvedIssues,
  getResolvedIssues,
  createResolvedIssuesForEnvironment,
}
