import axios, {AxiosResponse} from 'axios'
import {TransactionQueryResponse} from '../../transaction/transaction-list/core/_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const EQUIPMENT_URL = `${API_ALPHA}/customer/asset/dashboardAssetCount`
const TRANSACTION_URL = `${API_ALPHA}/customer/asset/equipmentTransaction`
const NEWS_URL = `${API_ALPHA}/general/logged/dashboardGeneralNews`

const getEquipments = ({setDashboardButtonStatus}: any) => {
  axios.get(`${EQUIPMENT_URL}`).then((res) => {
    setDashboardButtonStatus(res.data)
  })
}

const getTrasnsactionCount = (): Promise<TransactionQueryResponse> => {
  return axios.get(`${EQUIPMENT_URL}`).then((d: AxiosResponse<TransactionQueryResponse>) => d.data)
}

const getTransactions = (query: string): Promise<TransactionQueryResponse> => {
  return axios
    .get(`${TRANSACTION_URL}?${query}`)
    .then((d: AxiosResponse<TransactionQueryResponse>) => d.data)
}

const getNews = (setNews: any) => {
  return axios.get(`${NEWS_URL}`).then((res) => {
    setNews(res.data)
  })
}

export {getEquipments, getTransactions, getTrasnsactionCount, getNews}
