import {Column} from 'react-table'
import {PendingWorkAuthorization} from '../../core/_models'
import {PendingWorkAuthorizationActionsCell} from './PendingWorkAuthorizationActionsCell'
import {PendingWorkAuthorizationActivityCell} from './PendingWorkAuthorizationActivityCell'
import {PendingWorkAuthorizationCustomHeader} from './PendingWorkAuthorizationCustomHeader'
import {PendingWorkAuthorizationRequirementCell} from './PendingWorkAuthorizationRequirementCell'
import {PendingWorkAuthorizationSelectionCell} from './PendingWorkAuthorizationSelectionCell'
import {PendingWorkAuthorizationSelectionHeader} from './PendingWorkAuthorizationSelectionHeader'

const pendingWorkAuthorizationColumns: ReadonlyArray<Column<PendingWorkAuthorization>> = [
  // {
  //   Header: (props) => <PendingWorkAuthorizationSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({ ...props }) => (
  //     <PendingWorkAuthorizationSelectionCell publicKey={props.data[props.row.index].publicKey} />
  //   ),
  // },
  {
    Header: (props) => (
      <PendingWorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.ACTIVITY_DESCRIPTION'
        className='min-w-125px'
      />
    ),
    id: 'activity_description',
    Cell: ({...props}) => (
      <PendingWorkAuthorizationActivityCell
        pending_work_authorization={props.data[props.row.index]}
      />
    ),
  },
  {
    Header: (props) => (
      <PendingWorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.EMPLOYEE'
        className='min-w-125px'
      />
    ),
    accessor: 'employee_name',
  },
  {
    Header: (props) => (
      <PendingWorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.LOCATION_NAME'
        className='min-w-125px'
      />
    ),
    accessor: 'location_name',
  },
  {
    Header: (props) => (
      <PendingWorkAuthorizationCustomHeader
        tableProps={props}
        title='ACCESS.APPROVED_WORK_AUTHORIZATION.REQUESTED_BY-NAME'
        className='min-w-125px'
      />
    ),
    accessor: 'requestedBy_user_fullName',
  },
  {
    Header: (props) => (
      <PendingWorkAuthorizationCustomHeader
        tableProps={props}
        title='TABLE.ACTIONS.TITLE'
        className='text-center min-w-150px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <PendingWorkAuthorizationActionsCell
        workFlowPublicKey={props.data[props.row.index].workAuthorizationWorkflow_publicKey}
        publicKey={props.data[props.row.index].publicKey}
      />
    ),
  },
]

export {pendingWorkAuthorizationColumns}
