import {KTCard} from '../../../../../_metronic/helpers/components/KTCard'
import NewAuthorizationModal from '../../newAuthorizationModal/NewAuthorizationModal'
import {HazardManagementListHeader} from './components/header/HazardManagementListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {WorkAuthorizationTable} from './table/WorkAuthorizationTable'

const WorkAuthorizationList = ({stateNewAuthorization, setStateNewAuthorization}: any) => {
  return (
    <>
      <KTCard>
        <HazardManagementListHeader setStateNewAuthorization={setStateNewAuthorization} />
        <WorkAuthorizationTable />
      </KTCard>
      {stateNewAuthorization !== undefined && (
        <NewAuthorizationModal setStateNewAuthorization={setStateNewAuthorization} />
      )}
    </>
  )
}

const WorkAuthorizationListWrapper = ({stateNewAuthorization, setStateNewAuthorization}: any) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <WorkAuthorizationList
          stateNewAuthorization={stateNewAuthorization}
          setStateNewAuthorization={setStateNewAuthorization}
        />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {WorkAuthorizationListWrapper}
