import axios from 'axios'

const API_URL2 = process.env.REACT_APP_API2_URL

export const CONFIRM_EMAIL_URL = `${API_URL2}/v2/auth/authenticated/sendConfirmEmail`

// Server should return AuthModel
export function sendConfirmEmail(publicKey: string) {
  return axios.get(`${CONFIRM_EMAIL_URL}/${publicKey}`)
}
