/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PendingIssues} from '../../core/_models'

type Props = {
  pending_issues: PendingIssues
}

const PendingIssuesInfoCell: FC<Props> = ({pending_issues}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1 h6'>
          {pending_issues.activity_description && pending_issues.activity_description?.length > 20
            ? pending_issues.activity_description?.substring(0, 20) + '...'
            : pending_issues.activity_description}
        </span>
        <span className='text-muted'>
          {pending_issues.activity_environment} - {pending_issues.activity_frequency}
        </span>
      </div>
    </div>
  )
}

export {PendingIssuesInfoCell}
