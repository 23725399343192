import React, {useRef} from 'react'
import {useIntl} from 'react-intl'
import SignatureCanvas from 'react-signature-canvas'
import {requestSignOnGlassTransaction} from '../core/_requests'

type Props = {
  employeeOrLocationName: string
  transactionPublicKey: string
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
}

const Signature: React.FC<Props> = ({
  employeeOrLocationName,
  transactionPublicKey,
  setCurrentStepIndex,
}) => {
  const intl = useIntl()

  const canvasRef: any = useRef()

  const handleLimpar = () => {
    canvasRef.current.clear()
  }

  const handleSubmit = async () => {
    const dataSend = {
      image: canvasRef.current.toDataURL(),
    }

    const data = await requestSignOnGlassTransaction(transactionPublicKey, dataSend)

    if (data) {
      if (data.httpStatus === '201') {
        setCurrentStepIndex(11) // => Avança para a foto da transação
      }
    }
  }

  return (
    <>
      <div
        style={{height: '100%', overflow: 'hidden'}}
        className='w-100 d-flex flex-column align-items-center justify-content-center'
      >
        <SignatureCanvas
          penColor='black'
          canvasProps={{width: '600px', height: '200px', className: 'assinatura-canvas'}}
          ref={canvasRef}
        />
        <div className='d-flex flex-column align-items-center justify-content-center gap-4 w-100'>
          <h1>{employeeOrLocationName}</h1>
          <div className='d-flex gap-2'>
            <button className='btn btn-secondary' onClick={handleLimpar}>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.CLEAR'})}
            </button>
            <button className='btn btn-primary' onClick={handleSubmit}>
              {intl.formatMessage({id: 'MODAL.BUTTON.SUBMIT'})}
            </button>
          </div>
        </div>

        <style>
          {`
                .assinatura-canvas {
                    border-bottom: 2px solid black;
                }
            `}
        </style>
      </div>
    </>
  )
}

export default Signature
