import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const API_URL2 = process.env.REACT_APP_API2_URL

export const REGISTER_URL = `${API_URL2}/v2/auth/signUp`

export function register(data: any) {
  return axios
    .post(
      REGISTER_URL,
      {
        businessName: data.businessName,
        companyGovernmentIdNumber: data.governmentId,
        country_publicKey: data.countryPublicKey,
        firstName: data.firstName,
        lastName: data.lastName,
        userGovernmentIdNumber: data.userGovernmentId,
        mobileNumber: data.phone,
        email: data.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
        accountType: 'customer',
      },
      {
        headers: {
          Environment: 'customer',
        },
      }
    )
    .then((d) => d.data)
    .catch((error) => error.response.data)
}
