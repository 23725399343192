const QUERIES = {
  USERS_LIST: 'users-list',
  EQUIPMENTS_LIST: 'equipments-list',
  INSPECTION_STEPS_LIST: 'inspectionSteps-list',
  EQUIPMENT_TRAINING_LIST: 'equipmentTraining-list',
  EMPLOYEE_TRAINING_LIST: 'employeeTraining-list',
  EMPLOYEE_TRANSACTION_LIST: 'employeeTransaction-list',
  TRANSACTIONS_LIST: 'transactions-list',
  ACCESSORIES_LIST: 'accessories-list',
  EMPLOYEES_LIST: 'employees-list',
  TITLE_LIST: 'title-list',
  MANUFACTURER_LIST: 'manufacturer-list',
  PRODUCT_TYPE_LIST: 'productType-list',
  PRODUCT_GROUP_LIST: 'productGroup-list',
  PRODUCT_CLASS_LIST: 'productClass-list',
  PRODUCT_CATEGORY_LIST: 'productCategory-list',
  EQUIPMENTS_DISCARDED_LIST: 'equipments-discarded',
  EQUIPMENTS_MAINTENANCE_LIST: 'equipments-maintenance',
  EXPIRED_INSPECTIONS_LIST: 'expired-inspections',
  INSPECTIONS_TO_BE_DONE_LIST: 'inspections-to-be-done',
  COUNTRY_LIST: 'country-list',
  BUILDING_LIST: 'building-list',
  BRANCH_LIST: 'branch-list',
  AREA_LIST: 'area-list',
  HAZARD_LIST: 'hazard-list',
  CONTROL_MEASURE_LIST: 'controlMeasure-list',
  RESCUE_PLAN_LIST: 'rescuePlan-list',
  HAZARD_MANAGEMENT_LIST: 'hazardManagement-list',
  CONTROL_MEASURE_FILTER_LIST: 'controlMeasureFilter-list',
  RISK_ANALYSIS_LIST: 'riskAnalysis-list',
  ACTIVITY_LIST: 'activity-list',
  EMPLOYEE_LOCATION_LIST: 'employeeLocation-list',
  PENDING_WORK_AUTHORIZATION_LIST: 'pendingWorkAuthorizationIssues-list',
  WORK_AUTHORIZATION: 'workAuthorization',
  PENDING_WORK_AUTHORIZATION: 'pendingWorkAuthorization-list',
  WORKFLOW: 'workflow',
  MEDICAL_EVALUATION: 'medicalRoutine',
  TRAINING_CERTIFICATE_LIST: 'certificate-list',
  TRAINING_EMPLOYEES_WITHOUT_INTEGRATION: 'employee-integration-list',
  IMPORT_EQUIPAMENTS: 'import-equipments',
  EQUIPMENTS_LOCATION_LIST: 'equipments-location'
}

export {QUERIES}
