import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet/dist/leaflet.css'
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {ITransactionInfoMap, MapEquipmentsStatistics} from './core/_models'
import {getTransactionInformationMap} from './core/_requests'

const MapClusterAsset: FC = () => {
  const [equipmentsStatistics, setEquipmentsStatistics] = useState<MapEquipmentsStatistics[]>([])
  const mapRef = useRef<HTMLDivElement>(null)
  const intl = useIntl()

  const getEquipments = async () => {
    const {data} = await getTransactionInformationMap()
    if (data) {
      setEquipmentsStatistics(data)
    }
  }

  useEffect(() => {
    getEquipments()
  }, [])

  useEffect(() => {
    if (equipmentsStatistics && equipmentsStatistics.length > 0) {
      if (mapRef.current) {
        const map = L.map(mapRef.current).setView([-14.235, -51.9253], 4)
        map.attributionControl.setPrefix(false)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map)

        const markers = L.markerClusterGroup()

        equipmentsStatistics.map((item) => {
          if (!item.branch_coordinates || !item.equipment_info) return false

          const {latitude, longitude}: {latitude: number; longitude: number} = JSON.parse(
            item.branch_coordinates
          )
          const equipmentInfoParsed: ITransactionInfoMap[] = JSON.parse(item.equipment_info)

          var content = `<table class='table align-middle gs-0 gy-4'> 
            <thead> 
            <tr class='fw-bold text-muted bg-light'>
              <th class='text-start'>${intl.formatMessage({
                id: 'ASSET.DASHBOARD.EQUIPMENTS_CHART.TITLE',
              })}</th> 
              <th>${intl.formatMessage({
                id: 'MAPS.TOLLTIP.TABLE.DELIVERIES',
              })}</th> 
              <th>${intl.formatMessage({
                id: 'MAPS.TOLLTIP.TABLE.DISCARDED',
              })}</th> 
              <th>${intl.formatMessage({
                id: 'EQUIPMENT.VIEW.LABEL.INSPECTION',
              })}</th> 
            </tr>
            </thead> 
            <tbody>
            ${Object.entries(equipmentInfoParsed)
              .map((key) => {
                return `<tr> 
                <td class="text-start"><b>${key[0]}</b></td> 
                <td>${key[1].delivered_safetyTags}</td> 
                <td>${key[1].discarded_safetyTags}</td> 
                <td>${key[1].inspected_safetyTags}</td> 
              </tr>`
              })
              .join('')}
            </tbody>
            </table>`

          markers.addLayer(
            L.circle([latitude, longitude], {radius: 1000, color: '#1F0C57'}).bindPopup(content, {
              autoPan: true,
              minWidth: 500,
            })
          )
        })

        map.addLayer(markers)
      }
    }
  }, [equipmentsStatistics])

  return (
    <div className='d-flex'>
      <div
        className='rounded'
        ref={mapRef}
        style={{display: 'flex', height: '66vh', width: '100vw'}}
      />
    </div>
  )
}

export default MapClusterAsset
