import {KTCard} from '../../../../../_metronic/helpers/components/KTCard'
import ApproveWorkAuthorizationModal from './approveWorkAuthorizationModal/ApproveWorkAuthorizationModal'
import {PendingWorkAuthorizationListHeader} from './components/header/PendingWorkAuthorizationListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PendingWorkAuthorizationTable} from './table/PendingWorkAuthorizationTable'

const PendingWorkAuthorizationList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard>
        <PendingWorkAuthorizationListHeader />
        <PendingWorkAuthorizationTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ApproveWorkAuthorizationModal />}
    </>
  )
}

const PendingWorkAuthorizationListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PendingWorkAuthorizationList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PendingWorkAuthorizationListWrapper}
