import {KTCard} from '../../../../_metronic/helpers/components/KTCard'
import {ResolvedIssuesListHeader} from './components/header/ResolvedIssuesListHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ResolvedIssuesTable} from './table/ResolvedIssuesTable'

const ResolvedIssuesList = () => {
  return (
    <>
      <KTCard>
        <ResolvedIssuesListHeader />
        <ResolvedIssuesTable />
      </KTCard>
    </>
  )
}

const ResolvedIssuesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ResolvedIssuesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ResolvedIssuesListWrapper}
