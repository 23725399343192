/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import {
  createWorkFlowUsers,
  deleteUserFromWorkFlow,
  getUsersByWorkflowList,
} from '../core/_requests'
import {getUsers} from '../../../settings/user/user-list/core/_requests'
import {SearchSelectCustom} from '../../../../components/form/CustomSelect'
import {ICreateWorkflowStatus} from '../core/_models'
import axios from 'axios'
import {useListView} from '../../workflow/Workflow-list/core/ListViewProvider'
import {PublicKey} from '../../../../../_metronic/helpers'

export function WorkflowUsers({workflowPublicKey, workflowLevelsPublicKey}: any) {
  const intl = useIntl()
  const [usersList, setUsersList] = useState([])
  const [filterOptions, setFilterOptions]: any = useState()

  useEffect(() => {
    listUsers()
    listUsersByWorkFlow()
  }, [])

  const {itemIdForUpdate}: any = useListView()

  // listar usuarios na lista gigantesca

  const listUsers = async () => {
    const {result} = await getUsersByWorkflowList(workflowPublicKey)
    setUsersList(result as [])
  }

  // listar usuários no select
  const listUsersByWorkFlow = async () => {
    const {result} = (await getUsers('')) as any

    const options = result?.map(
      ({
        publicKey,
        firstName,
        lastName,
      }: {
        publicKey: string
        firstName: string
        lastName: string
      }) => ({
        value: publicKey,
        label: `${firstName} ${lastName}`,
      })
    )

    setFilterOptions(options)
  }

  const deleteFunction = (publicKey: string) => {
    Swal.fire({
      title: intl.formatMessage({id: 'SWAL.DELETE.TITLE'}),
      text: intl.formatMessage({id: 'SWAL.DELETE.TEXT'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({id: 'SWAL.DELETE.CONFIRM_BUTTON'}),
      cancelButtonText: intl.formatMessage({id: 'MODAL.BUTTON.CANCEL'}),
      preConfirm: async () => {
        try {
          const data = (await deleteUserFromWorkFlow(publicKey)) as ICreateWorkflowStatus
          if (data?.error === 1) {
            Swal.showValidationMessage(`${data.messages}`)
          } else {
            return true
          }
        } catch (error) {
          Swal.showValidationMessage(
            intl.formatMessage({id: 'SWAL.DELETE.REQUEST_FAILED'}) + `: ${error}`
          )
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        listUsers()
        Swal.fire(
          intl.formatMessage({id: 'SWAL.DELETE.SUCCESS.TITLE'}),
          intl.formatMessage({id: 'SWAL.DELETE.SUCCESS.TEXT'}),
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          intl.formatMessage({id: 'SWAL.DELETE.CANCELED.TITLE'}),
          intl.formatMessage({id: 'SWAL.DELETE.CANCELED.TEXT'}),
          'error'
        )
      }
    })
  }

  const handleUserAddition = async (value: string) => {
    try {
      const data = (await createWorkFlowUsers({
        user_publicKey: value,
        workflowLevel_publicKey: workflowLevelsPublicKey,
        workflow_publicKey: workflowPublicKey,
      })) as any
      if (data?.error === 1) {
        toast.error(intl.formatMessage({id: 'TOAST.ERROR.CREATE'}))
      } else {
        toast.success(intl.formatMessage({id: 'TOAST.SUCCESS.CREATE'}))
        listUsers()
      }
    } catch (e: any) {
      toast.error(intl.formatMessage({id: 'TOAST.SUBMIT.FAILED'}))
    }
  }

  return (
    <>
      <div className='content flex-row-fluid' id='kt_content'>
        <div className='card'>
          <SearchSelectCustom
            onChange={(value: string) => handleUserAddition(value)}
            //value={props.values.manufacturer_publicKey}
            placeholder={intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.PLACEHOLDER.USER'})}
            options={filterOptions}
            className='form-control'
          />

          <div className='card mb-5 mb-xl-8'>
            <div className='py-3'>
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-200px'>
                        {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.USER_LIST.NAME'})}
                      </th>
                      <th className='min-w-200px'>
                        {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.USER_LIST.LEVEL'})}
                      </th>
                      <th className='min-w-100px text-end'>
                        {intl.formatMessage({id: 'TABLE.ACTIONS.TITLE'})}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {usersList &&
                      usersList.length > 0 &&
                      usersList?.map((user: any, index) => (
                        <tr key={index}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-6 cursor-pointer'>
                                  {user?.user_name}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {user?.level === '1' && (
                                  <span className='badge badge-success'>{user?.level}</span>
                                )}
                                {user?.level === '2' && (
                                  <span className='badge badge-warning'>{user?.level}</span>
                                )}
                                {user?.level === '3' && (
                                  <span className='badge badge-danger'>{user?.level}</span>
                                )}
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                onClick={() => {
                                  deleteFunction(user?.publicKey)
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                <span className='svg-icon svg-icon-3'>
                                  <svg
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                                      fill='currentColor'
                                    />
                                    <path
                                      opacity='0.5'
                                      d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                                      fill='currentColor'
                                    />
                                    <path
                                      opacity='0.5'
                                      d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                                      fill='currentColor'
                                    />
                                  </svg>
                                </span>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {!(usersList && usersList.length > 0) && (
                  <div className='d-flex w-100 text-muted justify-content-center align-items-center'>
                    {intl.formatMessage({id: 'TABLE.NO_RECORDS_FOUND'})}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
