import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {useAuth} from '../modules/auth'
import AccessPages from '../pages/access/AccessPages'
import {DashboardAssetWrapper} from '../pages/asset/dashboard/DashboardAssetWrapper'

const PrivateRoutes = () => {
  const {companyStorage, currentFeatures, currentFeaturesObject, currentModules} = useAuth()

  const AccountPage = lazy(() => import('../pages/settings/account/AccountPage'))
  const ViewEquipment = lazy(() => import('../pages/asset/equipment/equipment-view/EquipmentView'))
  const EquipmentPage = lazy(() => import('../pages/asset/equipment/EquipmentPage'))
  const WizardCreate = lazy(() => import('../pages/asset/labeling/CreateTemplate/WizardCreate'))
  const WizardEdit = lazy(() => import('../pages/asset/labeling/EditTemplate/WIzardEdit'))
  const SelectTemplates = lazy(
    () => import('../pages/asset/labeling/PrintLabeling/SelectTemplates')
  )
  const ManufacturerPage = lazy(() => import('../pages/asset/manufacturer/ManufacturerPage'))
  const ProductsSpecificationsPage = lazy(
    () => import('../pages/asset/product-specifications/ProductsSpecificationsPage')
  )
  const TablesPage = lazy(() => import('../pages/asset/tables/TablesPage'))
  const TransactionPage = lazy(() => import('../pages/asset/transaction/TransactionPage'))
  const AuditPages = lazy(() => import('../pages/audit/AuditPages'))
  const DashboardAuditWrapper = lazy(() => import('../pages/audit/DashboardAuditWrapper'))
  const ConfirmAccount = lazy(() => import('../modules/auth/ConfirmAccount'))
  const CertificateTransaction = lazy(
    () => import('../pages/asset/transaction/certificate/CertificateTransaction')
  )
  const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
  const DashboardMedicalWrapper = lazy(
    () => import('../pages/medical/dashboard/DashboardMedicalWrapper')
  )
  const MedicalPages = lazy(() => import('../pages/medical/MedicalPages'))
  const NotPaidPage = lazy(() => import('../pages/not-paid/NotPaidPage'))
  const DashboardRiskWrapper = lazy(() => import('../pages/risk/dashboard/DashboardRiskWrapper'))
  const HazardManagementPage = lazy(
    () => import('../pages/risk/hazard-management/HazardManagementPage')
  )
  const RiskAnalysisPage = lazy(() => import('../pages/risk/riskAnalysis/RiskAnalysisPage'))
  const EmployeePage = lazy(() => import('../pages/settings/employee/EmployeePage'))
  const EmployeeDetails = lazy(() => import('../pages/settings/scan-employee/EmployeeDetails'))
  const User = lazy(() => import('../pages/settings/user/UserPage'))
  const DashboardTrainingWrapper = lazy(
    () => import('../pages/training/dashboard/DashboardTrainingWrapper')
  )
  const TrainingPages = lazy(() => import('../pages/training/TrainingPages'))
  const Workspace = lazy(() => import('../pages/workspace/Workspace'))
  const Company = lazy(() => import('../pages/settings/company/Company'))

  const manufacturerLabelingFilter = currentFeatures?.filter(
    (features: any) => features.feature === 'manufacturerLabeling'
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='workspace'
          element={
            <SuspensedView>
              <Workspace />
            </SuspensedView>
          }
        />

        <Route
          path='auth/confirm-email/:token'
          element={
            <SuspensedView>
              <ConfirmAccount />
            </SuspensedView>
          }
        />

        <Route path='auth/*' element={<Navigate to='/workspace' />} />

        <Route
          path='certificate/:publicKey'
          element={
            <SuspensedView>
              <CertificateTransaction />
            </SuspensedView>
          }
        />

        <Route
          path='safetyTag/:safetyTag'
          element={
            <SuspensedView>
              <ViewEquipment />
            </SuspensedView>
          }
        />

        <Route
          path='employeeDetails/:publicKey'
          element={
            <SuspensedView>
              <EmployeeDetails />
            </SuspensedView>
          }
        />

        {manufacturerLabelingFilter?.length > 0 ? (
          <>
            <Route
              path='asset/labeling/'
              element={
                <SuspensedView>
                  <SelectTemplates />
                </SuspensedView>
              }
            />
            <Route
              path='asset/labeling/new-label'
              element={
                <SuspensedView>
                  <WizardCreate />
                </SuspensedView>
              }
            />
            <Route
              path='asset/labeling/edit-label/:publicKey'
              element={
                <SuspensedView>
                  <WizardEdit />
                </SuspensedView>
              }
            />
            <Route path='*' element={<Navigate to='/asset/labeling' />} />
          </>
        ) : (
          <>
            {companyStorage && companyStorage.companyPublicKey ? (
              <>
                <Route
                  path='dashboard'
                  element={
                    <SuspensedView>
                      <DashboardWrapper />
                    </SuspensedView>
                  }
                />

                {/* <Route path='testings3' element={<TestingS3 />} /> */}

                {/*Settings*/}
                {currentModules?.includes('settings') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.user && (
                    <Route
                      path='settings/account/*'
                      element={
                        <SuspensedView>
                          <AccountPage />
                        </SuspensedView>
                      }
                    />
                  )}

                {/*Settings*/}
                {currentModules?.includes('settings') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.company && (
                    <Route
                      path='settings/company/*'
                      element={
                        <SuspensedView>
                          <Company />
                        </SuspensedView>
                      }
                    />
                  )}
                {/*Settings*/}
                {currentModules?.includes('settings') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.employee && (
                    <Route
                      path='settings/employee/*'
                      element={
                        <SuspensedView>
                          <EmployeePage />
                        </SuspensedView>
                      }
                    />
                  )}
                {currentModules?.includes('settings') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.company_user && (
                    <Route
                      path='settings/user/*'
                      element={
                        <SuspensedView>
                          <User />
                        </SuspensedView>
                      }
                    />
                  )}

                {currentModules?.includes('asset') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.equipment && (
                    <Route
                      path='asset/dashboard'
                      element={
                        <SuspensedView>
                          <DashboardAssetWrapper />
                        </SuspensedView>
                      }
                    />
                  )}

                {currentModules?.includes('training') && (
                  <Route
                    path='training/dashboard'
                    element={
                      <SuspensedView>
                        <DashboardTrainingWrapper />
                      </SuspensedView>
                    }
                  />
                )}
                {currentModules?.includes('medical') && (
                  <Route
                    path='medical/dashboard'
                    element={
                      <SuspensedView>
                        <DashboardMedicalWrapper />
                      </SuspensedView>
                    }
                  />
                )}
                {currentModules?.includes('audit') && (
                  <Route
                    path='audit/dashboard'
                    element={
                      <SuspensedView>
                        <DashboardAuditWrapper />
                      </SuspensedView>
                    }
                  />
                )}

                {currentModules?.includes('risk') &&
                  currentFeaturesObject &&
                  (currentFeaturesObject.riskAnalysis ||
                    currentFeaturesObject.hazardManagement) && (
                    <Route
                      path='risk/dashboard'
                      element={
                        <SuspensedView>
                          <DashboardRiskWrapper />
                        </SuspensedView>
                      }
                    />
                  )}

                {currentModules?.includes('audit') && (
                  <Route
                    path='audit/*'
                    element={
                      <SuspensedView>
                        <AuditPages />
                      </SuspensedView>
                    }
                  />
                )}

                {currentModules?.includes('access') && (
                  <Route
                    path='access/*'
                    element={
                      <SuspensedView>
                        <AccessPages />
                      </SuspensedView>
                    }
                  />
                )}

                {currentModules?.includes('medical') && (
                  <Route
                    path='medical/*'
                    element={
                      <SuspensedView>
                        <MedicalPages />
                      </SuspensedView>
                    }
                  />
                )}

                {currentModules?.includes('training') && (
                  <Route
                    path='training/*'
                    element={
                      <SuspensedView>
                        <TrainingPages />
                      </SuspensedView>
                    }
                  />
                )}

                {/*Asset*/}

                {currentModules?.includes('asset') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.manufacturer && (
                    <Route
                      path='asset/manufacturer/*'
                      element={
                        <SuspensedView>
                          <ManufacturerPage />
                        </SuspensedView>
                      }
                    />
                  )}

                {currentModules?.includes('asset') && (
                  <Route
                    path='asset/product-specifications/*'
                    element={
                      <SuspensedView>
                        <ProductsSpecificationsPage />
                      </SuspensedView>
                    }
                  />
                )}

                {currentModules?.includes('asset') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.equipment && (
                    <Route
                      path='asset/equipment/*'
                      element={
                        <SuspensedView>
                          <EquipmentPage />
                        </SuspensedView>
                      }
                    />
                  )}
                {currentModules?.includes('asset') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.equipmentTransaction && (
                    <Route
                      path='asset/transaction/*'
                      element={
                        <SuspensedView>
                          <TransactionPage />
                        </SuspensedView>
                      }
                    />
                  )}

                {/* RISK */}

                {currentModules?.includes('risk') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.hazardManagement && (
                    <Route
                      path='risk/hazard-management/*'
                      element={
                        <SuspensedView>
                          <HazardManagementPage />
                        </SuspensedView>
                      }
                    />
                  )}

                {currentModules?.includes('risk') &&
                  currentFeaturesObject &&
                  currentFeaturesObject.riskAnalysis && (
                    <Route
                      path='risk/risk-analysis/*'
                      element={
                        <SuspensedView>
                          <RiskAnalysisPage />
                        </SuspensedView>
                      }
                    />
                  )}

                {/* TABLES */}
                {currentModules?.includes('asset') && (
                  <Route
                    path='asset/tables/*'
                    element={
                      <SuspensedView>
                        <TablesPage />
                      </SuspensedView>
                    }
                  />
                )}

                <Route
                  path='not-paid/:moduleName'
                  element={
                    <SuspensedView>
                      <NotPaidPage />
                    </SuspensedView>
                  }
                />
              </>
            ) : (
              <Route path='*' element={<Navigate to='/workspace' />} />
            )}

            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404' />} />
          </>
        )}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
