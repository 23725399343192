import {motion} from 'framer-motion'
import React, {useState} from 'react'
import TransactionNotesPhoto from '../components/TransactionNotesPhoto'

type Props = {
  transactionPublicKey: string
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
  image: string
  setImage: React.Dispatch<React.SetStateAction<string>>
  transactionNotes: string
}

const TransactionNotes: React.FC<Props> = ({
  transactionPublicKey,
  setCurrentStepIndex,
  image,
  setImage,
  transactionNotes,
}) => {
  return (
    <>
      <motion.div
        initial={{y: 600}}
        animate={{y: 0}}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        className='w-100 h-100'
      >
        <TransactionNotesPhoto
          transactionPublicKey={transactionPublicKey}
          setCurrentStepIndex={setCurrentStepIndex}
          image={image}
          setImage={setImage}
          transactionNotes={transactionNotes}
        />
      </motion.div>
    </>
  )
}

export default TransactionNotes
