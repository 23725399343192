import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  count: any
}

const WorkflowApproversCell: React.FC<Props> = ({count}) => {
  const intl = useIntl()

  return (
    <div className='text-center'>
      <span className='badge badge-primary'>
        {count} {intl.formatMessage({id: 'ACCESS.WORKFLOW_LIST.APPROVERS'})}
      </span>
    </div>
  )
}

export default WorkflowApproversCell
