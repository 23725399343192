import axios from 'axios'
import {PublicKey} from '../../../../../../_metronic/helpers'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const VIEW_RISK_ANALISY = `${API_ALPHA}/customer/access/workAuthorization`
const WORK_FLOW = `${API_ALPHA}/customer/access/workAuthorizationWorkflow`

export const getRiskAnalisy = (setData: any, publicKey: PublicKey) => {
  axios.get(`${VIEW_RISK_ANALISY}/${publicKey}`).then((res) => {
    setData(res.data.result)
  })
}

export const updateWorkAuthorization = (publicKey: any, status: any) => {
  return axios.put(`${WORK_FLOW}/${publicKey}`, {
    status,
  })
}
