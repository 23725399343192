import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useAuth} from './core/Auth'
import {refreshToken} from './core/AuthHelpers'
import {confirmAccount} from './core/_requests'

const ConfirmAccount: React.FC = () => {
  const {token}: any = useParams()
  const intl = useIntl()
  const navigate = useNavigate()
  const {currentUser, auth}: any = useAuth()

  const confirmEmail = async () => {
    try {
      const data = await confirmAccount(token)

      if (data?.httpStatus === '200') {
        toast.success(intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBMIT.SUCCESS'}))
      } else {
        toast.error(data?.message)
      }
    } catch (e) {
      console.log(e)
      navigate('/dashboard')
    } finally {
      if (currentUser) {
        refreshToken(auth.refreshToken)
      }
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    confirmEmail()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default ConfirmAccount
