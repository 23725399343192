import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../../modules/auth'

type Props = {
  setStateNewAuthorization: any
}

const DashboardButtons: React.FC<Props> = ({setStateNewAuthorization}) => {
  const [hoverWorkAuthorization, setHoverWorkAuthorization]: any = useState(false)
  const [hoverPendingRequests, setHoverPendingRequests]: any = useState(false)
  const [hoverWorkflow, setHoverWorkflow]: any = useState(false)
  const {currentFeaturesObject} = useAuth()

  const intl = useIntl()

  return (
    <>
      <div className='row g-5 g-xl-8'>
        {currentFeaturesObject && currentFeaturesObject.workAuthorization && (
          <div
            className='col-xl-3'
            onMouseEnter={() => {
              setHoverWorkAuthorization(true)
            }}
            onMouseLeave={() => {
              setHoverWorkAuthorization(false)
            }}
          >
            <span
              onClick={() => setStateNewAuthorization(null)}
              className={`card ${
                hoverWorkAuthorization === false ? 'bg-dark text-gray-100' : 'bg-primary text-white'
              } card-xl-stretch mb-xl-8 cursor-pointer`}
            >
              <div className='card-body'>
                <span className='svg-icon svg-icon-body svg-icon-2hx ms-n1 flex-grow-1'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen035.svg'
                    className={`${hoverWorkAuthorization === true && 'text-white'}`}
                  />
                </span>

                <div className='fw-bold fs-2 mb-2 mt-5'>
                  {intl.formatMessage({id: 'ACCESS.DASHBOARD.HEADER_BUTTONS.WORK_AUTHORIZATION'})}
                </div>
                <div className='fw-semibold'>
                  {intl.formatMessage({
                    id: 'ACCESS.DASHBOARD.HEADER_BUTTONS.WORK_AUTHORIZATION.DESCRIPTION',
                  })}
                </div>
              </div>
            </span>
          </div>
        )}

        {currentFeaturesObject && currentFeaturesObject.workAuthorization && (
          <div
            className='col-xl-3'
            onMouseEnter={() => {
              setHoverPendingRequests(true)
            }}
            onMouseLeave={() => {
              setHoverPendingRequests(false)
            }}
          >
            <Link
              to='/access/work-authorization/pending-list'
              className={`card ${
                hoverPendingRequests === false ? 'bg-dark text-gray-100' : 'bg-primary text-white'
              } card-xl-stretch mb-xl-8`}
            >
              <div className='card-body'>
                <span className='svg-icon svg-icon-body svg-icon-2hx ms-n1 flex-grow-1'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen035.svg'
                    className={`${hoverPendingRequests === true && 'text-white'}`}
                  />
                </span>

                <div className='fw-bold fs-2 mb-2 mt-5'>
                  {intl.formatMessage({id: 'ACCESS.DASHBOARD.HEADER_BUTTONS.PENDING_REQUESTS'})}
                </div>
                <div className='fw-semibold'>
                  {intl.formatMessage({
                    id: 'ACCESS.DASHBOARD.HEADER_BUTTONS.PENDING_REQUESTS.DESCRIPTION',
                  })}
                </div>
              </div>
            </Link>
          </div>
        )}

        {/* {currentFeaturesObject && currentFeaturesObject.workflow && <div
          className='col-xl-3'
          onMouseEnter={() => {
            setHoverWorkflow(true)
          }}
          onMouseLeave={() => {
            setHoverWorkflow(false)
          }}
        >
          <Link
            to='/access/workflow/list'
            className={`card ${
              hoverWorkflow === false ? 'bg-dark text-gray-100' : 'bg-primary text-white'
            } card-xl-stretch mb-xl-8`}
          >
            <div className='card-body'>
              <span className='svg-icon svg-icon-body svg-icon-2hx ms-n1 flex-grow-1'>
                <KTSVG
                  path='/media/icons/duotune/general/gen035.svg'
                  className={`${hoverWorkflow === true && 'text-white'}`}
                />
              </span>

              <div className='fw-bold fs-2 mb-2 mt-5'>
                {intl.formatMessage({id: 'ACCESS.DASHBOARD.HEADER_BUTTONS.WORKFLOW'})}
              </div>
              <div className='fw-semibold'>
                {intl.formatMessage({
                  id: 'ACCESS.DASHBOARD.HEADER_BUTTONS.WORKFLOW.DESCRIPTION',
                })}
              </div>
            </div>
          </Link>
        </div>} */}
      </div>
    </>
  )
}

export {DashboardButtons}
