import jwt_decode from 'jwt-decode'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {useAuth} from '../../../app/modules/auth'
import {sendConfirmEmail} from '../core/_requests'

export const AlertConfirmEmail = () => {
  const intl = useIntl()
  const {currentUser, auth}: any = useAuth()
  const [verifyAccount, setVerifyAccount] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [counter, setCounter] = useState(60)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const decodedToken: any = auth?.accessToken && jwt_decode(auth.accessToken)
    setVerifyAccount(Boolean(parseInt(decodedToken?.data.verifyAccount)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmEmail = async () => {
    disableButton()

    sendConfirmEmail(currentUser?.publicKey)
      .then(() => {
        toast.success(intl.formatMessage({id: 'USER.INTEGRATE_COMPANY.MODAL.LABEL.SENT'}))
      })
      .catch(() => {
        toast.success(intl.formatMessage({id: 'TOAST.SUBMIT.FAILED'}))
      })
  }

  const disableButton = () => {
    setButtonDisabled(true)

    const interval = 1000

    const newTimer = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1)
    }, interval)

    setTimer(newTimer)

    setTimeout(() => {
      clearInterval(newTimer)
      setButtonDisabled(false)
      setCounter(60)
    }, counter * interval)
  }

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [timer])

  return (
    <>
      {verifyAccount && (
        <div className='card d-flex flex-row justify-content-between align-items-center p-6 my-4 rounded gap-3'>
          <div>
            <h1>{intl.formatMessage({id: 'AUTH.EMAIL_NOT_VERIFIED'})}</h1>
            <span className='text-muted fw-bold d-block'>
              {intl.formatMessage({id: 'AUTH.EMAIL_NOT_VERIFIED_DESCRIPTION'})}
            </span>
          </div>
          <button
            onClick={() => confirmEmail()}
            disabled={buttonDisabled}
            className='btn btn-primary'
          >
            {buttonDisabled
              ? intl.formatMessage({id: 'AUTH.CONFIRM_EMAIL.WAIT'}, {counter})
              : intl.formatMessage({id: 'AUTH.EMAIL_CONFIRM_NOW'})}
          </button>
        </div>
      )}
    </>
  )
}
