import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PendingWorkAuthorization} from '../../core/_models'

type Props = {
  pending_work_authorization_requirement:
    | PendingWorkAuthorization['activity_permitionRequired']
    | PendingWorkAuthorization['activity_accessControlRequired']
}

const PendingWorkAuthorizationRequirementCell: FC<Props> = ({
  pending_work_authorization_requirement,
}) => {
  const intl = useIntl()

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {pending_work_authorization_requirement === '0' ? (
          <span className='badge badge-success fw-bolder'>
            {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.NOT_REQUIRED'})}
          </span>
        ) : (
          <span className='badge badge-danger fw-bolder'>
            {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.REQUIRED'})}
          </span>
        )}
      </div>
    </div>
  )
}

export {PendingWorkAuthorizationRequirementCell}
