import './loading.scss'

interface Props {
  className?: string
}

const Loading = ({className}: Props) => {
  return (
    <div className={`loading ${className !== undefined ? className : ''}`}>
      <img
        src={`${process.env.REACT_APP_CDN_WHITE_LABEL}/assets/app/loading.svg`}
        alt='Start logo'
      />
    </div>
  )
}

export {Loading}
