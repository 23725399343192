import React, {useEffect} from 'react'
import {motion} from 'framer-motion'
import {Loading} from '../../../../components/table/loading/Loading'
import {useIntl} from 'react-intl'

type Props = {
  type?: string
  employeeOrLocationName?: string
  safetyTagContinued?: () => void
  safetyTagNotContinued?: () => void
  currentStepIndex: Number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
  handleClose?: () => void
  error?: string
}

const InfoStep: React.FC<Props> = ({
  type,
  employeeOrLocationName,
  safetyTagContinued,
  safetyTagNotContinued,
  currentStepIndex,
  setCurrentStepIndex,
  handleClose,
  error,
}) => {
  const intl = useIntl()

  useEffect(() => {
    if (currentStepIndex === 15) {
      setTimeout(() => {
        setCurrentStepIndex(8)
      }, 3000)
    } else if (currentStepIndex === 16) {
      setTimeout(() => {
        setCurrentStepIndex(12)
      }, 3000)
    } else if (currentStepIndex === 18) {
      setTimeout(() => {
        setCurrentStepIndex(1)
      }, 3000)
    } else if (currentStepIndex === 17) {
      setTimeout(() => {
        if (type === 'employee' || type === 'area' || type === 'building' || type === 'branch') {
          setCurrentStepIndex(13)
        } else {
          setCurrentStepIndex(3)
        }
      }, 3000)
    } else if (currentStepIndex === 19) {
      setTimeout(() => {
        setCurrentStepIndex(6)
      }, 3000)
    } else if (currentStepIndex === 20) {
      setTimeout(() => {
        setCurrentStepIndex(5)
      }, 3000)
    } else if (currentStepIndex === 21) {
      setTimeout(() => {
        setCurrentStepIndex(3)
      }, 3000)
    } else if (currentStepIndex === 23) {
      setTimeout(() => {
        setCurrentStepIndex(8)
      }, 3000)
    }
  }, [])

  return (
    <>
      {/* Prosseguir para recebimento EPI */}
      {currentStepIndex === 9 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center text-center gap-4'>
            <h1>
              {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_SIGNONGLASS'})}
            </h1>

            <div className='d-flex align-items-center justify-content-center gap-2'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  setCurrentStepIndex(10)
                }}
              >
                {intl.formatMessage({
                  id: 'TRANSACTION.BOTTOM_NAVIGATION.EMPLOYEE_SIGNONGLASS_CLICK_HERE_TO_SIGN',
                })}
              </button>
            </div>
          </div>
        </motion.div>
      )}

      {/* Confirmar employee ou location */}
      {currentStepIndex === 13 && (
        <motion.div
          initial={{opacity: 0, y: 600}}
          animate={{opacity: 1, y: 0}}
          exit={{opacity: 0, y: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 d-flex flex-column align-items-center justify-content-center'
          style={{height: '78%', overflow: 'hidden'}}
        >
          <>
            {type && employeeOrLocationName && safetyTagContinued && safetyTagNotContinued && (
              <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center text-center gap-4'>
                <h1>
                  {type === 'employee' &&
                    `${intl.formatMessage({
                      id: 'TRANSACTION.CONTINUED_EMPLOYEE',
                    })}: ${employeeOrLocationName}`}
                  {type === 'area' &&
                    `${intl.formatMessage({
                      id: 'TRANSACTION.CONTINUED_AREA',
                    })}: ${employeeOrLocationName}`}
                  {type === 'building' &&
                    `${intl.formatMessage({
                      id: 'TRANSACTION.CONTINUED_BUILDING',
                    })}: ${employeeOrLocationName}`}
                  {type === 'branch' &&
                    `${intl.formatMessage({
                      id: 'TRANSACTION.CONTINUED_BRANCH',
                    })}: ${employeeOrLocationName}`}{' '}
                  ?
                </h1>

                <div className='d-flex align-items-center justify-content-center gap-2'>
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      safetyTagContinued()
                    }}
                  >
                    {intl.formatMessage({
                      id: 'INSPECTION_DELIVERY.SWAL.INSPECTION_CONFIRM.CONFIRM_BUTTON',
                    })}
                  </button>
                  <button
                    className='btn btn-secondary'
                    onClick={() => {
                      safetyTagNotContinued()
                    }}
                  >
                    {intl.formatMessage({
                      id: 'INSPECTION_DELIVERY.SWAL.INSPECTION_CONFIRM.CANCEL_BUTTON',
                    })}
                  </button>
                </div>
              </div>
            )}
          </>
        </motion.div>
      )}

      {/* Erro reconhecimento facial */}
      {currentStepIndex === 14 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex bg-danger flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          <h1 className='text-white'>
            {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.TRANSACTION_UNAUTHORIZED'})}
          </h1>

          <div className='d-flex align-items-center justify-content-center gap-2'>
            <button onClick={handleClose} className='btn btn-primary'>
              Cancelar
            </button>
          </div>
        </motion.div>
      )}

      {/* Sucesso reconhecimento facial */}
      {currentStepIndex === 15 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex bg-success flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          <h1 className='text-white'>
            {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.FACE_RECOGNITION_SUCCESS'})}
          </h1>
        </motion.div>
      )}

      {/* Transação concluida */}
      {currentStepIndex === 16 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex bg-success flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          <h1 className='text-white'>
            {intl.formatMessage({id: 'TRANSACTION.BOTTOM_NAVIGATION.TRANSACTION_FINISHED'})}
          </h1>
        </motion.div>
      )}

      {/* SafetyTag encontrada */}
      {currentStepIndex === 17 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex bg-success flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          <h1 className='text-white'>
            {intl.formatMessage({id: 'INSPECTION_DELIVERY.SCAN_EQUIPMENT.FOUND'})}
          </h1>
        </motion.div>
      )}

      {/* SafetyTag não encontrada */}
      {currentStepIndex === 18 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex bg-danger flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          <h1 className='text-white'>
            {intl.formatMessage({id: 'INSPECTION_DELIVERY.SCAN_EQUIPMENT.NOT_FOUND'})}
          </h1>
        </motion.div>
      )}

      {/* Empregado encontrado */}
      {currentStepIndex === 19 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex bg-success flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          <h1 className='text-white'>
            {intl.formatMessage({id: 'INSPECTION_DELIVERY.SCAN_USER_LOCATION.FOUND'})}
          </h1>
        </motion.div>
      )}

      {/* Empregado encontrado */}
      {currentStepIndex === 20 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex bg-success flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          <h1 className='text-white'>
            {intl.formatMessage({id: 'INSPECTION_DELIVERY.SCAN_USER_LOCATION.FOUND'})}
          </h1>
        </motion.div>
      )}

      {/* Empregado não encontrado */}
      {currentStepIndex === 21 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex bg-danger flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          {error ? (
            <h1 className='text-white'>{error}</h1>
          ) : (
            <h1 className='text-white'>
              {intl.formatMessage({id: 'INSPECTION_DELIVERY.SCAN_EQUIPMENT.NOT_FOUND'})}
            </h1>
          )}
        </motion.div>
      )}

      {/* Empregado encontrado */}
      {currentStepIndex === 23 && (
        <motion.div
          initial={{opacity: 0, x: 600}}
          animate={{opacity: 1, x: 0}}
          exit={{opacity: 0, x: 600}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 h-100 d-flex bg-success flex-column align-items-center justify-content-center text-center gap-4 rounded'
        >
          <h1 className='text-white'>
            {intl.formatMessage({id: 'INSPECTION_DELIVERY.SCAN_USER_LOCATION.FOUND'})}
          </h1>
        </motion.div>
      )}
    </>
  )
}

export default InfoStep
