import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {verifyLogin} from '../core/_requests'

type RouteParams = {
  verifyToken: string
}

const initialValues = {
  newPassword: '',
  confirmNewPassword: '',
}

export function VerifyLogin() {
  const intl = useIntl()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState('')

  const {verifyToken} = useParams<RouteParams>()

  const verifyLoginSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {min: '8'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {max: '50'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~`@!#$%^&*+=\-[\]\\';,/{}|\\":<>().?])/,
        intl.formatMessage({id: 'PROFILE.SIGN_IN.FORM.LABEL.PASSWORD_RULES'})
      ),
    confirmNewPassword: Yup.string()
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}))
      .oneOf([Yup.ref('newPassword')], intl.formatMessage({id: 'VALIDATION.CONFIRM_PASSWORD'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: verifyLoginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        verifyLogin(values.newPassword, values.confirmNewPassword, verifyToken)
          .then(({data}) => {
            setLoading(false)
            if (data.error === 1) {
              setHasErrors(true)
              setErrorMessage(data.messages)
              toast.error(intl.formatMessage({id: 'TOAST.ERROR.CREATE'}))
            } else {
              setHasErrors(false)
              setErrorMessage('')
              toast.success(intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBMIT.SUCCESS'}))
              navigate('/auth/login')
            }
            setLoading(false)
          })
          .catch((error) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setErrorMessage(error.response.data.messages?.error)
            toast.error(intl.formatMessage({id: 'TOAST.SUBMIT.FAILED'}))
          })
      }, 1000)
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.TITLE'})}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errorMessage}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>
              {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.LABEL.NEW_PASSWORD'})}
            </label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder={intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.PLACEHOLDER.NEW_PASSWORD'})}
                autoComplete='off'
                {...formik.getFieldProps('newPassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                  {
                    'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.newPassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.LABEL.CONFIRM_NEW_PASSWORD'})}
          </label>
          <input
            type='password'
            placeholder={intl.formatMessage({
              id: 'AUTH.VERIFY_LOGIN.PLACEHOLDER.CONFIRM_NEW_PASSWORD',
            })}
            autoComplete='off'
            {...formik.getFieldProps('confirmNewPassword')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.confirmNewPassword && formik.errors.confirmNewPassword},
              {
                'is-valid': formik.touched.confirmNewPassword && !formik.errors.confirmNewPassword,
              }
            )}
          />
          {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmNewPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.BUTTON.SUBMIT'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.BUTTON.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_verify_login_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.BUTTON.CANCEL'})}
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
