import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  equipment_name: string
  date: string
  area_name: string
  building_name: string
  employee_fullName: string
  status: string
}

const TableColumn: React.FC<Props> = ({
  equipment_name,
  area_name,
  building_name,
  date,
  employee_fullName,
  status,
}) => {
  const intl = useIntl()

  return (
    <tr>
      <td>
        <span className='fw-bolder text-gray-800 d-block fs-6'>{equipment_name}</span>
      </td>

      <td>
        <span>
          {date?.includes(':') &&
            new Date(date).toLocaleDateString('en-GB', {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })}
          {!date?.includes(':') &&
            new Date(date + ' 00:00:00').toLocaleDateString('en-GB', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })}
        </span>
      </td>
      <td>
        {employee_fullName && <span>{employee_fullName}</span>}
        {area_name && <span>{area_name}</span>}
        {building_name && <span>{building_name}</span>}
      </td>
      <td>
        <>
          {status && status === 'process deliver' && (
            <div className='badge badge-warning fw-bolder'>
              {intl.formatMessage({id: 'TRANSACTION.TABLE.OUTCOME.IN_PROCESS'})}
            </div>
          )}
          {status && status === 'process inspection' && (
            <div className='badge badge-warning fw-bolder'>
              {intl.formatMessage({id: 'TRANSACTION.TABLE.OUTCOME.IN_PROCESS'})}
            </div>
          )}
          {status && status === 'done' && (
            <div className='badge badge-success fw-bolder'>
              {intl.formatMessage({id: 'TRANSACTION.TABLE.OUTCOME.DELIVERED'})}
            </div>
          )}
          {status && status === 'discard' && (
            <div className='badge badge-danger fw-bolder'>
              {intl.formatMessage({id: 'TRANSACTION.TABLE.OUTCOME.DISCARD'})}
            </div>
          )}
          {status && status === 're-open' && (
            <div className='badge badge-primary fw-bolder'>
              {intl.formatMessage({id: 'MAINTENANCE.TRANSACTION.STATUS.RE_OPEN'})}
            </div>
          )}
          {status && status === 'maintenance' && (
            <div className='badge badge-warning fw-bolder'>
              {intl.formatMessage({id: 'TRANSACTION.TABLE.OUTCOME.MAINTENANCE'})}
            </div>
          )}
          {status && status === 'canceled' && (
            <div className='badge badge-danger fw-bolder'>
              {intl.formatMessage({id: 'TRANSACTION.TABLE.OUTCOME.CANCEL'})}
            </div>
          )}
          {status && status === 'unauthorized' && (
            <div className='badge badge-danger fw-bolder'>
              {intl.formatMessage({id: 'TRANSACTION.TABLE.OUTCOME.UNAUTHORIZED'})}
            </div>
          )}
        </>
      </td>
    </tr>
  )
}

export {TableColumn}
