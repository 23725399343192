import React, {useState} from 'react'
import {motion} from 'framer-motion'
import {requestSubmitEmployeeChallenge} from '../core/_requests'

type Props = {
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
  challenges: never[]
}

const EmployeeChallenge: React.FC<Props> = ({setCurrentStepIndex, challenges}) => {
  const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

  const [currentIndex, setCurrentIndex] = useState(0)

  const handleSubmitChallenge = async (publicKey: string, value: any) => {
    //faz uma requisição passando a publicKey do challenge e o valor da resposta
    //se retornar sucesso altera o estado para a proxima step

    const dataSend = {
      response: value,
    }

    const data = await requestSubmitEmployeeChallenge(publicKey, dataSend)

    if (data) {
      if (data.httpStatus === '201') {
        if (currentIndex + 1 === challenges.length) {
          setCurrentStepIndex(8)
        } else {
          setCurrentIndex(currentIndex + 1)
        }
      } else {
        // Cancelar transação
        setCurrentStepIndex(14)
      }
    }
  }

  function formatData(data: any) {
    let lang = JSON.parse(String(localStorage.getItem(I18N_CONFIG_KEY)))?.selectedLang

    const idiomas_es = [
      'es',
      'es-AR',
      'es-BO',
      'es-CL',
      'es-CO',
      'es-CR',
      'es-DO',
      'es-EC',
      'es-SV',
      'es-GQ',
      'es-GT',
      'es-HN',
      'es-MX',
      'es-NI',
      'es-PA',
      'es-PY',
      'es-PE',
      'es-PH',
      'es-PR',
      'es-ES',
      'es-US',
      'es-UY',
      'es-VE',
      'pt',
      'pt-PT',
      'pt-BR',
      'pt-AO',
      'pt-MZ',
      'pt-GW',
      'pt-TL',
    ]

    if (lang) {
      if (idiomas_es.includes(lang)) {
        const dataObj = new Date(data)
        const dataFormatada = dataObj.toLocaleDateString('pt-BR', {timeZone: 'UTC'})
        const partes = dataFormatada.split('/')
        const day = partes[0]
        const month = partes[1]
        const year = partes[2]
        return `${day}/${month}/${year}`
      } else {
        const dataObj = new Date(data)
        const dataFormatada = dataObj.toLocaleDateString('pt-BR', {timeZone: 'UTC'})
        const partes = dataFormatada.split('/')
        const day = partes[0]
        const month = partes[1]
        const year = partes[2]
        return `${day}/${month}/${year}`
      }
    } else {
      const dataObj = new Date(data)
      const dataFormatada = dataObj.toLocaleDateString('pt-BR', {timeZone: 'UTC'})
      const partes = dataFormatada.split('/')
      const day = partes[0]
      const month = partes[1]
      const year = partes[2]
      return `${day}/${month}/${year}`
    }
  }

  return (
    <>
      {challenges.map((challenge: any, index: any) => (
        <motion.div
          initial={{y: 600}}
          animate={{y: 0}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className='w-100 flex-column align-items-center justify-content-center gap-8 px-4'
          style={{
            height: '78%',
            overflow: 'hidden',
            display: currentIndex === index ? 'flex' : 'none',
          }}
        >
          <h1 className='text-center'>{challenge.title}</h1>
          <div className='d-flex flex-column gap-6 align-items-center justify-content-center'>
            {JSON.parse(challenge.options).map((item: any) => (
              <button
                className='btn btn-primary'
                onClick={() => handleSubmitChallenge(challenge.publicKey, item)}
              >
                {challenge.type === 'birthday' ? formatData(item) : item}
              </button>
            ))}
          </div>
        </motion.div>
      ))}
    </>
  )
}

export default EmployeeChallenge
