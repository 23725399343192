import React, {useRef, useState, useEffect} from 'react'
import Webcam from 'react-webcam'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../components/table/loading/Loading'
import {requestCompleteTransaction, uploadTransactionNotesImage} from '../core/_requests'

type Props = {
  transactionNotes: string
  setTransactionNotes: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: Number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
}

const TransactionInsertNotes: React.FC<Props> = ({
  transactionNotes,
  setTransactionNotes,
  currentStepIndex,
  setCurrentStepIndex,
}) => {
  return (
    <>
      <div className='w-100' style={{height: '78%', overflow: 'hidden'}}>
        <div
          style={{height: '80%'}}
          className='w-100 d-flex align-items-center justify-content-center px-6'
        >
          <textarea
            style={{position: 'relative', zIndex: 999, resize: 'none'}}
            className='form-control bg-transparent'
            cols={30}
            rows={10}
            value={transactionNotes}
            onChange={(e) => setTransactionNotes(e.target.value)}
          ></textarea>
        </div>
        <div
          style={{height: '65%', position: 'absolute', top: '0', padding: '24px'}}
          className='w-100 d-flex align-items-end justify-content-end'
        >
          <div
            //style={{pointerEvents: 'all'}}
            onClick={() => setCurrentStepIndex(11)}
            className='w-60px h-60px d-flex align-items-center justify-content-center bg-success rounded-circle fixed cursor-pointer'
          >
            <span style={{fontSize: '30px'}} className='text-white fw-bold'>
              ✓
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionInsertNotes
