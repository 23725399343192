/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {AuthLayout} from './AuthLayout'
import ConfirmAccount from './ConfirmAccount'
import {AcceptIntegrate} from './components/AcceptIntegrate'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import RegisterAccount from './components/RegisterAccount'
import {ResetPassword} from './components/ResetPassword'
import {VerifyLogin} from './components/VerifyLogin'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='/login' element={<Login />} />
      <Route path='/registration' element={<RegisterAccount />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password/:resetToken' element={<ResetPassword />} />
      <Route path='/verify-login/:verifyToken' element={<VerifyLogin />} />
      <Route path='/accept-integrate/:tokenIntegrate' element={<AcceptIntegrate />} />
      <Route path='/confirm-email/:token' element={<ConfirmAccount />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export default AuthPage
