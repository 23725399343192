import clsx from 'clsx'
import {ErrorMessage, Field} from 'formik'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {useDebounce} from '../../../../../_metronic/helpers'
import {
  reactSelectStyles,
  theme,
} from '../../../risk/hazard-management/control-measure-list/controlMeasure-edit-modal/ControlMeasureEquipmentEditModalForm'
import {getAreas} from '../../../settings/company/components/location/area-list/core/_requests'
import {getBranches} from '../../../settings/company/components/location/branch-list/core/_requests'
import {getBuildings} from '../../../settings/company/components/location/building-list/core/_requests'
import {getCountries} from '../../../settings/company/components/location/country-list/core/_requests'
import {useListView} from '../../workflow/Workflow-list/core/ListViewProvider'
import {getActivities, getActivityList, getWorkflowByPublicKey} from '../core/_requests'

export const Workflow = (props: any) => {
  const [parentOptions, setParentOptions] = useState([])
  const [activityOptions, setActivityOptions] = useState([])
  const intl = useIntl()

  const {itemIdForUpdate} = useListView()

  useEffect(() => {
    async function getParentOptions() {
      const requests = {
        company_country: async () => await getCountries(''),
        branch: async () => await getBranches(''),
        area: async () => await getAreas(''),
        building: async () => await getBuildings(''),
      }

      const {result} = (await requests[
        props.values.table as 'company_country' | 'branch' | 'area' | 'building'
      ]()) as any

      setParentOptions(result)
    }

    if (props.values.table === '') return
    getParentOptions()
    // eslint-disable-next-line
  }, [props.values.table])

  useEffect(() => {
    const getWorkflow = async () => {
      const {data} = await getWorkflowByPublicKey(itemIdForUpdate)

      if (data) {
        props.setFieldValue('publicKey', data.result.publicKey)
        props.setFieldValue('table', data.result.table)
        props.setFieldValue('table_publicKey', data.result.table_publicKey)
        props.setFieldValue('activity_publicKey', data.result.activity_publicKey)
        setActivity({
          value: data.result.activity_publicKey,
          label: data.result.activity_description,
        })

        if (data.result?.genericActivity === '1') {
          props.setFieldValue('genericActivity', true)
        } else {
          props.setFieldValue('genericActivity', false)
        }
        props.setFieldValue('description', data.result.description)
      }
    }

    getWorkflow()
  }, [])

  // useEffect(() => {
  //   async function getActivityOptions() {
  //     const {result} = (await getActivityList()) as any

  //     setActivityOptions(result || [])
  //   }

  //   if (!props.values.genericActivity) {
  //     getActivityOptions()
  //     props.setFieldValue('activity_publicKey', '')
  //   } else props.setFieldValue('activity_publicKey', 'none')
  // }, [props.values.genericActivity])

  useEffect(() => {
    if (!props.values.genericActivity) {
      getActivityOptions('')
      props.setFieldValue('activity_publicKey', '')
    } else props.setFieldValue('activity_publicKey', 'none')
  }, [props.values.genericActivity])

  const [searchActivity, setSearchActivity]: any = useState()
  const [activity, setActivity]: any = useState()

  async function getActivityOptions(search: string) {
    const {data} = await getActivities(search)
    const options =
      data?.result?.map(({publicKey, description}: {publicKey: string; description: string}) => ({
        value: publicKey,
        label: description,
      })) || []
    setActivityOptions(options)
  }

  const handleChangeActivity = (activity_publicKey: {value: string; label: string}) => {
    if (activity_publicKey?.value !== '') {
      setActivity(activity_publicKey)
      props.setFieldValue('activity_publicKey', activity_publicKey.value)
      getActivityOptions('')
    } else setActivity(undefined)
  }

  const activityFilterOptions = (
    candidate: {label: string; value: string; data: any},
    input: string
  ) => {
    if (input) {
      setSearchActivity(input)
    }
    return true
  }

  const debouncedActivitySearch = useDebounce(searchActivity, 150)
  // Effect for API call
  useEffect(() => {
    if (debouncedActivitySearch !== undefined && searchActivity !== undefined) {
      getActivityOptions(debouncedActivitySearch)
    }
  }, [debouncedActivitySearch])

  return (
    <div className='w-100'>
      <div className='row mb-5'>
        {/* Table */}
        <div className='fv-row mb-5 col-md-6'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>
              {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.LABEL.LOCATION_TYPE'})}
            </span>
          </label>

          <div>
            <Field
              as='select'
              className={clsx('form-control form-control-lg form-select', {
                'is-invalid': props.touched.table && props.errors.table,
              })}
              placeholder={intl.formatMessage({
                id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.PLACEHOLDER.LOCATION_TYPE',
              })}
              name='table'
            >
              <option value='' disabled>
                {intl.formatMessage({
                  id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.PLACEHOLDER.LOCATION_TYPE',
                })}
              </option>
              <option value='company_country'>
                {intl.formatMessage({
                  id: 'HAZARD_MANAGEMENT.MODAL.ENVIRONMENT.FORM.OPTION.COUNTRY',
                })}
              </option>
              <option value='branch'>
                {intl.formatMessage({
                  id: 'HAZARD_MANAGEMENT.MODAL.ENVIRONMENT.FORM.OPTION.BRANCH',
                })}
              </option>
              <option value='building'>
                {intl.formatMessage({
                  id: 'HAZARD_MANAGEMENT.MODAL.ENVIRONMENT.FORM.OPTION.BUILDING',
                })}
              </option>
              <option value='area'>
                {intl.formatMessage({
                  id: 'HAZARD_MANAGEMENT.MODAL.ENVIRONMENT.FORM.OPTION.AREA',
                })}
              </option>
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='table' />
            </div>
          </div>
        </div>
        {/* Table */}

        {/* Table PublicKey */}
        <div className='fv-row mb-5 col-md-6'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>
              {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.LABEL.LOCATION'})}
            </span>
          </label>

          <div className='position-relative'>
            <Field
              as='select'
              className={clsx('form-control form-control-lg form-select', {
                'is-invalid': props.touched.table_publicKey && props.errors.table_publicKey,
              })}
              placeholder={intl.formatMessage({
                id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.PLACEHOLDER.LOCATION',
              })}
              name='table_publicKey'
              defaultValue=''
              disabled={parentOptions.length === 0}
            >
              <option value='' disabled>
                {intl.formatMessage({
                  id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.PLACEHOLDER.LOCATION',
                })}
              </option>
              {parentOptions.map((parent: any, index: number) => (
                <option key={index} value={parent.publicKey}>
                  {parent.area_name ||
                    parent.building_name ||
                    parent.branch_name ||
                    parent.country_name}
                </option>
              ))}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='table_publicKey' />
            </div>
          </div>
        </div>
        {/* Table PublicKey */}

        {/* Generic Activity */}
        <div className='fv-row mb-5 col-md-6'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>
              {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.LABEL.ACTIVITY_TYPE'})}
            </span>
          </label>

          <div className='position-relative'>
            <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
              <Field type='checkbox' className='form-check-input' name='genericActivity' />
              <span className='ms-4'>
                {!props.values.genericActivity
                  ? intl.formatMessage({
                      id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.LABEL.SPECIFIC_ACTIVITY',
                    })
                  : intl.formatMessage({
                      id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.LABEL.GENERIC_ACTIVITY',
                    })}
              </span>
            </label>
            <div className='text-danger mt-2'>
              <ErrorMessage name='genericActivity' />
            </div>
          </div>
        </div>
        {/* Generic Activity */}

        {/* Activity */}
        {!props.values.genericActivity && (
          <div className='fv-row mb-5 col-md-6'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span className='required'>
                {intl.formatMessage({id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.LABEL.ACTIVITY'})}
              </span>
            </label>

            <div className='position-relative'>
              {/* <Field
                as='select'
                className={clsx('form-control form-control-lg form-select', {
                  'is-invalid': props.touched.activity_publicKey && props.errors.activity_publicKey,
                })}
                placeholder={intl.formatMessage({
                  id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.PLACEHOLDER.ACTIVITY',
                })}
                name='activity_publicKey'
                defaultValue=''
                disabled={activityOptions.length === 0}
              >
                <option value='' selected disabled>
                  {intl.formatMessage({
                    id: 'ACCESS.WORKFLOW.MODAL.LEVEL.FORM.PLACEHOLDER.ACTIVITY',
                  })}
                </option>
                {activityOptions?.map((activity: any, index: number) => (
                  <option key={index} value={activity.publicKey}>
                    {activity.description}
                  </option>
                ))}
              </Field> */}

              <Select
                filterOption={activityFilterOptions}
                isClearable={true}
                isSearchable={true}
                name='productType_publicKey'
                options={activityOptions}
                onChange={(value: any) => handleChangeActivity(value)}
                placeholder={intl.formatMessage({id: 'RISK_ANALYSIS.MODAL.PLACEHOLDER.ACTIVITY'})}
                isDisabled={activityOptions?.length === 0}
                value={!activity ? '' : activity}
                theme={theme}
                styles={reactSelectStyles}
                className='form-control form-control-lg'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='activity_publicKey' />
              </div>
            </div>
          </div>
        )}
        {/* Activity */}

        {/* Description */}
        <div className={`fv-row mb-5 ${!props.values.genericActivity ? 'col-md-12' : 'col-md-6'}`}>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>
              {intl.formatMessage({id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.LABEL.NAME'})}
            </span>
          </label>

          <div className='position-relative'>
            <Field
              type='text'
              className={clsx('form-control form-control-lg', {
                'is-invalid': props.touched.description && props.errors.description,
              })}
              placeholder={intl.formatMessage({
                id: 'HAZARD_MANAGEMENT.MODAL.ACTIVITY.FORM.PLACEHOLDER.NAME',
              })}
              name='description'
            />

            <div className='text-danger mt-2'>
              <ErrorMessage name='description' />
            </div>
          </div>
        </div>
        {/* Description */}
      </div>
    </div>
  )
}
