import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../_metronic/helpers'
import {PendingIssues} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const APPROVED_WORK_AUTHORIZATION = `${API_ALPHA}/customer/access/workAuthorizationIssue`
const EMPLOYEE_LIST = `${API_ALPHA}/customer/settings/employee`
const ACTIVITY_LIST = `${API_ALPHA}/customer/risk/hazardManagement?filter_type=activity`

const getPendingIssues = (query: string): Promise<any> => {
  return axios
    .get(`${APPROVED_WORK_AUTHORIZATION}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}

export const getPendingIssuesWithSearch = (query: string, search: string): Promise<any> => {
  return axios
    .get(`${APPROVED_WORK_AUTHORIZATION}?${query}&filter_employee_publickey=${search}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getEmployeeList = (search: string) => {
  return axios.get(`${EMPLOYEE_LIST}?offset=1&limit=10&search=${search}`)
}

const getActivityList = (search: string) => {
  return axios.get(`${ACTIVITY_LIST}?offset=1&limit=10&search=${search}`)
}

const getPendingIssuesById = (publicKey: PublicKey): Promise<PendingIssues | undefined> => {
  return axios
    .get(`${APPROVED_WORK_AUTHORIZATION}/${publicKey}`)
    .then((response: AxiosResponse<Response<PendingIssues>>) => response.data)
    .then((response: Response<PendingIssues>) => response.result)
}

const createPendingIssuesForEnvironment = (
  environment: PendingIssues
): Promise<PendingIssues | undefined> => {
  return axios
    .post(APPROVED_WORK_AUTHORIZATION, environment)
    .then((response: AxiosResponse<Response<PendingIssues>>) => response.data)
    .then((response: Response<PendingIssues>) => response as Response<PendingIssues>['data'])
}

const createPendingIssues = (
  hazard_management: PendingIssues
): Promise<PendingIssues | undefined> => {
  return axios
    .post(APPROVED_WORK_AUTHORIZATION, hazard_management)
    .then((response: AxiosResponse<Response<PendingIssues>>) => response.data)
    .then((response: Response<PendingIssues>) => response as Response<PendingIssues>['data'])
}

const updatePendingIssues = (
  hazard_management: PendingIssues
): Promise<PendingIssues | undefined> => {
  return axios
    .put(`${APPROVED_WORK_AUTHORIZATION}/${hazard_management.publicKey}`, hazard_management)
    .then((response: AxiosResponse<Response<PendingIssues>>) => response.data)
    .then((response: Response<PendingIssues>) => response as Response<PendingIssues>['data'])
}

const deletePendingIssues = (hazard_managementId: PublicKey): Promise<void> => {
  return axios
    .delete(`${APPROVED_WORK_AUTHORIZATION}/${hazard_managementId}`)
    .then((res) => res.data)
}

const deleteSelectedPendingIssues = (
  hazard_managementPublicKey: Array<PublicKey>
): Promise<void> => {
  const requests = hazard_managementPublicKey.map((publicKey) =>
    axios.delete(`${APPROVED_WORK_AUTHORIZATION}/${publicKey}`)
  )
  return axios.all(requests).then(() => {})
}

export {
  deletePendingIssues,
  deleteSelectedPendingIssues,
  getPendingIssuesById,
  createPendingIssues,
  updatePendingIssues,
  getPendingIssues,
  createPendingIssuesForEnvironment,
  getEmployeeList,
  getActivityList,
}
