import {createContext, FC, useContext, useMemo, useState} from 'react'
import {
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  initialListView,
  ListViewContextProps,
  PublicKey,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<PublicKey>>(initialListView.selected)
  const [authSelected, setAuthSelected]: any = useState<any>([])
  const [itemIdForUpdate, setItemIdForUpdate] = useState<PublicKey>(initialListView.itemIdForUpdate)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  const workAuthorization: any = {}

  const handleAddAuthPublicKey = (publicKey: any, value: boolean) => {
    if (value) {
      //workAuthorization[`${publicKey}`] = "Approved"
      setAuthSelected((current: any) => [
        ...current,
        {
          key: publicKey,
          value: 'Approved',
        },
      ])
    } else {
      setAuthSelected((current: any) => [
        ...current,
        {
          key: publicKey,
          value: 'Denied',
        },
      ])
    }

    // var object = arr.reduce(
    //   (obj: any, item: any) => Object.assign(obj, { [item.key]: item.value }), {});
  }

  return (
    <ListViewContext.Provider
      value={{
        selected,
        authSelected,
        handleAddAuthPublicKey,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (publicKey: PublicKey) => {
          groupingOnSelect(publicKey, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
