import {KTCard} from '../../../../../_metronic/helpers/components/KTCard'
import {HazardManagementListHeader} from './components/header/HazardManagementListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {RejecteddWorkAuthorizationTable} from './table/ApprovedWorkAuthorizationTable'

const RejectedWorkAuthorizationList = () => {
  return (
    <>
      <KTCard>
        <HazardManagementListHeader />
        <RejecteddWorkAuthorizationTable />
      </KTCard>
    </>
  )
}

const RejectedWorkAuthorizationListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RejectedWorkAuthorizationList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RejectedWorkAuthorizationListWrapper}
