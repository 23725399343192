import axios from 'axios'
import {ICreateAccount} from '../components/Registration'
import {UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const API_URL2 = process.env.REACT_APP_API2_URL

export const REGISTER_URL = `${API_URL2}/general/newAccount`
export const LOGIN_URL = `${API_URL}/v2/auth/signIn`
export const REFRESH_TOKEN = `${API_URL}/v2/auth/refresh`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL2}/general/logged/getUserByToken`
export const GET_FEATURES_USER = `${API_URL2}/general/logged/getFeaturesUser`
export const GET_MODULES_USER = `${API_URL2}/general/logged/getModulesCompany`

export const REQUEST_FORGOT_PASSWORD = `${API_URL}/auth/forgotPassword`
export const REQUEST_RESET_PASSWORD = `${API_URL}/auth/resetPassword`
export const REQUEST_VERIFY_LOGIN = `${API_URL}/auth/verifyLogin`

export const GET_COMPANY = `${API_URL2}/customer/listCompany`
export const GET_COUNTRY = `${API_URL2}/general/getCountry`
export const GET_BUSINESS_SEGMENT = `${API_URL2}/general/getBusinessSegment`
export const REQUEST_CONFIRMED_ACCEPTED_INTEGRATE = `${API_URL2}/general/confirmedIntegrateAnother`
export const CONFIRM_EMAIL_URL = `${API_URL}/v2/auth/confirmEmail`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function register(data: Array<ICreateAccount>) {
  return axios.post(REGISTER_URL, data)
}

export function refresh(refreshToken: any) {
  return axios.post(REFRESH_TOKEN, {refreshToken})
}

// Server should return AuthModel
export function resetPassword(
  newPassword: string,
  confirmNewPassword: string,
  tokenResetPassword: any
) {
  return axios.post(REQUEST_RESET_PASSWORD, {newPassword, confirmNewPassword, tokenResetPassword})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{result: boolean}>(REQUEST_RESET_PASSWORD, {
    params: {
      email: email,
    },
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function getUserFeatures() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<any>(GET_FEATURES_USER)
}

export function getUserModule() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<any>(GET_MODULES_USER)
}

export function forgotPassword(data: any) {
  return axios.post(REQUEST_FORGOT_PASSWORD, data)
}

export function verifyLogin(
  newPassword: string,
  confirmNewPassword: string,
  tokenVerifyLogin: any
) {
  return axios.post(REQUEST_VERIFY_LOGIN, {newPassword, confirmNewPassword, tokenVerifyLogin})
}

export function acceptIntegrate(tokenIntegrate: any) {
  return axios.post(REQUEST_CONFIRMED_ACCEPTED_INTEGRATE, {
    tokenIntegrateAnotherCompany: tokenIntegrate,
  })
}

export function listCompany() {
  return axios.get(GET_COMPANY)
}

export function listCountry() {
  return axios.get(GET_COUNTRY)
}

export function listBusinessSegments() {
  return axios.get(GET_BUSINESS_SEGMENT)
}

export function confirmAccount(token: string) {
  return axios
    .post(CONFIRM_EMAIL_URL, {
      tokenConfirmEmail: token,
    })
    .then((d) => d.data)
    .catch((error) => error.response.data)
}
