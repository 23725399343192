import {Column} from 'react-table'
import {ResolvedIssues} from '../../core/_models'
import {ResolvedIssuesInfoCell} from './ResolvedIssuesInfoCell'
import {ResolvedIssuesCustomHeader} from './ResolvedIssuesCustomHeader'
import {ResolvedIssuesTableCell} from './ResolvedIssuesTableCell'

const resolvedIssuesColumns: ReadonlyArray<Column<ResolvedIssues>> = [
  {
    Header: (props) => (
      <ResolvedIssuesCustomHeader
        tableProps={props}
        title='ACCESS.PENDING_ISSUES.EMPLOYEE'
        className='min-w-125px'
      />
    ),
    accessor: 'employee_name',
  },
  {
    Header: (props) => (
      <ResolvedIssuesCustomHeader
        tableProps={props}
        title='ACCESS.PENDING_ISSUES.ACTIVITY'
        className='min-w-125px'
      />
    ),
    id: 'activity_info',
    Cell: ({...props}) => <ResolvedIssuesInfoCell resolved_issues={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ResolvedIssuesCustomHeader
        tableProps={props}
        title='ACCESS.PENDING_ISSUES.TYPE'
        className='min-w-125px'
      />
    ),
    id: 'table',
    Cell: ({...props}) => (
      <ResolvedIssuesTableCell resolved_issues_table={props.data[props.row.index].table} />
    ),
  },
  {
    Header: (props) => (
      <ResolvedIssuesCustomHeader
        tableProps={props}
        title='ACCESS.PENDING_ISSUES.CONTROL_MEASURE_NAME'
        className='min-w-125px'
      />
    ),
    accessor: 'controlMeasure_name',
  },
  {
    Header: (props) => (
      <ResolvedIssuesCustomHeader
        tableProps={props}
        title='ACCESS.PENDING_ISSUES.REQUESTED_BY_NAME'
        className='min-w-125px'
      />
    ),
    accessor: 'requestedBy_user_fullName',
  },
]

export {resolvedIssuesColumns}
