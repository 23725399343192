/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {useLayout} from '../../core'

const Toolbar1: FC = () => {
  const {classes} = useLayout()

  return (
    <div className={`toolbar py-lg-5`} id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      ></div>
    </div>
  )
}

export {Toolbar1}
