import React from 'react'
import {useIntl} from 'react-intl'

type Props = {
  status: any
}

//Under Approval
//Pending
//Approved
//Denied

const WorkAuthorizationStatusCell: React.FC<Props> = ({status}) => {
  const intl = useIntl()

  return (
    <div className='text-center'>
      {status === 'Pending' && (
        <span className='badge badge-primary'>
          {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.STATUS.PENDING'})}
        </span>
      )}
      {status === 'Approved' && (
        <span className='badge badge-success'>
          {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.STATUS.APPROVED'})}
        </span>
      )}
      {status === 'Denied' && (
        <span className='badge badge-danger'>
          {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.STATUS.DENIED'})}
        </span>
      )}
      {status === 'Under Approval' && (
        <span className='badge badge-info'>
          {intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION_LIST.STATUS.UNDER_APPROVAL'})}
        </span>
      )}
    </div>
  )
}

export default WorkAuthorizationStatusCell
