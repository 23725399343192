import {motion} from 'framer-motion'
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../components/table/loading/Loading'
import {requestSubmitStep} from '../core/_requests'

type Props = {
  inspectionsSteps: any
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<Number>>
  transactionPublicKey: string
  currentIndex: number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
  typeTransaction: string
}

const Inspections: React.FC<Props> = ({
  inspectionsSteps,
  setCurrentStepIndex,
  currentIndex,
  setCurrentIndex,
  typeTransaction,
}) => {
  const [x, setX] = React.useState(0)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (inspectionsSteps.length === 0) {
      if (typeTransaction === 'employee') {
        setCurrentStepIndex(9)
      } else {
        setCurrentStepIndex(11)
      }
    }
  }, [])

  const handleSubmitStep = async (publicKey: string, type: string) => {
    setIsLoading(true)

    const dataSend = {
      result: type,
    }

    const data = await requestSubmitStep(publicKey, dataSend)

    if (data.httpStatus === '201') {
      if (currentIndex + 1 === inspectionsSteps.length) {
        if (typeTransaction === 'employee') {
          setCurrentStepIndex(9)
          setIsLoading(false)
        } else {
          setCurrentStepIndex(11)
          setIsLoading(false)
        }
      } else {
        setCurrentIndex(currentIndex + 1)
        setX(0)
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <div
        style={{height: '68%', overflow: 'hidden'}}
        className='w-100 d-flex flex-column align-items-center justify-content-center'
      >
        {isLoading === true && <Loading />}
        <>
          {inspectionsSteps.map((step: any, index: any) => {
            return (
              <>
                {currentIndex === index && (
                  <>
                    <div
                      className='w-100 h-100 d-flex align-items-center justify-content-center bg-primary py-4'
                      style={{zIndex: 5}}
                    >
                      <h1 className='text-white'>{step.stepDescription}</h1>
                    </div>

                    <div
                      className='w-100 d-flex justify-content-between px-5 position-absolute'
                      style={{zIndex: 11, top: '60%'}}
                    >
                      {x < 0 ? (
                        <div
                          style={{pointerEvents: 'all', marginBottom: x * -1}}
                          onClick={() => {
                            handleSubmitStep(step.publicKey, 'fail')
                          }}
                          className='w-60px h-60px d-flex align-items-center justify-content-center bg-danger rounded-circle fixed'
                        >
                          <span style={{fontSize: '30px'}} className='text-white fw-bold'>
                            X
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{pointerEvents: 'all'}}
                          onClick={() => {
                            handleSubmitStep(step.publicKey, 'fail')
                          }}
                          className='w-60px h-60px d-flex align-items-center justify-content-center bg-danger rounded-circle fixed'
                        >
                          <span style={{fontSize: '30px'}} className='text-white fw-bold'>
                            X
                          </span>
                        </div>
                      )}

                      {x > 0 ? (
                        <div
                          style={{pointerEvents: 'all', marginBottom: x}}
                          onClick={() => {
                            handleSubmitStep(step.publicKey, 'pass')
                          }}
                          className='w-60px h-60px d-flex align-items-center justify-content-center bg-success rounded-circle fixed'
                        >
                          <span style={{fontSize: '30px'}} className='text-white fw-bold'>
                            ✓
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{pointerEvents: 'all'}}
                          onClick={() => {
                            handleSubmitStep(step.publicKey, 'pass')
                          }}
                          className='w-60px h-60px d-flex align-items-center justify-content-center bg-success rounded-circle fixed'
                        >
                          <span style={{fontSize: '30px'}} className='text-white fw-bold'>
                            ✓
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                )}

                <motion.img
                  src={
                    process.env.REACT_APP_CDN_URL +
                    `${step.company_publicKey}` +
                    `/equipment-transaction/inspection-steps/` +
                    `${step.publicKey}`
                  }
                  onError={({currentTarget}) => {
                    currentTarget.src = toAbsoluteUrl('/media/svg/files/blank-image.svg')
                  }}
                  drag='x'
                  dragConstraints={{left: 0, right: 0}}
                  onDrag={(event, info) => {
                    setX(info.offset.x)
                  }}
                  onDragEnd={(event: any, info: any) => {
                    if (info.offset.x > 300) {
                      handleSubmitStep(step.publicKey, 'pass')
                    } else if (info.offset.x < -300) {
                      handleSubmitStep(step.publicKey, 'fail')
                    } else {
                      setX(0)
                    }
                  }}
                  transition={{type: 'spring', stiffness: 50, damping: 10}}
                  style={{
                    bottom: 0,
                    width: '100%',
                    backgroundColor: '#fff',
                    display: currentIndex !== index ? 'none' : 'flex',
                    originX: 0.5,
                    originY: 1,
                    rotate: x / 10,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    zIndex: 10,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                />
              </>
            )
          })}
          {inspectionsSteps && (
            <>
              <img
                src={
                  process.env.REACT_APP_CDN_URL +
                  `${inspectionsSteps[currentIndex + 1]?.company_publicKey}` +
                  `/equipment-transaction/inspection-steps/` +
                  `${inspectionsSteps[currentIndex + 1]?.publicKey}`
                }
                alt=''
                style={{
                  position: 'absolute',
                  bottom: '32%',
                  width: '100%',
                  display: 'block',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </>
          )}
        </>
      </div>
    </>
  )
}

export default Inspections
