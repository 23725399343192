import {KTCard} from '../../../../_metronic/helpers/components/KTCard'
import {PendingIssuesListHeader} from './components/header/PendingIssuesListHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PendingIssuesTable} from './table/PendingIssuesTable'

const PendingIssuesList = () => {
  return (
    <>
      <KTCard>
        <PendingIssuesListHeader />
        <PendingIssuesTable />
      </KTCard>
    </>
  )
}

const PendingIssuesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PendingIssuesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PendingIssuesListWrapper}
