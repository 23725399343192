import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, QUERIES, useDebounce} from '../../../../_metronic/helpers'
import {SearchSelectCustom} from '../../../components/form/CustomSelect'
import {
  getActivityList,
  getEmployeeList,
  getLocationList,
  makeNewWorkAuthorization,
} from './core/_requests'
import {toast} from 'react-toastify'
import {useQueryClient} from 'react-query'
import {useQueryResponse} from '../workAuthorization/WorkAuthorization-list/core/QueryResponseProvider'
import Select from 'react-select'
import {
  reactSelectStyles,
  theme,
} from '../../risk/hazard-management/control-measure-list/controlMeasure-edit-modal/ControlMeasureEquipmentEditModalForm'

type Props = {
  setStateNewAuthorization: any
}

const NewAuthorizationModal: React.FC<Props> = ({setStateNewAuthorization}) => {
  const [employeeFilterOptions, setEmployeeFilterOptions]: any = useState(null)
  const [activityFilterOptions, setActivityFilterOptions]: any = useState(null)
  const [locationType, setLocationType]: any = useState(null)
  const [locationList, setLocationList]: any = useState(null)

  const [employeePublicKey, setEmployeePublicKey]: any = useState(null)
  const [activityPublicKey, setActivityPublicKey]: any = useState(null)
  const [tablePublicKey, setTablePublicKey]: any = useState(null)
  const [dueDate, setDueDate]: any = useState(null)

  const date = new Date()
  const todayDateConverted = [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
  ].join('-')

  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  useEffect(() => {
    document.body.classList.add('modal-open')
    getEmployeeListDefine('')
    getActivityListDefine('')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    getLocationListDefine()
  }, [locationType])

  const intl = useIntl()

  const getActivityListDefine = async (search: any) => {
    const activityData = await getActivityList(search)

    const ActivityOptions = activityData?.data?.result?.map(
      ({publicKey, description}: {publicKey: string; description: string}) => ({
        value: publicKey,
        label: description,
      })
    )
    setActivityFilterOptions(ActivityOptions)
  }

  const getEmployeeListDefine = async (search: any) => {
    const employeeData = await getEmployeeList(search)

    const EmployeeOptions = employeeData?.data?.result?.map(
      ({publicKey, employee_fullName}: {publicKey: string; employee_fullName: string}) => ({
        value: publicKey,
        label: employee_fullName,
      })
    )
    setEmployeeFilterOptions(EmployeeOptions)
  }

  const getLocationListDefine = async () => {
    const locationList = await getLocationList(locationType)

    if (locationType === 'building') {
      const LocationOptions = locationList?.data?.result?.map(
        ({publicKey, building_name}: {publicKey: string; building_name: string}) => ({
          value: publicKey,
          label: building_name,
        })
      )
      setLocationList(LocationOptions)
    }
    if (locationType === 'branch') {
      const LocationOptions = locationList?.data?.result?.map(
        ({publicKey, branch_name}: {publicKey: string; branch_name: string}) => ({
          value: publicKey,
          label: branch_name,
        })
      )
      setLocationList(LocationOptions)
    }
    if (locationType === 'area') {
      const LocationOptions = locationList?.data?.result?.map(
        ({publicKey, area_name}: {publicKey: string; area_name: string}) => ({
          value: publicKey,
          label: area_name,
        })
      )
      setLocationList(LocationOptions)
    }
    if (locationType === 'company_country') {
      const LocationOptions = locationList?.data?.result?.map(
        ({publicKey, country_name}: {publicKey: string; country_name: string}) => ({
          value: publicKey,
          label: country_name,
        })
      )
      setLocationList(LocationOptions)
    }
  }

  const onSubmitForm = async (e: any) => {
    e.preventDefault()

    if (
      employeePublicKey.value !== null &&
      activityPublicKey.value !== null &&
      tablePublicKey !== null &&
      dueDate !== null
    ) {
      const {data} = await makeNewWorkAuthorization(
        employeePublicKey.value,
        activityPublicKey.value,
        locationType,
        tablePublicKey,
        dueDate
      )

      if (data.error === 0) {
        toast.success(intl.formatMessage({id: 'TOAST.SUCCESS.CREATE'}))
        queryClient.invalidateQueries([`${QUERIES.WORK_AUTHORIZATION}-${query}`])
        setStateNewAuthorization(undefined)
      } else {
        toast.error(data.messages)
      }
    }
  }

  const [searchEmployee, setSearchEmployee]: any = useState()
  const [searchActivity, setSearchActivity]: any = useState()

  const employeeFilter = (candidate: {label: string; value: string; data: any}, input: string) => {
    if (input) {
      setSearchEmployee(input)
    }
    return true
  }

  const debouncedManufacturerSearch = useDebounce(searchEmployee, 150)

  // Effect for API call
  useEffect(() => {
    if (debouncedManufacturerSearch !== undefined && searchEmployee !== undefined) {
      getEmployeeListDefine(debouncedManufacturerSearch)
    }
  }, [debouncedManufacturerSearch])

  const activityFilter = (candidate: {label: string; value: string; data: any}, input: string) => {
    if (input) {
      setSearchActivity(input)
    }
    return true
  }

  const debouncedActivitySearch = useDebounce(searchActivity, 150)
  // Effect for API call
  useEffect(() => {
    if (debouncedActivitySearch !== undefined && searchActivity !== undefined) {
      getActivityListDefine(debouncedActivitySearch)
    }
  }, [debouncedActivitySearch])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_inspectionStep'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          {/* begin::Modal content */}
          <form onSubmit={onSubmitForm} className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>
                {intl.formatMessage({id: 'ACCESS.NEW_WORK_AUTHORIZATION.TITLE'})}
              </h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-inspection-steps-modal-action='close'
                onClick={() => setStateNewAuthorization(undefined)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body mx-5 mx-xl-15 my-7'>
              <div className='d-flex align-items-center justify-content-center gap-4'>
                <div className='w-100'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>
                      {intl.formatMessage({id: 'ACCESS.NEW_WORK_AUTHORIZATION.SELECT_EMPLOYEE'})}
                    </span>
                  </label>

                  <Select
                    filterOption={employeeFilter}
                    isClearable={true}
                    isSearchable={true}
                    name='manufacturer_publicKey'
                    options={employeeFilterOptions}
                    value={!employeePublicKey ? '' : employeePublicKey}
                    onChange={(value: any) => {
                      setEmployeePublicKey(value)
                      getEmployeeListDefine('')
                    }}
                    theme={theme}
                    styles={reactSelectStyles}
                    placeholder={intl.formatMessage({id: 'ACCESS.TABLES_FILTER.SELECT_EMPLOYEE'})}
                    className='form-control form-control-lg'
                    isLoading={employeeFilterOptions?.length === 0}
                  />

                  {/* <Select
                    onChange={(value: string) => setEmployeePublicKey(value)}
                    //value={props.values.manufacturer_publicKey}
                    options={employeeFilterOptions}
                    isDisabled={employeeFilterOptions === null}
                    className={`form-control ${employeePublicKey !== null && 'is-valid'}`}
                  /> */}
                </div>
                <div className='w-100'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>
                      {intl.formatMessage({id: 'ACCESS.NEW_WORK_AUTHORIZATION.SELECT_ACTIVITY'})}
                    </span>
                  </label>
                  <Select
                    filterOption={activityFilter}
                    isClearable={true}
                    isSearchable={true}
                    name='activity_publicKey'
                    options={activityFilterOptions}
                    value={!activityPublicKey ? '' : activityPublicKey}
                    onChange={(value: any) => {
                      setActivityPublicKey(value)
                      getActivityListDefine('')
                    }}
                    theme={theme}
                    styles={reactSelectStyles}
                    placeholder={intl.formatMessage({
                      id: 'RISK_ANALYSIS.MODAL.PLACEHOLDER.ACTIVITY',
                    })}
                    className='form-control form-control-lg'
                    isLoading={activityFilterOptions?.length === 0}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-center gap-4 mt-4'>
                <div className='w-100'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>
                      {intl.formatMessage({
                        id: 'HAZARD_MANAGEMENT.MODAL.ENVIRONMENT.FORM.PLACEHOLDER.PARENT_TYPE',
                      })}
                    </span>
                  </label>
                  <select
                    required
                    onChange={(e: any) => setLocationType(e.target.value)}
                    className={`form-control form-select ${locationType !== null && 'is-valid'}`}
                  >
                    <option value='' disabled selected>
                      {intl.formatMessage({
                        id: 'HAZARD_MANAGEMENT.MODAL.ENVIRONMENT.FORM.PLACEHOLDER.PARENT_TYPE',
                      })}
                    </option>
                    <option value='company_country'>
                      {intl.formatMessage({
                        id: 'COMPANY.TAB.COUNTRY.TITLE',
                      })}
                    </option>
                    <option value='branch'>
                      {intl.formatMessage({
                        id: 'COMPANY.TAB.BRANCH.TITLE',
                      })}
                    </option>
                    <option value='building'>
                      {intl.formatMessage({
                        id: 'COMPANY.TAB.BUILDING.TITLE',
                      })}
                    </option>
                    <option value='area'>
                      {intl.formatMessage({
                        id: 'COMPANY.TAB.AREA.TITLE',
                      })}
                    </option>
                  </select>
                </div>

                <div className='w-100'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>
                      {intl.formatMessage({
                        id: 'RISK_ANALYSIS.MODAL.PLACEHOLDER.LOCATION',
                      })}
                    </span>
                  </label>
                  <select
                    onChange={(e: any) => setTablePublicKey(e.target.value)}
                    required
                    disabled={locationList === null}
                    className={`form-control form-control-lg form-select ${
                      tablePublicKey !== null && 'is-valid'
                    }`}
                  >
                    <option value='' disabled selected>
                      {intl.formatMessage({
                        id: 'RISK_ANALYSIS.MODAL.PLACEHOLDER.LOCATION',
                      })}
                    </option>
                    {locationList?.map((item: any) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='w-100 mt-4'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                  <span className='required'>
                    {intl.formatMessage({id: 'ACCESS.NEW_WORK_AUTHORIZATION.DUE_DATE'})}
                  </span>
                </label>
                <input
                  type='date'
                  min={todayDateConverted}
                  onChange={(e: any) => setDueDate(e.target.value)}
                  className='form-control form-control-lg'
                />
              </div>
            </div>

            <div className='text-center py-7 d-flex justify-content-center'>
              <button
                type='reset'
                className='btn btn-light me-3'
                onClick={() => setStateNewAuthorization(undefined)}
                data-kt-inspection-steps-modal-action='cancel'
              >
                {intl.formatMessage({id: 'FORM.BUTTON.CANCEL'})}
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-inspection-steps-modal-action='submit'
              >
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'FORM.BUTTON.SUBMIT'})}
                </span>
              </button>
            </div>
            {/* end::Modal body */}
          </form>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default NewAuthorizationModal
