import axios, {AxiosResponse} from 'axios'
import {PublicKey, Response} from '../../../../../_metronic/helpers'
import {HazardManagementQueryResponse} from '../../workAuthorization/WorkAuthorization-list/core/_models'
import {ICreateWorkflowStatus} from './_models'

const API_ALPHA = process.env.REACT_APP_API2_URL || 'api'
const ACTIVITY_LIST = `${API_ALPHA}/customer/risk/activity`
const LOCATION_LIST = `${API_ALPHA}/customer/settings`
const WORKFLOW_URL = `${API_ALPHA}/customer/access/workflow`
const WORKFLOW_LEVELS_URL = `${API_ALPHA}/customer/access/workflowLevel`
const WORKFLOW_USERS_URL = `${API_ALPHA}/customer/access/workflowUser`
const USERS_BY_WORKFLOW_LIST = `${API_ALPHA}/customer/access/listUsersByWorkflow`
const WORKFLOW_LIST_LEVES = `${API_ALPHA}/customer/access/listLevelsByWorkflow`

export const getActivityList = (): Promise<HazardManagementQueryResponse> => {
  return axios.get(ACTIVITY_LIST).then((d: AxiosResponse<HazardManagementQueryResponse>) => d.data)
}

export const getActivities = (search: string) => {
  return axios.get(`${ACTIVITY_LIST}?offset=1&limit=10&search=${search}`)
}

export const getWorkflowByPublicKey = (publicKey: PublicKey) => {
  return axios.get(`${WORKFLOW_URL}/${publicKey}`)
}

export const getWorkflowLevelsByPublicKey = (publicKey: PublicKey) => {
  return axios.get(`${WORKFLOW_LIST_LEVES}/${publicKey}`)
}

export const getLocationList = (locationType: any): Promise<HazardManagementQueryResponse> => {
  return axios
    .get(`${LOCATION_LIST}/${locationType}`)
    .then((d: AxiosResponse<HazardManagementQueryResponse>) => d.data)
}

export const getUsersByWorkflowList = (
  workflow: string
): Promise<HazardManagementQueryResponse> => {
  return axios
    .get(`${USERS_BY_WORKFLOW_LIST}/${workflow}`)
    .then((d: AxiosResponse<HazardManagementQueryResponse>) => d.data)
}

export const deleteUserFromWorkFlow = (userId: string): Promise<HazardManagementQueryResponse> => {
  return axios
    .delete(`${WORKFLOW_USERS_URL}/${userId}`)
    .then((d: AxiosResponse<HazardManagementQueryResponse>) => d.data)
}

export const createWorkFlow = (workflow: any): Promise<ICreateWorkflowStatus | undefined> => {
  return axios
    .post(WORKFLOW_URL, workflow)
    .then((response: AxiosResponse<Response<ICreateWorkflowStatus>>) => response.data)
    .then(
      (response: Response<ICreateWorkflowStatus>) =>
        response as Response<ICreateWorkflowStatus>['data']
    )
}

export const createWorkFlowLevels = (workflow: any): Promise<ICreateWorkflowStatus | undefined> => {
  return axios
    .post(WORKFLOW_LEVELS_URL, workflow)
    .then((response: AxiosResponse<Response<ICreateWorkflowStatus>>) => response.data)
    .then(
      (response: Response<ICreateWorkflowStatus>) =>
        response as Response<ICreateWorkflowStatus>['data']
    )
}

export const createWorkFlowUsers = (workflow: any): Promise<ICreateWorkflowStatus | undefined> => {
  return axios
    .post(WORKFLOW_USERS_URL, workflow)
    .then((response: AxiosResponse<Response<ICreateWorkflowStatus>>) => response.data)
    .then(
      (response: Response<ICreateWorkflowStatus>) =>
        response as Response<ICreateWorkflowStatus>['data']
    )
}

export const updateWorkFlow = (workflow: any, publicKey: any): Promise<any | undefined> => {
  return axios
    .put(`${WORKFLOW_URL}/${publicKey}`, workflow)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response as Response<ICreateWorkflowStatus>['data'])
}

export const updateWorkFlowLevels = (workflow: any): Promise<any | undefined> => {
  return axios
    .put(`${WORKFLOW_LEVELS_URL}/${workflow.publicKey}`, workflow)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response as Response<ICreateWorkflowStatus>['data'])
}
