import React from 'react'

type Props = {
  description: string
}

const WorkflowDescriptionCell: React.FC<Props> = ({description}) => {
  return (
    <div className='d-flex flex-column'>
      <a className='text-gray-800'>{description}</a>
    </div>
  )
}

export default WorkflowDescriptionCell
