/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {useAuth} from '../core/Auth'
import {getUserByToken, login} from '../core/_requests'

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'VALIDATION.EMAIL'}))
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {min: '3'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {max: '50'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {min: '3'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {max: '50'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
  })

  const initialValues = {
    email: '',
    password: '',
  }

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth({accessToken: auth.accessToken, refreshToken: auth.refreshToken})
        const {data: user} = await getUserByToken()
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.ERROR'}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}</h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <input
          placeholder='email@example.com'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'AUTH.LOGIN.INPUT.PASSWORD'})}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='d-flex justify-content-end align-items-start mt-n5'>
        <div className='d-flex flex-stack mb-10'>
          {/* begin::Link */}
          <Link
            to='/auth/forgot-password'
            className='link-primary fs-6 fw-bolder'
            style={{marginLeft: '5px'}}
          >
            {intl.formatMessage({id: 'AUTH.LOGIN.FORGOT_YOUR_PASSWORD'})}
          </Link>
          {/* end::Link */}
        </div>
      </div>

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON.SIGNIN'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON.PLEASE_AWAIT'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='d-flex align-items-center mb-5'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>
          {intl.formatMessage({id: 'AUTH.LOGIN.OR'})}
        </span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {/* begin::Google link */}
      <a>
        <Link
          to='/auth/registration'
          className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'
        >
          {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON.CREATE_AN_ACCOUNT'})}
        </Link>
      </a>
      {/* end::Google link */}
    </form>
  )
}
