import React from 'react'
import {useListView} from '../workflow/Workflow-list/core/ListViewProvider'
import {UpdateWorkflowModal} from './UpdateWorkflowModal'
import {WorkflowModal} from './WorkflowModal'

type Props = {
  show: boolean
  handleClose: () => void
  refetch?: () => void
}
const WorkflowModalWrapper: React.FC<Props> = ({show, refetch, handleClose}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <>
      {itemIdForUpdate !== null ? (
        <UpdateWorkflowModal
          show={true}
          refetch={refetch}
          handleClose={() => setItemIdForUpdate(undefined)}
        />
      ) : (
        <WorkflowModal
          show={true}
          refetch={refetch}
          handleClose={() => setItemIdForUpdate(undefined)}
        />
      )}
    </>
  )
}

export default WorkflowModalWrapper
