import {CompanyStorageModel, ModuleStorageModel} from './_models'

const COMPANY_LOCAL_STORAGE_KEY = '@awniva/company'
const MODULE_LOCAL_STORAGE_KEY = '@awniva/module'

const getCompanyStorage = (): CompanyStorageModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(COMPANY_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const company: CompanyStorageModel = JSON.parse(lsValue) as CompanyStorageModel
    if (company) {
      // You can easily check settings_token expiration also
      return company
    }
  } catch (error) {
    console.error('COMPANY LOCAL STORAGE PARSE ERROR', error)
  }
}

const setCompanyStorage = (company: CompanyStorageModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(company)
    localStorage.setItem(COMPANY_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('COMPANY LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeCompanyStorage = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(COMPANY_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('COMPANY LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getModuleStorage = (): ModuleStorageModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(MODULE_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const module: ModuleStorageModel = JSON.parse(lsValue) as ModuleStorageModel
    if (module) {
      // You can easily check settings_token expiration also
      return module
    }
  } catch (error) {
    console.error('MODULE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setModuleStorage = (module: ModuleStorageModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(module)
    localStorage.setItem(MODULE_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('MODULE LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeModuleStorage = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(MODULE_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('MODULE LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {
  getCompanyStorage,
  setCompanyStorage,
  removeCompanyStorage,
  getModuleStorage,
  setModuleStorage,
  removeModuleStorage,
  COMPANY_LOCAL_STORAGE_KEY,
  MODULE_LOCAL_STORAGE_KEY,
}
