import {motion} from 'framer-motion'
import React, {useEffect, useState} from 'react'
import EmployeeChallenge from './Steps/EmployeeChallenge'
import InfoStep from './Steps/InfoStep'
import Inspections from './Steps/Inspections'
import ManualInsertSafetyTag from './Steps/ManualInsertSafetyTag'
import ScanSafetyTag from './Steps/ScanSafetyTag'
import Signature from './Steps/Signature'
import TransactionInsertNotes from './Steps/TransactionInsertNotes'
import TransactionNotes from './Steps/TransactionNotes'
import TransactionResult from './Steps/TransactionResult'
import BottomNavigationBar from './components/BottomNavigationBar'
import {
  requestEmployeeOrLocationDetails,
  requestNewTransaction,
  requestSafetyTagDetails,
} from './core/_requests'

// Lista de Steps (Para gerenciamento do estado [currentStepIndex]) = {
//EPI
//     1 = Scan de safetyTag (Equipamento)
//     2 = Digitar SafetyTag (Opcional)

//EMPLOYEE
//     3 = Scan de Employee (Ou Location)
//     4 = Digitar employee (ou location)
//     5 = Reconhecimento facial do Employee
//     6 = Desafio para o empregado
//     7 = Verificação do código do empregado

//INSPECAO
//     8 = Inspeção do equipamento

//RESULT
//     9 = Botão para ir ao assinar o recebimento do EPI
//     10 = Assinar o recebimento do EPI
//     11 = Foto para comprovação da transação / Notas

//DONE
//     12 = Resultado da transação

// TELA PARA SUCESSO
//     13 = Confirmar employee
//     14 = Erro reconhecimento facial Employee
//     15 = Sucesso reconhecimento Facial Employee
//     16 = Transação concluida
//     17 = SafetyTag encontrada
//     18 = SafetyTag não encontrada
//     19 = Empregado encontrado => challenge
//     20 = Empregado encontrado => faceValidation
//     21 = Empregado não encontrado
//     22 = Inserir notas da transação
//     23 = Empregado/Location encontrado => without validation
// }

const EquipmentTransactionIndex: React.FC<any> = ({show, handleClose, breakpoints}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<Number>(1)
  const [currentIndex, setCurrentIndex] = useState(0)

  const [safetyTagScanned, setSafetyTagScanned] = useState('')
  const [employeeOrLocationName, setEmployeeOrLocationName] = useState('')
  const [employeeOrLocationPublicKey, setEmployeeOrLocationPublicKey] = useState('')
  const [employeeOrLocationTyped, setEmployeeOrLocationTyped] = useState('')
  const [type, setType] = useState('')
  const [transactionPublicKey, setTransactionPublicKey] = useState('')

  const [transactionImage, setTransactionImage] = useState('')
  const [transactionNotes, setTransactionNotes] = useState('')

  const [inspectionsSteps, setInspectionsSteps] = useState([])
  const [challenges, setChallenges] = useState([])

  const [error, setError] = useState('')

  const [nfcAvailable, setNfcAvailable] = useState(false)
  const [nfcAvailableSecondStep, setNfcAvailableSecondStep] = useState(false)

  // Scan de SafetyTag
  useEffect(() => {
    if (!safetyTagScanned.startsWith('http') && safetyTagScanned?.length === 8) {
      const safetyTagData = async () => {
        const response = await requestSafetyTagDetails(safetyTagScanned)

        if (response?.code === 'ERR_NETWORK') {
          setSafetyTagScanned('')
          setCurrentStepIndex(18) // => SafetyTag não encontrada
        } else {
          if (response?.data?.data === null) {
            setSafetyTagScanned('')
            setCurrentStepIndex(18) // => SafetyTag não encontrada
          } else {
            if (
              response?.data?.data?.equipment_status === 'On Hold' ||
              response?.data?.data?.equipment_status === 'Inactive'
            ) {
              setCurrentStepIndex(14) // => Não autorizada
            } else {
              if (
                response?.data?.data?.alphaTag_status === 'discard' ||
                response?.data?.data?.alphaTag_status === 'maintenance'
              ) {
                setCurrentStepIndex(14) // => Não autorizada
              } else {
                if (response?.data?.data?.employee_publicKey !== null) {
                  setEmployeeOrLocationPublicKey(response?.data?.data?.employee_publicKey)
                  setEmployeeOrLocationTyped(response?.data?.data?.employee_publicKey)
                  setEmployeeOrLocationName(response?.data?.data?.employee_fullName)
                  setType('employee')
                  setCurrentStepIndex(17) // => Confirmação do empregado
                } else if (response?.data?.data?.area_publicKey !== null) {
                  setEmployeeOrLocationPublicKey(response?.data?.data?.area_publicKey)
                  setEmployeeOrLocationTyped(response?.data?.data?.area_publicKey)
                  setEmployeeOrLocationName(response?.data?.data?.area_name)
                  setType('area')
                  setCurrentStepIndex(17) // => Confirmação da area
                } else if (response?.data?.data?.building_publicKey !== null) {
                  setEmployeeOrLocationPublicKey(response?.data?.data?.building_publicKey)
                  setEmployeeOrLocationTyped(response?.data?.data?.building_publicKey)
                  setEmployeeOrLocationName(response?.data?.data?.building_name)
                  setType('building')
                  setCurrentStepIndex(17) // => Confirmação da building
                } else if (response?.data?.data?.branch_publicKey !== null) {
                  setEmployeeOrLocationPublicKey(response?.data?.data?.branch_publicKey)
                  setEmployeeOrLocationTyped(response?.data?.data?.branch_publicKey)
                  setEmployeeOrLocationName(response?.data?.data?.branch_name)
                  setType('branch')
                  setCurrentStepIndex(17) // => Confirmação da branch
                } else {
                  setCurrentStepIndex(17) // => Scan de employee ou location
                }
              }
            }
          }
        }
      }
      safetyTagData()
    }
  }, [safetyTagScanned])

  // Scan de employee
  useEffect(() => {
    if (
      !employeeOrLocationPublicKey.startsWith('http') &&
      employeeOrLocationPublicKey?.length === 8
    ) {
      if (currentStepIndex === 3 || currentStepIndex === 4) {
        var typeTransaction = ''

        if (employeeOrLocationTyped === employeeOrLocationPublicKey) {
          typeTransaction = 'inspection'
        } else {
          typeTransaction = 'delivery'
        }

        const employeeOrLocationData = async () => {
          const data = await requestEmployeeOrLocationDetails(
            employeeOrLocationPublicKey,
            safetyTagScanned
          )

          if (data?.code === 'ERR_NETWORK') {
            setSafetyTagScanned('')
            setCurrentStepIndex(21) // => Empregado não encontrada
          } else {
            if (data?.data?.httpStatus !== '200') {
              if (data?.data?.httpStatus === '422') {
                setCurrentStepIndex(21) // => Empregado não encontrado
                setEmployeeOrLocationPublicKey('')
                setError(data?.data?.message)
              } else {
                setCurrentStepIndex(21) // => Empregado não encontrado
                setEmployeeOrLocationPublicKey('')
              }
            } else {
              if (data?.data?.data === null) {
                setCurrentStepIndex(21) // => Empregado não encontrado
                setEmployeeOrLocationPublicKey('')
              } else {
                if (data?.data?.data?.typeDelivery) {
                  if (data?.data?.data?.employee_fullName) {
                    setEmployeeOrLocationName(data?.data?.data?.employee_fullName)
                  }

                  const dataSend = {
                    alphaTag_publicKey: safetyTagScanned,
                    type: typeTransaction,
                    typeDelivery: data?.data?.data?.typeDelivery,
                    typeDelivery_publicKey: data?.data?.data?.typeDelivery_publicKey,
                  }

                  const newTransaction = await requestNewTransaction(dataSend)

                  if (newTransaction.data?.error === 1) {
                    setError('Not found')
                  } else {
                    setType(data?.data?.data?.typeDelivery)
                    if (data?.data?.data?.typeDelivery === 'employee') {
                      if (newTransaction.data?.validationMethod === 'faceValidation') {
                        setCurrentStepIndex(20) // => FACE VALIDATION
                        //setCurrentStepIndex(5)
                      } else if (newTransaction.data?.validationMethod === 'challenge') {
                        setChallenges(newTransaction.data?.challenges)
                        setCurrentStepIndex(19) // => CHALLENGE
                        //setCurrentStepIndex(6)
                      } else {
                        setCurrentStepIndex(23) // Inspeção
                      }
                    } else {
                      setCurrentStepIndex(23) // Inspeção
                    }
                    setTransactionPublicKey(newTransaction.data?.publicKey)
                    setInspectionsSteps(newTransaction.data?.inspectionSteps)
                  }
                } else {
                  setError('Not found')
                }
              }
            }
          }
        }

        employeeOrLocationData()
      }
    }
  }, [employeeOrLocationPublicKey])

  const safetyTagContinued = async () => {
    if (type === 'employee') {
      const dataSend = {
        alphaTag_publicKey: safetyTagScanned,
        type: 'inspection',
        typeDelivery: type,
        typeDelivery_publicKey: employeeOrLocationPublicKey,
      }

      const newTransaction = await requestNewTransaction(dataSend)

      if (newTransaction.data?.error === 1) {
        setError('Not found')
      } else {
        if (newTransaction.data?.validationMethod === 'faceValidation') {
          setCurrentStepIndex(20) // => FACE VALIDATION
        } else if (newTransaction.data?.validationMethod === 'challenge') {
          setChallenges(newTransaction.data?.challenges)
          setCurrentStepIndex(19) // => CHALLENGE
        } else {
          setCurrentStepIndex(23) // => Inspeção
        }
        setTransactionPublicKey(newTransaction.data?.publicKey)
        setInspectionsSteps(newTransaction.data?.inspectionSteps)
      }
    } else {
      const dataSend = {
        alphaTag_publicKey: safetyTagScanned,
        type: 'inspection',
        typeDelivery: type,
        typeDelivery_publicKey: employeeOrLocationPublicKey,
      }

      const newTransaction = await requestNewTransaction(dataSend)

      if (newTransaction.data?.error === 1) {
        setError('Not found')
      } else {
        setCurrentStepIndex(23) // => Inspeção
        setTransactionPublicKey(newTransaction.data?.publicKey)
        setInspectionsSteps(newTransaction.data?.inspectionSteps)
      }
    }
  }

  const safetyTagNotContinued = () => {
    setEmployeeOrLocationPublicKey('')
    setType('')
    setCurrentStepIndex(3) // => Scan de employee
  }

  return (
    <>
      <motion.div
        initial={{opacity: 0, y: 600}}
        animate={{opacity: 1, y: 0}}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        className='modal fade show d-block'
        id='kt_modal_add_inspectionStep'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div
          className={`${
            currentStepIndex !== 10 && 'modal-dialog'
          } modal-dialog-centered w-100 h-100`}
          style={{
            margin: breakpoints.md ? '100' : '0',
            marginTop: breakpoints.md ? '0' : '0',
            marginBottom: breakpoints.md ? '0' : '0',
          }}
        >
          <div className='modal-content w-100' style={{height: '100%'}}>
            <div
              className='w-100 h-100 scroll-y'
              style={{overflow: currentStepIndex !== 12 ? 'hidden' : 'auto'}}
            >
              <div className='w-100 h-100'>
                {/* Primeira Step */}

                {currentStepIndex === 1 && (
                  <ScanSafetyTag
                    currentStepIndex={currentStepIndex}
                    safetyTagScanned={safetyTagScanned}
                    setSafetyTagScanned={setSafetyTagScanned}
                    setCurrentStepIndex={setCurrentStepIndex}
                    handleClose={handleClose}
                    nfcAvailable={nfcAvailable}
                    setNfcAvailable={setNfcAvailable}
                  />
                )}
                {currentStepIndex === 2 && (
                  <ManualInsertSafetyTag
                    safetyTagScanned={safetyTagScanned}
                    setSafetyTagScanned={setSafetyTagScanned}
                    handleClose={handleClose}
                    currentStepIndex={currentStepIndex}
                  />
                )}
                {/* Fim da primeira Step */}

                {/* Segunda Step */}

                {currentStepIndex === 3 && (
                  <ScanSafetyTag
                    currentStepIndex={currentStepIndex}
                    safetyTagScanned={employeeOrLocationPublicKey}
                    setSafetyTagScanned={setEmployeeOrLocationPublicKey}
                    setCurrentStepIndex={setCurrentStepIndex}
                    handleClose={handleClose}
                    nfcAvailable={nfcAvailableSecondStep}
                    setNfcAvailable={setNfcAvailableSecondStep}
                  />
                )}

                {currentStepIndex === 4 && (
                  <ManualInsertSafetyTag
                    safetyTagScanned={employeeOrLocationPublicKey}
                    setSafetyTagScanned={setEmployeeOrLocationPublicKey}
                    handleClose={handleClose}
                    currentStepIndex={currentStepIndex}
                  />
                )}

                {currentStepIndex === 5 && (
                  <ScanSafetyTag // => Reconhecimento facial
                    currentStepIndex={currentStepIndex}
                    safetyTagScanned={employeeOrLocationPublicKey}
                    setSafetyTagScanned={setEmployeeOrLocationPublicKey}
                    transactionPublicKey={transactionPublicKey}
                    employeeFaceRecognition={true}
                    setCurrentStepIndex={setCurrentStepIndex}
                    handleClose={handleClose}
                    nfcAvailable={nfcAvailable}
                    setNfcAvailable={setNfcAvailable}
                  />
                )}

                {currentStepIndex === 6 && (
                  <EmployeeChallenge
                    challenges={challenges}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 8 && (
                  <Inspections
                    inspectionsSteps={inspectionsSteps}
                    setCurrentStepIndex={setCurrentStepIndex}
                    transactionPublicKey={transactionPublicKey}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    typeTransaction={type}
                  />
                )}

                {currentStepIndex === 9 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 10 && (
                  <Signature
                    employeeOrLocationName={employeeOrLocationName}
                    transactionPublicKey={transactionPublicKey}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 11 && (
                  <TransactionNotes
                    transactionPublicKey={transactionPublicKey}
                    setCurrentStepIndex={setCurrentStepIndex}
                    image={transactionImage}
                    setImage={setTransactionImage}
                    transactionNotes={transactionNotes}
                  />
                )}

                {currentStepIndex === 12 && (
                  <TransactionResult transactionPublicKey={transactionPublicKey} />
                )}

                {currentStepIndex === 13 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}
                {currentStepIndex === 14 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                    handleClose={handleClose}
                  />
                )}
                {currentStepIndex === 15 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 16 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 17 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 18 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 19 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 20 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 21 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                    error={error}
                  />
                )}

                {currentStepIndex === 22 && (
                  <TransactionInsertNotes
                    transactionNotes={transactionNotes}
                    setTransactionNotes={setTransactionNotes}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {currentStepIndex === 23 && (
                  <InfoStep
                    type={type}
                    employeeOrLocationName={employeeOrLocationName}
                    safetyTagContinued={safetyTagContinued}
                    safetyTagNotContinued={safetyTagNotContinued}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                )}

                {/* Fim da segunda Step */}
                {currentStepIndex !== 13 &&
                  currentStepIndex !== 14 &&
                  currentStepIndex !== 15 &&
                  currentStepIndex !== 16 &&
                  currentStepIndex !== 17 &&
                  currentStepIndex !== 18 &&
                  currentStepIndex !== 19 &&
                  currentStepIndex !== 20 &&
                  currentStepIndex !== 21 &&
                  currentStepIndex !== 23 &&
                  currentStepIndex !== 10 &&
                  currentStepIndex !== 9 && (
                    <BottomNavigationBar
                      currentStepIndex={currentStepIndex}
                      setCurrentStepIndex={setCurrentStepIndex}
                      handleClose={handleClose}
                      stepCurrentIndex={currentIndex}
                      setStepCurrentIndex={setCurrentIndex}
                      inspectionsSteps={inspectionsSteps}
                      nfcAvailable={nfcAvailable}
                      nfcAvailableSecondStep={nfcAvailableSecondStep}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default EquipmentTransactionIndex
